import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addOneServerToStore } from 'store/actions/server';
import { Button } from 'app/shared/button';
import { SimpleInput } from 'app/shared/input';
import { MultiSelect } from 'app/shared/select';
import { useServerService } from 'hooks/api/postal/server';

const CLASS_OPTIONS = [
  {
    value: 'mailing',
    label: 'Campaigns'
  },
  {
    value: 'transactional',
    label: 'Transactionals'
  }
];

const PROVIDER_OPTIONS = [
  {
    value: 'all',
    label: 'All'
  },
  {
    value: 'gmail',
    label: 'G-Mail'
  },
  {
    value: 'yahoo',
    label: 'Yahoo'
  },
  {
    value: 'others',
    label: 'Others'
  }
];

const QUEUE_OPTIONS = [
  {
    value: 'regular',
    label: 'Regular'
  },
  {
    value: 'preferred',
    label: 'Preferred'
  }
];

const CreateServer = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { createServer } = useServerService();

  const [provider, setProvider] = useState({});
  const [server_class, setServerClass] = useState({});
  const [server_ip, setServerIP] = useState('');
  const [queue_class, setQueueClass] = useState({});
  const [loading, setLoading] = useState(false);

  const clearForm = () => {
    setServerClass({});
    setServerIP('');
    setProvider({});
    setQueueClass({});
  };

  const submit = async () => {
    const data = {
      provider: provider.value,
      class: server_class.value,
      ip_address: server_ip,
      queue_class: queue_class.value
    };

    if (!provider || !server_class || !server_ip) {
      toast.error('please fill all fields.');
      return;
    }

    setLoading(true);
    const payload = await createServer(data);

    setLoading(false);

    if (!payload) return;
    toast.success('Server created.');
    dispatch(addOneServerToStore(payload));
    clearForm();
    closeModal();
  };

  return (
    <div>
      <SimpleInput
        placeholder="Domain (or IP address)"
        required
        value={server_ip}
        onInput={(value) => setServerIP(value)}
      />
      <div>
        <label>Server Class:</label>
        <MultiSelect options={CLASS_OPTIONS} onChange={setServerClass} value={server_class} />
      </div>
      <div>
        <label>Email Provider:</label>
        <MultiSelect options={PROVIDER_OPTIONS} onChange={setProvider} value={provider} />
      </div>
      <div>
        <label>Queue Class:</label>
        <MultiSelect options={QUEUE_OPTIONS} onChange={setQueueClass} value={queue_class} />
      </div>
      <Button
        text="Save"
        onClick={submit}
        className="float-right w-50 mt-4 mb-4"
        loading={loading}
      />
    </div>
  );
};

export default CreateServer;
