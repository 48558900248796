export const table_config = {
  actions: {
    single: (tenant) => [
      { label: 'Assign Subscription', value: 'assign_subscription', hidden: !tenant.is_active },
      { label: 'View', value: 'view', hidden: !tenant.is_active },
      { label: 'Edit', value: 'edit', hidden: !tenant.is_active },
      { label: 'Top Up', value: 'top up', hidden: !tenant.is_active },
      { label: 'Delete', value: 'delete' }
    ]
  },
  allow_bulk_action: true,
  css: {},
  fields: [
    {
      title: 'ID',
      key: 'id'
    },
    {
      title: 'Name',
      key: 'name',
      searchable: true,
      isTitle: true
    },
    {
      title: 'Email',
      key: 'emails',
      formatter: (value) => value.join(', '),
      searchable: true,
      isTagline: true
    },
    {
      title: 'Status',
      key: 'is_active',
      formatter: (value) => (value ? 'Active' : 'Inactive'),
      isTagline: true
    },
    {
      title: 'Balance',
      key: 'balance',
      isTagline: true,
      formatter: (value) => value || '0'
    },
    {
      title: 'Rate',
      key: 'rate',
      isTagline: true,
      formatter: (value) => value || 'N/A'
    },
    {
      title: 'Date added',
      key: 'created_on',
      formatter: (value) => {
        return value ? new Date(value).toDateString() : '';
      },
      isMetadata: true
    }
  ],
  items: [],
  // search_key: 'name',
  search_text: ''
};
