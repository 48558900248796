/** */
export const READ_MANY_SMS = 'read multiple sms.';
export const READ_ONE_SMS = 'read one sms.';

export const ADD_ONE_SMS_TO_STORE = 'add one sms to store.';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add sms search results to store.';
export const ADD_MANY_SMS_TO_STORE = 'add multiple sms to store.';
export const LOAD_SMS_TO_STORE = 'load up sms to store.';
export const REM_ONE_SMS_FROM_STORE = 'remove a sms from the store.';

/** */
export const readManySMS = (payload) => ({
  type: READ_MANY_SMS,
  payload
});

export const readOneSMS = (payload) => ({
  type: READ_ONE_SMS,
  payload
});

export const addManySMSToStore = (payload) => ({
  type: ADD_MANY_SMS_TO_STORE,
  payload
});

export const addOneSMSToStore = (payload) => ({
  type: ADD_ONE_SMS_TO_STORE,
  payload
});

export const addSMSearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const loadSMSToStore = (payload) => ({
  type: LOAD_SMS_TO_STORE,
  payload
});

export const removeOneSMSFromStore = (payload) => ({
  type: REM_ONE_SMS_FROM_STORE,
  payload
});
