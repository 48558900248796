/** */
export const READ_MANY_EFFECT = 'read multiple effect.';
export const ADD_MANY_EFFECT_TO_STORE = 'add multiple effect to store.';
export const REMOVE_ONE_EFFECT_FROM_STORE = 'remove one effect from store';
export const REMOVE_MANY_EFFECT_FROM_STORE = 'remove many effect from store';
export const ADD_ONE_EFFECT_TO_STORE = 'add one effect to store';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add effect search results to store.';
export const UPDATE_ONE_EFFECT_IN_STORE = 'update an effect in store.';

/** */
export const readManyEffect = (payload) => ({
  type: READ_MANY_EFFECT,
  payload
});

export const addManyEffectToStore = (payload) => ({
  type: ADD_MANY_EFFECT_TO_STORE,
  payload
});

export const removeOneEffectFromStore = (payload) => ({
  type: REMOVE_ONE_EFFECT_FROM_STORE,
  payload
});

export const removeManyEffectFromStore = (payload) => ({
  type: REMOVE_MANY_EFFECT_FROM_STORE,
  payload
});

export const addOneEffectToStore = (payload) => ({
  type: ADD_ONE_EFFECT_TO_STORE,
  payload
});

export const addEffectSearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const updateEffectInStore = (payload) => ({
  type: UPDATE_ONE_EFFECT_IN_STORE,
  payload
});
