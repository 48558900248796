import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setPageTitle } from 'store/actions/header';
import { countries } from 'app/data/countries';
import { Button } from 'app/shared/button';
import Form from 'app/shared/form';
import { SimpleInput } from 'app/shared/input';
import { MultiSelect } from 'app/shared/select';
import { useParams } from 'react-router-dom';
import { convertDateFromIsoToHTMLFormat } from 'app/shared/utils/date';
import styles from './edit.module.css';
import { useTenantService } from 'hooks/api/iam/tenant';

const gender_options = [
  {
    value: 'male',
    label: 'Male'
  },
  {
    value: 'female',
    label: 'Female'
  },
  {
    value: 'other',
    label: 'Other'
  }
];

export const TenantUpdatePage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { fetchTenants, updateTenant } = useTenantService();

  const [loading, setLoading] = useState(false);
  const [tenant_data, setTenantData] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState({});
  const [street, setStreet] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [date_of_birth, setDateOfBirth] = useState();

  /** */
  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Tenants', path: '..' },
        { title: 'Edit', path: `.` }
      ])
    );
    fetchTenants({ query_string: `id=${id}` }).then(({ tenants }) => {
      const [tenant] = tenants;
      if (!tenant) return;

      const { firstname, lastname, email, address, date_of_birth, gender } = tenant;

      setFirstname(firstname);
      setLastname(lastname);
      setEmail(email);
      setDateOfBirth(convertDateFromIsoToHTMLFormat(date_of_birth));
      setGender(gender);

      if (address && Object.keys(address).length) {
        const { street, state, country } = address;
        setState(state);
        setStreet(street);
        setCountry({
          label: countries.find((c) => c.code === country)?.name,
          value: country
        });
      }

      if (gender) {
        setGender({
          label: `${gender[0].toUpperCase()}${gender.substr(1)}`,
          value: gender
        });
      }

      setTenantData(tenant);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm = async () => {
    const form_data = {
      firstname,
      lastname,
      email,
      gender: gender ? gender.value : '',
      address: {
        street,
        state,
        country: country ? country.value : ''
      },
      date_of_birth,
      tenant_id: tenant_data.id
    };

    setLoading(true);
    await updateTenant(tenant_data.id, { data: form_data });
    setLoading(false);
  };

  return (
    <Form>
      <div className="grid cols-2">
        <SimpleInput
          label="First name:"
          input_id="firstname"
          value={firstname}
          onInput={(e) => setFirstname(e)}
        />
        <SimpleInput
          label="Last name:"
          input_id="lastname"
          value={lastname}
          onInput={(e) => setLastname(e)}
        />
      </div>
      <div>
        <SimpleInput label="Email address:" value={email} onInput={(e) => setEmail(e)} />
      </div>
      <div className="grid cols-2">
        {date_of_birth ? (
          <SimpleInput
            type="date"
            label="Date of birth:"
            value={date_of_birth}
            onInput={(e) => setDateOfBirth(e)}
          />
        ) : (
          <SimpleInput type="date" label="Date of birth:" onInput={(e) => setDateOfBirth(e)} />
        )}

        <div>
          <label>Gender:</label>
          <MultiSelect
            options={gender_options}
            onChange={setGender}
            value={gender}
            label="Select gender"
          />
        </div>
      </div>
      <div>
        <SimpleInput
          label="Street address:"
          input_id="street-address"
          value={street}
          onInput={(e) => setStreet(e)}
          wrapperClassName={styles.street}
        />
      </div>
      <div className="grid cols-2">
        <SimpleInput label="State:" input_id="state" value={state} onInput={(e) => setState(e)} />
        <div>
          <label>Country:</label>
          <MultiSelect
            options={countries.map((country) => ({
              label: country.name,
              value: country.code
            }))}
            onChange={setCountry}
            value={country}
            label="Select country"
            isMulti={false}
          />
        </div>
      </div>
      <div className="grid cols-3 mt-2">
        <div />
        <div />
        <Button onClick={submitForm} text="Save" className="float-right" loading={loading} />
      </div>
    </Form>
  );
};
