import { useWalletService } from 'hooks/api/sales/wallet';
import { createContext, useEffect, useState } from 'react';

export const WalletContext = createContext();
export const useTenantWalletData = () => {
  const { fetchWallets } = useWalletService();
  const [tenant_wallet_list, setTenantWalletList] = useState({});

  useEffect(() => {
    const query_string = `return_only=balance,tenant_id,rate`;
    fetchWallets({ query_string }).then(({ wallets }) => {
      const wallet_by_tenant_id = wallets.reduce(
        (sac, item) => ({
          ...sac,
          [item.tenant_id]: {
            rate: item.rate,
            balance: item.balance ? item.balance.toFixed(2) : 0
          }
        }),
        {}
      );
      setTenantWalletList(wallet_by_tenant_id);
    });
  }, []);

  return { tenant_wallet_list };
};
