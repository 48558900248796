import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as CurrencyService from 'services/sales/currency';

export const useCurrencyService = () => {
  const { token } = useSelector((store) => store.user_data);

  const createCurrency = async (data) => {
    const { error, payload } = await CurrencyService.create({ data, token });
    if (error) {
      toast.error(error);
      return null;
    }

    return payload;
  };

  const deleteCurrency = async (id, options = {}) => {
    const { error } = await CurrencyService.deleteById(id, {
      ...options,
      token
    });

    if (error) {
      toast.error('Unable to delete currency at this time.');
      return false;
    }

    toast.success('Currency deleted successfully');
    return true;
  };

  const fetchCurrencyById = async (id, options = {}) => {
    const { error, payload } = await CurrencyService.readById(id, { ...options, token });
    if (error) {
      toast.error('Unable to fetch currency at this time.');
      return { currency: {} };
    }

    return { currency: payload };
  };

  const fetchCurrencies = async (options = {}) => {
    const { error, payload } = await CurrencyService.read({ ...options, token });
    if (error) {
      toast.error('Unable to fetch currencies at this time.');
      return { currencies: [], size: 0 };
    }

    const {
      data: currencies,
      meta: { size }
    } = payload;
    return { currencies, size };
  };

  const fetchCurrencyCountries = async (options = {}) => {
    const countries = await CurrencyService.getCountries({ ...options, token });
    return { countries: countries || [] };
  };

  const searchCurrencies = async (keys, keyword, options = {}) => {
    const { error, payload } = await CurrencyService.search(keys, keyword, { ...options, token });
    if (error) {
      toast.error('Unable to fetch currencies at this time.');
      return { currencies: [], size: 0 };
    }

    const {
      data: currencies,
      meta: { size }
    } = payload;
    return { currencies, size };
  };

  const updateCurrency = async (id, options = {}) => {
    const { error } = await CurrencyService.updateById(id, {
      ...options,
      token
    });

    if (error) {
      toast.error('Unable to update Currency at this time.');
      return false;
    }

    toast.success('Currency updated successfully');
    return true;
  };

  return {
    createCurrency,
    deleteCurrency,
    fetchCurrencyById,
    fetchCurrencies,
    fetchCurrencyCountries,
    searchCurrencies,
    updateCurrency
  };
};
