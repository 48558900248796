import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as ServerService from 'services/postal/server';

export const useServerService = () => {
  const { token } = useSelector((store) => store.user_data);

  const createServer = async (data) => {
    const { error, payload } = await ServerService.create({ data, token });
    if (error) {
      toast.error(error);
      return null;
    }

    return payload;
  };

  const deleteServer = async (id, options = {}) => {
    const { error } = await ServerService.updateById(id, {
      ...options,
      token
    });

    if (error) {
      toast.error('Unable to delete server at this time.');
      return false;
    }

    toast.success('Server deleted successfully');
    return true;
  };

  const fetchServerById = async (id, options = {}) => {
    const { error, payload } = await ServerService.readById(id, { ...options, token });
    if (error) {
      toast.error('Unable to fetch server at this time.');
      return { server: {} };
    }

    return { server: payload };
  };

  const fetchServers = async (options = {}) => {
    const { error, payload } = await ServerService.read({ ...options, token });
    if (error) {
      toast.error('Unable to fetch servers at this time.');
      return { servers: [], size: 0 };
    }

    const {
      data: servers,
      meta: { size }
    } = payload;
    return { servers, size };
  };

  const searchServers = async (keys, keyword, options = {}) => {
    const { error, payload } = await ServerService.search(keys, keyword, { ...options, token });
    if (error) {
      toast.error('Unable to fetch servers at this time.');
      return { servers: [], size: 0 };
    }

    const {
      data: servers,
      meta: { size }
    } = payload;
    return { servers, size };
  };

  const updateServer = async (id, options = {}) => {
    const { error } = await ServerService.updateById(id, {
      ...options,
      token
    });

    if (error) {
      toast.error('Unable to update server at this time.');
      return false;
    }

    toast.success('Server updated successfully');
    return true;
  };

  const uploadServers = async (options = {}) => {
    const { error, payload } = await ServerService.upload({
      ...options,
      token,
      headers: {
        ...(options.headers || {}),
        'Content-Type': 'application/form-data'
      }
    });

    if (error) {
      toast.error(error);
      return null;
    }

    return payload;
  };

  return {
    createServer,
    deleteServer,
    fetchServerById,
    fetchServers,
    searchServers,
    updateServer,
    uploadServers
  };
};
