import { SlidingRow } from 'app/member/layouts/sliding';
import { PrimaryStatCard } from 'app/shared/cards';
import { useAutomationJourneyService } from 'hooks/api/automata/journey';
import { useAutomationTemplateService } from 'hooks/api/automata/template';
import { useTenantService } from 'hooks/api/iam/tenant';
import { useCampaignService } from 'hooks/api/mailing/campaign';
import { useMailActionService } from 'hooks/api/mailing/mailaction';
import { useMailLogService } from 'hooks/api/mailing/maillog';
import { useTransactionalService } from 'hooks/api/mailing/transactional';
import { useWalletLogService } from 'hooks/api/sales/walletlog';
import { useAudienceService } from 'hooks/api/users/audience';
import { useContactService } from 'hooks/api/users/contact';
import React, { useEffect, useState } from 'react';

export const DashboardStatBar = ({ tenant_id }) => {
  const { fetchAudiences } = useAudienceService();
  const { fetchJourneys } = useAutomationJourneyService();
  const { fetchTemplates } = useAutomationTemplateService();
  const { fetchCampaigns } = useCampaignService();
  const { fetchContacts } = useContactService();
  const { fetchMailActions } = useMailActionService();
  const { fetchMailLogs } = useMailLogService();
  const { fetchTenants } = useTenantService();
  const { fetchTransactionals } = useTransactionalService();
  const { fetchWalletLogs } = useWalletLogService();

  const [audience_stat, setAudienceStat] = useState({});
  const [automation_stat, setAutomationStat] = useState({});
  const [campaign_emails_stat, setCampaignEmailStat] = useState({});
  const [campaign_stat, setCampaignStat] = useState({});
  const [contact_stat, setContactStat] = useState({});
  const [finance_stat, setFinanceStat] = useState({});
  const [tenant_stat, setTenantStat] = useState({});
  const [transactional_stat, setTransactionalStat] = useState({});

  useEffect(() => {
    const tenant_query = tenant_id ? `&tenant_id=${tenant_id}` : '';
    Promise.all([
      fetchCampaigns({
        query_string: `bool=-is_test_campaign&status=!draft&count=1${tenant_query}`
      }),
      fetchMailLogs({ query_string: `class=campaign&count${tenant_query}` })
    ]).then(([{ size: all }, { size: emails_sent }]) => {
      setCampaignStat(() => ({
        primary: all,
        secondary: emails_sent
      }));
    });

    Promise.all([
      fetchContacts({ query_string: `count=1${tenant_query}` }),
      fetchContacts({ query_string: `bool=-is_valid&count=1${tenant_query}` })
    ]).then(([{ size: all }, { size: invalid }]) => {
      setContactStat(() => ({
        primary: all,
        secondary: invalid
      }));
    });

    Promise.all([
      fetchAudiences({ query_string: `count=1${tenant_query}` }),
      fetchAudiences({ query_string: `bool=is_archived&count=1${tenant_query}` })
    ]).then(([{ size: all }, { size: archived }]) => {
      setAudienceStat(() => ({
        primary: all,
        secondary: archived
      }));
    });

    Promise.all([
      fetchCampaigns({ query_string: `count=1${tenant_query}` }),
      fetchMailActions({
        query_string: `resource_type=campaign&action=opened&count=1${tenant_query}`
      }),
      fetchMailActions({
        query_string: `resource_type=campaign&action=clicked_link&count=1${tenant_query}`
      })
    ]).then(([{ size: all }, { size: opened }, { size: clicks }]) => {
      setCampaignEmailStat(() => ({
        primary: (opened / all) * 100,
        secondary: (clicks / all) * 100
      }));
    });

    Promise.all([
      fetchTenants({ query_string: `count=1${tenant_query}` }),
      fetchTenants({ query_string: `bool=-is_active&count=1${tenant_query}` })
    ]).then(([{ size: all }, { size: inactive }]) => {
      setTenantStat(() => ({
        primary: all,
        secondary: inactive
      }));
    });

    Promise.all([
      fetchTransactionals({ query_string: `count=1${tenant_query}` }),
      fetchMailLogs({ query_string: `class=transactional&count${tenant_query}` })
    ]).then(([{ size: transactionals }, { size: logs }]) => {
      setTransactionalStat(() => ({
        primary: transactionals,
        secondary: logs
      }));
    });

    Promise.all([
      fetchTemplates({ query_string: `count=1${tenant_query}` }),
      fetchJourneys({ query_string: `count=1${tenant_query}` })
    ]).then(([{ size: templates }, { size: journeys }]) => {
      setAutomationStat(() => ({
        primary: templates,
        secondary: journeys
      }));
    });

    Promise.all([
      fetchWalletLogs({
        query_string: `resource_type=campaign&sum=amount${tenant_query}`
      }),
      fetchWalletLogs({
        query_string: `resource_type=transactional&sum=amount${tenant_query}`
      })
    ]).then(([{ walletlogs: campaign_costs }, { walletlogs: transactional_costs }]) => {
      setFinanceStat(() => ({
        primary: campaign_costs.reduce((sum, log) => sum + log.amount, 0),
        secondary: transactional_costs.reduce((sum, log) => sum + log.amount, 0)
      }));
    });
  }, []);

  return (
    <SlidingRow>
      {!tenant_id && (
        <PrimaryStatCard
          main_stat={{ label: 'Total Tenants', value: tenant_stat.primary }}
          bottom_stat={{ label: 'Inactive', value: tenant_stat.secondary }}
        />
      )}
      <PrimaryStatCard
        main_stat={{ label: 'Campaign Spends', value: finance_stat.primary }}
        bottom_stat={{ label: 'Transactionals', value: finance_stat.secondary }}
      />
      <PrimaryStatCard
        main_stat={{ label: 'Total Campaigns', value: campaign_stat.primary }}
        bottom_stat={{ label: 'Emails', value: campaign_stat.secondary }}
      />
      <PrimaryStatCard
        main_stat={{ label: 'Total Transactionals', value: transactional_stat.primary }}
        bottom_stat={{ label: 'Emails', value: transactional_stat.secondary }}
      />
      <PrimaryStatCard
        main_stat={{ label: 'Total Contacts', value: contact_stat.primary }}
        bottom_stat={{ label: 'Invalid', value: contact_stat.secondary }}
      />
      <PrimaryStatCard
        main_stat={{ label: 'Total Audiences', value: audience_stat.primary }}
        bottom_stat={{ label: 'Archived', value: audience_stat.secondary }}
      />
      <PrimaryStatCard
        main_stat={{ label: 'Average opens', value: campaign_emails_stat.primary }}
        bottom_stat={{ label: 'Average Clicks', value: campaign_emails_stat.secondary }}
      />
      <PrimaryStatCard
        main_stat={{ label: 'Total Automations', value: automation_stat.primary }}
        bottom_stat={{ label: 'Journeys', value: automation_stat.secondary }}
      />
    </SlidingRow>
  );
};
