import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PermissionListPage } from './list';

export const PermissionsModule = () => {
  return (
    <Switch>
      <Route path="/user-management/permissions" component={PermissionListPage} />
    </Switch>
  );
};
