import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as PurgeService from 'services/purge';

export const usePurgeService = () => {
  const { token } = useSelector((state) => state.user_data);

  const purgeAccount = async (tenant_id, options = {}) => {
    const [{ error: mailing_error }, { automata_error }, { error: notification_error }] =
      await Promise.all([
        PurgeService.destroyMailingData(tenant_id, { ...options, token }),
        PurgeService.destroyAutomationData(tenant_id, { ...options, token }),
        PurgeService.destroyNotificationData(tenant_id, { ...options, token })
      ]);

    const error = mailing_error || automata_error || notification_error;
    if (error) {
      toast.error(error);
      return false;
    }

    toast.success('Tenant records deleted successfully');
    return true;
  };

  return {
    purgeAccount
  };
};
