import { Spacer } from 'app/member/layouts/generic';
import { PageContentWrapper } from 'app/member/layouts/wrapper/page-content';
import SlidingNav from 'app/shared/nav/sliding/sliding';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { setPageTitle } from 'store/actions/header';
import { PermissionsModule } from './permissions';
import { ResourcesModule } from './resources';
import { RolesModule } from './roles';
import { UsersModule } from './users';

const NAVITEMS = [
  {
    title: 'Users',
    path: '/user-management/users'
  },
  {
    title: 'Roles',
    path: '/user-management/roles'
  },
  {
    title: 'Resources',
    path: '/user-management/resources'
  },
  {
    title: 'Permissions',
    path: '/user-management/permissions'
  }
];

export const UserManagementModule = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'User Management', path: '.' }]));
  }, []);

  return (
    <PageContentWrapper>
      <SlidingNav nav_items={NAVITEMS} />
      <Spacer multiple={4} />
      <Switch>
        <Route path="/user-management/users" component={UsersModule} />
        <Route path="/user-management/roles" component={RolesModule} />
        <Route path="/user-management/resources" component={ResourcesModule} />
        <Route path="/user-management/permissions" component={PermissionsModule} />
      </Switch>
    </PageContentWrapper>
  );
};
