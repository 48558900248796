import { Spacer } from 'app/member/layouts/generic';
import { Icon } from 'assets/icons';
import styles from '../create.module.css';
const { GridRow, GridColumn } = require('app/member/layouts/grid');
const { BasicInfoSection } = require('app/shared/info-section/basic');
const { SimpleInput } = require('app/shared/input');
const { MultiSelect } = require('app/shared/select');

export const InputSection = ({ title, options, items, addItem, removeItem, handleItemChange }) => {
  return (
    <BasicInfoSection title={title}>
      {items?.map((item, index) => (
        <GridRow key={index} num_of_columns={8}>
          <GridColumn span={5}>
            <MultiSelect
              options={options}
              value={item.field}
              onChange={(value) => handleItemChange(index, 'field', value)}
              required
            />
          </GridColumn>
          <GridColumn span={2}>
            <SimpleInput
              input_id={`${title.toLowerCase()}_value_${index}`}
              value={item.value}
              onInput={(value) => handleItemChange(index, 'value', value)}
              required
            />
          </GridColumn>
          <GridColumn>
            <span className={styles['remove-icon']}>
              <Icon name="trash" onClick={() => removeItem(index)} />
            </span>
          </GridColumn>
        </GridRow>
      ))}

      <Spacer multiple={3} />
      <GridRow>
        <span className={styles['add-icon']}>
          <Icon name="add" onClick={addItem} />
        </span>
      </GridRow>
    </BasicInfoSection>
  );
};
