import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { useParams, useHistory } from 'react-router-dom';
import { Button } from 'app/shared/button';
import Form from 'app/shared/form';
import { SimpleInput } from 'app/shared/input';
import { MultiSelect } from 'app/shared/select';
import { capitalizeText } from 'app/shared/utils/general';
import { updateEventInStore } from 'store/actions/event';
import { createValidationConfig } from 'app/shared/utils/form/validation';
import useValidator from 'hooks/validation';
import { useAutomationEventService } from 'hooks/api/automata/event';

const CreateEventForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const { fetchEvent, updateEvent, seedEvents } = useAutomationEventService();

  const [loading, setLoading] = useState(false);
  const [is_seeding, setIsSeeding] = useState(false);
  const [data, setData] = useState({
    name: '',
    code: '',
    resource_class: ''
  });
  const { errors, validateField, form_is_valid } = useValidator(createValidationConfig);

  useEffect(() => {
    if (id) {
      fetchEvent(id).then(async ({ event }) => {
        if (!event) return;

        const { code, name, resource_class, in_use } = event;
        const updated_code = code.split(':')[0];
        setData((previousValues) => ({
          ...previousValues,
          code: updated_code,
          name,
          resource_class,
          in_use
        }));
      });
    }
  }, []);

  const resource_class_list = [
    {
      label: 'Email',
      value: 'email'
    },
    {
      label: 'Link',
      value: 'link'
    },
    {
      label: 'Audience',
      value: 'audience'
    },
    {
      label: 'Contact',
      value: 'contact'
    }
  ];

  const submitForm = async () => {
    setLoading(true);
    if (!id) {
      return toast.error('No event selected.');
    } else {
      const result = await updateEvent(id, { data });
      if (result) {
        const values = { ...data, code: data.code, id };
        dispatch(updateEventInStore(values));
        history.push(`/settings/event`);
      }
    }

    setLoading(false);
  };

  const handleChange = (event) => {
    const { name, value } = event;

    const code_in_use = id && data.in_use;

    if (name === 'name') {
      const lowercaseValue = value.toLowerCase().replace(/ /g, '_');
      setData((prevData) => ({
        ...prevData,
        name: value,
        code: code_in_use ? prevData.code : lowercaseValue
      }));
      validateField(name, value);
      validateField('code', lowercaseValue);
    } else {
      let processedValue = value;
      if (name === 'code' && code_in_use) return;
      if (name === 'code') {
        processedValue = value.toLowerCase().replace(/ /g, '_');
      }

      setData((prevData) => ({
        ...prevData,
        [name]: processedValue
      }));

      validateField(name, processedValue);
    }
  };

  const handleSeedAction = async () => {
    setIsSeeding(() => true);
    await seedEvents();
    setIsSeeding(() => false);
    history.push(`/settings/event`);
  };

  return (
    <Form className="event-form">
      <h5 className="mb-4">Edit Event</h5>
      <hr />
      <div className="grid">
        <SimpleInput
          label="NAME *"
          input_id="name"
          name="name"
          value={data.name}
          onInput={handleChange}
          error={errors.name}
        />
        <SimpleInput
          label="CODE *"
          input_id="code"
          name="code"
          value={data.code}
          onInput={handleChange}
          error={errors.code}
        />
        <div className="form-group">
          <label>RESOURCE CLASS *</label>
          <MultiSelect
            options={resource_class_list}
            onChange={(v) => handleChange({ name: 'resource_class', value: v.value })}
            value={{ value: data.resource_class, label: capitalizeText(data.resource_class) }}
            isMulti={false}
            error={errors.resource_class}
          />
        </div>
      </div>

      <div className="grid mt-2 mb-4">
        <Button
          onClick={submitForm}
          text="Save"
          className="float-right"
          loading={loading}
          disabled={!form_is_valid}
        />
      </div>
      <hr />

      <div className="grid mt-4">
        <Button
          onClick={handleSeedAction}
          text="Create system events "
          className="float-right"
          loading={is_seeding}
        />
      </div>
    </Form>
  );
};

export default CreateEventForm;
