import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setPageTitle } from 'store/actions/header';
import { useWalletService } from 'hooks/api/sales/wallet';
import { DashboardStatBar } from 'app/member/modules/dashboard/components/statbar';
import { GridColumn, GridRow } from 'app/member/layouts/grid';
import { CampaignPerformance } from 'app/shared/performance/campaign';
import { TransactionalPerformance } from 'app/shared/performance/transactional';
import { ContactPerformance } from 'app/shared/performance/contact';
import { SpendingPerformance } from 'app/shared/performance/spends';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { DetailItem } from 'app/shared/info-section/item';
import { useNumbers } from 'hooks/ui/general/numbers';
import { Spacer } from 'app/member/layouts/generic';
import { formatDateForDisplay } from 'app/shared/utils/date';
import { useTenantService } from 'hooks/api/iam/tenant';

const TenantDashboard = () => {
  const { id: tenant_id } = useParams();
  const dispatch = useDispatch();
  const { toReadableNumber } = useNumbers();
  const { fetchTenant } = useTenantService();
  const { fetchWallets } = useWalletService();

  const [wallet, setWallet] = useState({});
  const [tenant, setTenant] = useState({});

  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Tenants', path: '.' },
        { title: '', path: `/tenants/${tenant_id}` }
      ])
    );
    fetchTenant(tenant_id).then(({ tenant }) => {
      if (!tenant) return;

      setTenant(() => tenant);
    });

    fetchWallets({ query_string: `tenant_id=${tenant_id}` }).then(({ wallets }) => {
      const [wallet] = wallets;
      if (!wallet) return;

      setWallet(() => wallet);
    });
  }, []);

  return (
    <>
      <GridRow num_of_columns={1}>
        <DashboardStatBar tenant_id={tenant_id} />
      </GridRow>
      <Spacer multiple={4} />
      <GridRow>
        <GridColumn span={3}>
          <CampaignPerformance tenant_id={tenant_id} />
          <Spacer multiple={4} />
          <TransactionalPerformance tenant_id={tenant_id} />
          <Spacer multiple={4} />
          <ContactPerformance tenant_id={tenant_id} />
          <Spacer multiple={4} />
          <SpendingPerformance tenant_id={tenant_id} />
        </GridColumn>
        <GridColumn span={1}>
          <BasicInfoSection title="Basic Details">
            <DetailItem title="Name">{tenant.name || 'N/A'}</DetailItem>
            <DetailItem title="Email Address">
              {(tenant.emails && tenant.emails[0]) || 'N/A'}
            </DetailItem>
            <DetailItem title="Industry">{tenant.industry || 'N/A'}</DetailItem>
            <DetailItem title="Phone Number">{tenant.phone_number || 'N/A'}</DetailItem>
            <DetailItem title="Subscriber count">{tenant.subscriber_count || 'N/A'}</DetailItem>
            <DetailItem title="Wallet Balance">{toReadableNumber(wallet.balance) || 0}</DetailItem>
            <DetailItem title="Wallet Rate">{wallet.rate || 0.8}</DetailItem>
            <DetailItem title="Date Created">
              {formatDateForDisplay(tenant.created_on) || 'N/A'}
            </DetailItem>
            <DetailItem title="Last Login">
              {formatDateForDisplay(tenant.last_login) || 'N/A'}
            </DetailItem>
          </BasicInfoSection>
        </GridColumn>
      </GridRow>
    </>
  );
};
export default TenantDashboard;
