import { PageContentWrapper } from 'app/member/layouts/wrapper/page-content';
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { SMSListPage } from './list/list';
import { SMSViewPage } from './views';

export const SMSModule = () => {
  return (
    <PageContentWrapper>
      <Switch>
        <Route path="/sms/:id" component={SMSViewPage} />
        <Route path="/sms" component={SMSListPage} />
      </Switch>
    </PageContentWrapper>
  );
};
