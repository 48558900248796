import React, { useState, useEffect, useRef } from 'react';
import ContextMenu from 'app/shared/datatable/context-menu/context-menu';

import styles from './item.module.css';
import { Icon } from 'assets/icons';

const DataTableItem = (props) => {
  const {
    actions,
    action_callback,
    checkbox,
    data,
    bulk_selection,
    fields,
    index,
    item_click_callback,
    selection_callback,
    unselection_callback
  } = props;
  const checkbox_ref = useRef();

  const [row_data, setRowData] = useState([]);
  const [is_selected, setIsSelected] = useState(false);

  const clipText = (text) => {
    if (!text) return '';
    if (text && (text.length < 50 || typeof text !== 'string')) return text;
    return `${text.substr(0, 50)}...`;
  };

  useEffect(() => {
    const field_keys = {};
    const cells = [];
    fields.forEach((field, index) => {
      field_keys[field.key] = field;
      if (field.formatter) {
        cells.push(
          <td onClick={() => item_click_callback(data)} key={`${index}-${data[field.key]}`}>
            {clipText(field.formatter(data[field.key]))}
          </td>
        );
        return;
      }

      cells.push(
        <td onClick={() => item_click_callback(data)} key={`${index}-${data[field.key]}`}>
          {clipText(data[field.key])}
        </td>
      );
    });

    setRowData(cells);
  }, [data, fields, index, item_click_callback]);

  useEffect(() => {
    setIsSelected(() => bulk_selection[index]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulk_selection]);

  const processAction = (action) => {
    action_callback(action, data);
  };

  const handleItemSelection = () => {
    if (!checkbox) return;
    if (!is_selected) selection_callback(data);
    else unselection_callback(data.id);
  };

  const checkboxClass = `${styles.gmCheckbox} ${
    is_selected ? styles.checkboxOn : !checkbox ? styles.disabledCheckbox : ''
  } `;

  return (
    <tr className={styles.item}>
      <td key={`checkbox-${index}`}>
        <div ref={checkbox_ref} className={checkboxClass} onClick={handleItemSelection}></div>
      </td>
      {row_data}

      {actions ? (
        <td key={`action-${index}`}>
          <ContextMenu
            actions={actions(data)}
            callback={processAction}
            text={<Icon name="more_vert" />}
          />
        </td>
      ) : (
        ''
      )}
    </tr>
  );
};

export default DataTableItem;
