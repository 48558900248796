import { GridColumn, GridRow } from 'app/member/layouts/grid';
import { DetailItem } from 'app/shared/info-section/item';
import { SimpleInput } from 'app/shared/input';
import { TextArea } from 'app/shared/input/textarea';
import { MultiSelect } from 'app/shared/select';
import styles from './create.module.css';
import React, { useEffect, useState } from 'react';
import { Button } from 'app/shared/button';
import { useTutorialService } from 'hooks/api/files/tutorial';
import { useDispatch } from 'react-redux';
import { addOneTutorialToStore } from 'store/actions/tutorial';
import { Checkbox } from 'app/shared/input/checkbox';
import { Spacer } from 'app/member/layouts/generic';

const PRIORITY_OPTIONS = [
  { label: 'High', value: 3 },
  { label: 'Medium', value: 2 },
  { label: 'Low', value: 1 }
];

export const TutorialCreation = ({ tutorial_data = {}, closeModal = () => {} }) => {
  const dispatch = useDispatch();
  const { createTutorial, updateTutorial } = useTutorialService();

  const [loading, setLoading] = useState(false);
  const [tutorial_id, setTutorialId] = useState(0);
  const [form_data, setFormData] = useState({});
  const [urls, setUrls] = useState({});
  const [selected_priority, setSelectedPriority] = useState(PRIORITY_OPTIONS[0]);

  useEffect(() => {
    return clearForm;
  }, []);

  useEffect(() => {
    setFormData(() => ({
      ...tutorial_data
    }));
    setUrls(() => tutorial_data.urls || {});
    setSelectedPriority(
      () => PRIORITY_OPTIONS.find((priority) => priority.value === tutorial_data.priority) || {}
    );
    setTutorialId(() => tutorial_data.id);
  }, [tutorial_data]);

  const clearForm = () => {
    setFormData({});
    setUrls({});
    setTutorialId(0);
    setLoading(false);
  };

  const handleInputChange = (key, value) => {
    if (!key) return;
    setFormData((data) => ({ ...data, [key]: value }));
  };

  const handleUrlChange = (key, value) => {
    if (!key) return;
    setUrls((data) => ({ ...data, [key]: value }));
  };

  const submit = async () => {
    const data = {
      ...form_data,
      urls,
      priority: selected_priority.value
    };

    setLoading(() => true);
    const { tutorial } = tutorial_id
      ? await updateTutorial(tutorial_id, { data })
      : await createTutorial({ data });

    if (Object.keys(tutorial).length) {
      dispatch(addOneTutorialToStore({ ...tutorial_data, ...form_data, ...tutorial }));
      clearForm();
      closeModal();
    }
    setLoading(() => false);
  };

  return (
    <>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Title">
            <SimpleInput value={form_data.title} onInput={(v) => handleInputChange('title', v)} />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Description">
            <TextArea
              value={form_data.description}
              className={styles.textarea}
              onInput={(v) => handleInputChange('description', v)}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Video URL">
            <SimpleInput value={urls.video} onInput={(v) => handleUrlChange('video', v)} />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Blog URL">
            <SimpleInput value={urls.blog} onInput={(v) => handleUrlChange('blog', v)} />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Thumbnail URL">
            <SimpleInput value={urls.thumbnail} onInput={(v) => handleUrlChange('thumbnail', v)} />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={2}>
        <GridColumn>
          <DetailItem title="code">
            <SimpleInput value={form_data.code} onInput={(v) => handleInputChange('code', v)} />
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="Priority">
            <MultiSelect
              value={selected_priority}
              options={PRIORITY_OPTIONS}
              onChange={setSelectedPriority}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <Spacer multiple={2} />
          <Checkbox
            checked={form_data.is_welcome}
            onClick={(v) => handleInputChange('is_welcome', !form_data.is_welcome)}
            label="is welcome video"
          />
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={2}>
        <GridColumn />
        <GridColumn>
          <Button text="Save" onClick={submit} disabled={loading} />
        </GridColumn>
      </GridRow>
    </>
  );
};
