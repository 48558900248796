import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as ResourceService from 'services/iam/resource';

export const useResourceService = () => {
  const { token } = useSelector((store) => store.user_data);

  const createResource = async (options = {}) => {
    const { error, payload: resource } = await ResourceService.create({
      ...options,
      token
    });

    if (error) {
      toast.error('Cannot create resource at this time');
      return { resource: {} };
    }

    toast.success('Resource created successfully.');
    return { resource };
  };

  const fetchResource = async (id, options = {}) => {
    const { error, payload } = await ResourceService.readById(id, { ...options, token });
    if (error) {
      toast.error('Unable to fetch resource at this time.');
      return { resource: {} };
    }

    return { resource: payload };
  };

  const fetchResources = async (options = {}) => {
    const { error, payload } = await ResourceService.read({ ...options, token });
    if (error) {
      toast.error('Unable to fetch resources at this time.');
      return { resources: [], size: 0 };
    }

    const {
      data: resources,
      meta: { size }
    } = payload;
    return { resources, size };
  };

  const searchResources = async (keys, keyword, options = {}) => {
    const { error, payload } = await ResourceService.search(keys, keyword, { ...options, token });
    if (error) {
      toast.error('Unable to fetch resources at this time.');
      return { resources: [], size: 0 };
    }

    const {
      data: resources,
      meta: { size }
    } = payload;
    return { resources, size };
  };

  return { createResource, fetchResource, fetchResources, searchResources };
};
