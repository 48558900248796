import './dashboard.css';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from 'store/actions/header';
import { GridColumn, GridRow } from 'app/member/layouts/grid';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { DetailItem } from 'app/shared/info-section/item';
import { useAudienceService } from 'hooks/api/users/audience';
import { formatDateForDisplay } from 'app/shared/utils/date';
import { useSMSService } from 'hooks/api/messaging/sms';

export const CampaignInformation = ({ id }) => {
  const dispatch = useDispatch();
  const { fetchAudiences } = useAudienceService();
  const { fetchSMSs } = useSMSService();

  const [audiences, setAudiences] = useState([]);
  const [message, setMessage] = useState({});

  useEffect(() => {
    fetchSMSs({ query_string: `id=${id}` }).then(async ({ messages: [message] }) => {
      if (!message) return;
      const { audiences } = message;

      setMessage(message);
      dispatch(
        setPageTitle([
          { title: 'SMS', path: '/messaging/sms' },
          { title: message.title, path: `.` }
        ])
      );

      const { audiences: audience_from_db } = await fetchAudiences({
        query_string: `id=${audiences.join(',')}`
      });
      setAudiences(audience_from_db);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GridRow>
      <GridColumn span={1}>
        <BasicInfoSection title="Basic Details">
          <DetailItem title="Title">{message.title || 'N/A'}</DetailItem>
          <DetailItem title="Sender ID">{message.sender_id || 'N/A'}</DetailItem>
          <DetailItem title="Scheduled date:">
            {formatDateForDisplay(message.schedule?.date) || 'N/A'}
          </DetailItem>
          <DetailItem title="Status:">{message.status || 'N/A'}</DetailItem>
          <DetailItem title="Audiences">
            {audiences.map((audience) => (
              <p key={audience.id}>{audience.name}</p>
            ))}
          </DetailItem>
        </BasicInfoSection>
      </GridColumn>
      <GridColumn span={3}></GridColumn>
    </GridRow>
  );
};
