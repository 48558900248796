import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { addManyCurrencyToStore } from 'store/actions/currency';
import { Button } from 'app/shared/button';
import { SimpleInput } from 'app/shared/input';
import { MultiSelect } from 'app/shared/select';
import { useCurrencyService } from 'hooks/api/sales/currency';

const UpdateCurrency = ({ currency, closeModal }) => {
  const dispatch = useDispatch();
  const { fetchCurrencies, fetchCurrencyCountries, updateCurrency } = useCurrencyService();

  const [countries, setCountries] = useState([]);
  const [exchange_rate, setExchangeRate] = useState('');
  const [selected_country, setSelectedCountry] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCurrencyCountries().then(({ countries }) => {
      const checked_countries = {};
      const valid_countries = [];

      countries.forEach((country) => {
        const formatted_country = {
          label: `${country.currencies ? country.currencies[0].name : ''} (${
            country.currencies ? country.currencies[0].code : ''
          })`,
          value: `${country.currencies ? country.currencies[0].name : ''}`,
          img_url: country.flags?.png || country.flags?.svg,
          country_code: country.alpha2Code,
          currency_code: country.currencies ? country.currencies[0].code : ''
        };

        if (!formatted_country.country_code) return;
        if (checked_countries[formatted_country.currency_code]) return;
        checked_countries[formatted_country.country_code] = 1;

        valid_countries.push(formatted_country);
      });

      setCountries(() => valid_countries);
    });
  }, []);

  useEffect(() => {
    if (currency) {
      setExchangeRate(currency.exchange_rate);
      setSelectedCountry({
        label: `${currency.name} (${currency.code})`,
        value: currency.name,
        img_url: currency.img_url,
        country_code: currency.country_code,
        currency_code: currency.code
      });
    }
  }, [currency]);

  const clearForm = () => {
    setSelectedCountry('');
    setExchangeRate('');
  };

  const handleDataRequest = async () => {
    try {
      setLoading(true);
      const { currencies } = await fetchCurrencies({
        query_string: `sort_by=-created_on`
      });
      dispatch(addManyCurrencyToStore(currencies));
    } catch (e) {
      dispatch(addManyCurrencyToStore([]));
    }
  };

  const update = async () => {
    const data = {
      code: selected_country.currency_code,
      country_code: selected_country.country_code,
      name: selected_country.value,
      exchange_rate
    };

    if (!selected_country || !selected_country.value || !exchange_rate) {
      toast.error('please fill the necessary fields');
      return;
    }

    setLoading(true);
    const result = await updateCurrency(currency.id, { data });

    setLoading(false);
    if (!result) return;
    await handleDataRequest();
    clearForm();
    closeModal();
  };

  return (
    <div>
      <div>
        <label>Country:</label>
        <MultiSelect options={countries} onChange={setSelectedCountry} value={selected_country} />
      </div>
      {selected_country && (
        <SimpleInput
          placeholder="Currency exchange rate"
          required
          value={exchange_rate}
          onInput={(value) => setExchangeRate(value)}
        />
      )}
      <Button
        icon_name="add"
        text="Update"
        onClick={update}
        className="float-right w-25 mt-4 mb-4"
        loading={loading}
      />
      <Button
        text="Cancel"
        type="secondary"
        onClick={() => {
          closeModal();
        }}
        className="float-right w-25 mt-4 mb-4 mr-4"
        loading={loading}
      />
    </div>
  );
};

export default UpdateCurrency;
