import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as MailLogService from 'services/mailing/maillog';

export const useMailLogService = () => {
  const { token } = useSelector((store) => store.user_data);
  const fetchMailLogs = async (options = {}) => {
    const { error, payload } = await MailLogService.read({ ...options, token });
    if (error) {
      toast.error('Unable to fetch maillogs at this time.');
      return { maillogs: [], size: 0 };
    }

    const {
      data: maillogs,
      meta: { size }
    } = payload;
    return { maillogs, size };
  };

  return { fetchMailLogs };
};
