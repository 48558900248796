import { Spacer } from 'app/member/layouts/generic';
import { GridColumn, GridRow } from 'app/member/layouts/grid';
import { Button } from 'app/shared/button';
import { DetailItem } from 'app/shared/info-section/item';
import { SimpleInput } from 'app/shared/input';
import { MultiSelect } from 'app/shared/select';
import { usePermissionService } from 'hooks/api/iam/permission';
import { useResourceService } from 'hooks/api/iam/resource';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { addOnePermissionToStore } from 'store/actions/permission';

const group_options = [
  { label: 'General', value: 'general' },
  { label: 'Configuration', value: 'configuration' }
];

const actions_options = [
  { label: 'Create', value: 'create' },
  { label: 'View', value: 'view' },
  { label: 'Update', value: 'update' },
  { label: 'Delete', value: 'delete' }
];

export const CreatePermission = ({ closeModal = () => {}, data = {} }) => {
  const dispatch = useDispatch();
  const { createPermission, updatePermission } = usePermissionService();
  const { fetchResources } = useResourceService();

  const [form_data, setFormData] = useState({});
  const [resource_options, setResourceOptions] = useState([]);
  const [selected_actions, setSelectedActions] = useState([]);
  const [selected_group, setSelectedGroup] = useState({});
  const [selected_resource, setSelectedResource] = useState({});

  useEffect(() => {
    fetchResources().then(({ resources }) => {
      setResourceOptions(() =>
        resources.map((resource) => ({
          ...resource,
          label: resource.name,
          value: resource.code
        }))
      );
    });
  }, []);

  useEffect(() => {
    if (!data.id) return;

    setFormData(() => ({ ...data }));
    setSelectedActions(() =>
      actions_options.filter((action) => (data.actions || []).includes(action.value))
    );
    setSelectedGroup(() => group_options.find((group) => group.value === data.group));
    setSelectedResource(() =>
      resource_options.find((resource) => resource.value === data.resource)
    );
  }, [data.id, resource_options]);

  const handleInputChange = (key, value) => {
    setFormData((curr_value) => ({
      ...curr_value,
      [key]: value
    }));
  };

  const submit = async () => {
    if (
      !form_data.name ||
      !form_data.code ||
      !selected_resource.value ||
      !selected_group.value ||
      !selected_actions.length
    ) {
      toast.error('Please fill all fields');
      return;
    }

    const actions = selected_actions.map((action) => action.value);
    const data = {
      ...form_data,
      actions,
      group: selected_group.value,
      resource: selected_resource.value
    };

    const { permission } = !data.id
      ? await createPermission({ data })
      : await updatePermission(data.id, { data });

    if (permission) {
      dispatch(addOnePermissionToStore(permission));
      closeModal();
    }
  };

  return (
    <>
      <GridRow num_of_columns={1}>
        <DetailItem title="Name">
          <SimpleInput onInput={(v) => handleInputChange('name', v)} value={form_data.name} />
        </DetailItem>
      </GridRow>
      <GridRow num_of_columns={1}>
        <DetailItem title="Code">
          <SimpleInput
            onInput={(v) => handleInputChange('code', v)}
            value={form_data.code}
            disabled={!!data.id}
          />
        </DetailItem>
      </GridRow>
      <GridRow num_of_columns={2}>
        <GridColumn>
          <DetailItem title="Group">
            <MultiSelect
              options={group_options}
              onChange={setSelectedGroup}
              value={selected_group}
            />
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="Resource">
            <MultiSelect
              onChange={setSelectedResource}
              value={selected_resource}
              options={resource_options}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={1}>
        <DetailItem title="Actions">
          <MultiSelect
            onChange={setSelectedActions}
            value={selected_actions}
            options={actions_options}
            isMulti
          />
        </DetailItem>
      </GridRow>
      <Spacer multiple={4} />
      <GridRow num_of_columns={1}>
        <Button text="Save" onClick={submit} />
      </GridRow>
    </>
  );
};
