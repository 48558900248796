import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as EventService from 'services/automata/event';

export const useAutomationEventService = () => {
  const { token } = useSelector((store) => store.user_data);

  const createEvent = async (data, options = {}) => {
    const { error, payload: event } = await EventService.create({
      ...options,
      data,
      token
    });

    if (error) {
      toast.error('Cannot create event at this time');
      return { evnt: {} };
    }

    toast.error('Event created successfully.');
    return { event };
  };

  const deleteEvent = async (id, options = {}) => {
    const { error } = await EventService.deleteById(id, {
      ...options,
      token
    });

    if (error) {
      toast.error('Unable to delete event at this time.');
      return false;
    }

    toast.success('Event deleted successfully');
    return true;
  };

  const fetchEvent = async (id, options = {}) => {
    const { error, payload: event } = await EventService.readById({ ...options, token });

    if (error) {
      toast.error(error);
      return { event: null };
    }

    return { event };
  };

  const fetchEvents = async (options = {}) => {
    const { error, payload } = await EventService.read({ ...options, token });

    if (error) {
      toast.error(error);
      return { events: [], size: 0 };
    }

    const {
      data: events,
      meta: { size }
    } = payload;
    return { events, size };
  };

  const seedEvents = async () => {
    const { error } = await EventService.seed({ token });
    if (error) {
      toast.error('Unable to seed events at this time.');
      return false;
    }

    toast.success('Event seeded successfully');
    return true;
  };

  const updateEvent = async (id, options = {}) => {
    const { error } = await EventService.updateById(id, {
      ...options,
      token
    });

    if (error) {
      toast.error('Unable to update event at this time.');
      return false;
    }

    toast.success('Event updated successfully');
    return true;
  };

  return { createEvent, deleteEvent, fetchEvent, fetchEvents, seedEvents, updateEvent };
};
