import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import styles from './sliding.module.css';

export const SlidingRow = ({ className, children }) => {
  const [width, setWidth] = useState('100%');

  useEffect(() => {
    const elements = document.querySelectorAll('.slider > *');
    let total_width = 0;
    elements.forEach((element) => {
      const marginRight = getComputedStyle(element).marginRight.split('px')[0];
      total_width += element.clientWidth + Number(marginRight);
    });
    setWidth(() => total_width + elements.length * 2);
  }, [children]);

  return (
    <div className={cx(styles.wrapper, className)}>
      <div className={cx(styles.slider, 'slider')} style={{ minWidth: `${width}px` }}>
        {children}
      </div>
    </div>
  );
};
