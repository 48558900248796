import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from 'app/shared/button';
import Form from 'app/shared/form';
import { SimpleInput } from 'app/shared/input';
import { GridColumn, GridRow } from 'app/member/layouts/grid';
import { useAdminService } from 'hooks/api/users/admin';

export const PasswordUpdateForm = () => {
  const { resetPassword } = useAdminService();
  const user_data = useSelector((state) => state.user_data);
  const { email } = user_data;

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});

  const clearForm = () => {
    setFormData({});
  };

  const submitForm = async () => {
    if (!formData.old_password) {
      toast.error('Current Password is required.');
      return;
    }

    if (formData.new_password !== formData.confirm_password) {
      toast.error('New Password does not match.');
      return;
    }

    const data = {
      ...formData,
      email
    };

    setLoading(true);
    const result = await resetPassword({ data });
    setLoading(false);
    if (result) clearForm();
  };

  return (
    <GridRow>
      <GridColumn />
      <GridColumn span={2}>
        <Form>
          <div className="mb-4">
            <h5> Update Password </h5>
          </div>
          <div className="grid cols-1">
            <SimpleInput
              type="password"
              label="Current Password:"
              input_id="old_password"
              value={formData.old_password}
              onInput={(value) => setFormData({ ...formData, old_password: value })}
            />
            <SimpleInput
              type="password"
              label="New Password:"
              input_id="password"
              value={formData.new_password}
              onInput={(value) => setFormData({ ...formData, new_password: value })}
            />
            <SimpleInput
              type="password"
              label="Confirm Password:"
              input_id="confirm_password"
              value={formData.confirm_password}
              onInput={(value) => setFormData({ ...formData, confirm_password: value })}
            />
          </div>

          <div className="grid cols-3 mt-2">
            <div />
            <div />
            <Button
              onClick={submitForm}
              text="Update Password"
              className="float-right"
              loading={loading}
            />
          </div>
        </Form>
      </GridColumn>
      <GridColumn />
    </GridRow>
  );
};
