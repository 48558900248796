import React from 'react';
import cx from 'classnames';
import styles from './primary.module.css';
import { useNumbers } from 'hooks/ui/general/numbers';

export const PrimaryStatCard = ({
  className,
  wrapperStyle = {},
  main_stat = {},
  bottom_stat = {}
}) => {
  const { toReadableNumber } = useNumbers();
  return (
    <div className={cx(styles.wrapper, className)} style={wrapperStyle}>
      <div className={styles.main} style={{ backgroundColor: main_stat.bg }}>
        <label style={{ color: main_stat.label_color }}>{main_stat.label || 'Main Label'}</label>
        <h3 style={{ color: main_stat.color }}>{toReadableNumber(main_stat.value || 0)}</h3>
      </div>
      {bottom_stat && bottom_stat.label ? (
        <div className={styles.bottom}>
          {bottom_stat.label || 'Bottom Label'}:{' '}
          {Number(bottom_stat.value)
            ? toReadableNumber(bottom_stat.value || 0)
            : bottom_stat.value || 'N/A'}
        </div>
      ) : null}
    </div>
  );
};
