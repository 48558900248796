import React from 'react';
import { Button } from 'app/shared/button';

import './dialog.css';
const ConfirmationDialog = ({ message, callback: onAction, is_open }) => {
  const cancel = () => onAction(false);
  const proceed = () => onAction(true);
  return (
    <>
      {is_open ? (
        <div className="dialog-veil">
          <div className="dialog-content-wrapper">
            <div className="dialog-message">{message}</div>
            <div className="dialog-actions">
              <Button className="w-50" onClick={cancel} type="secondary" text="Cancel" />
              <Button className="w-50" onClick={proceed} text="proceed" />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ConfirmationDialog;
