import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as TenantService from 'services/iam/tenant';

export const useTenantService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchTenant = async (id, options = {}) => {
    const { error, payload } = await TenantService.readById(id, { ...options, token });
    if (error) {
      toast.error('Unable to fetch tenant at this time.');
      return { tenant: {} };
    }

    return { tenant: payload };
  };

  const fetchTenants = async (options = {}) => {
    const { error, payload } = await TenantService.read({ ...options, token });
    if (error) {
      toast.error('Unable to fetch tenants at this time.');
      return { tenants: [], size: 0 };
    }

    const {
      data: tenants,
      meta: { size }
    } = payload;
    return { tenants, size };
  };

  const searchTenants = async (keys, keyword, options = {}) => {
    const { error, payload } = await TenantService.search(keys, keyword, { ...options, token });
    if (error) {
      toast.error('Unable to fetch tenants at this time.');
      return { tenants: [], size: 0 };
    }

    const {
      data: tenants,
      meta: { size }
    } = payload;
    return { tenants, size };
  };

  const updateTenant = async (id, options = {}) => {
    const { error } = await TenantService.updateById(id, { ...options, token });
    if (error) {
      toast.error('Unable to update tenant at this time.');
      return false;
    }

    toast.error('Tenant updated successfully.');
    return true;
  };

  return { fetchTenant, fetchTenants, searchTenants, updateTenant };
};
