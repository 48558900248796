import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as MailActionService from 'services/mailing/mailaction';

export const useMailActionService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchMailActions = async (options = {}) => {
    const { error, payload } = await MailActionService.read({ ...options, token });
    if (error) {
      toast.error('Unable to fetch mail actions at this time.');
      return { mailactions: [], size: 0 };
    }

    const {
      data: mailactions,
      meta: { size }
    } = payload;
    return { mailactions, size };
  };

  return { fetchMailActions };
};
