import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import { formatDateForDisplay } from 'app/shared/utils/date';
import styles from './logs.module.css';
import { useMailLogService } from 'hooks/api/mailing/maillog';

const config = (items = [], is_search_mode) => ({
  actions: { single: () => [] },
  css: {},
  fields: [
    {
      title: 'Sender Email',
      key: 'sender_email',
      formatter: (value) => value || 'N/A',
      isTitle: true
    },
    {
      title: 'Recipient',
      key: 'recipient',
      formatter: (value) => value || 'N/A',
      isTitle: true
    },
    {
      title: 'Server',
      key: 'server',
      formatter: (value) => value || 'N/A'
    },
    {
      title: 'Tenant ID',
      key: 'tenant_id',
      formatter: (value) => value || 'N/A'
    },
    {
      title: 'Status',
      key: 'status',
      searchable: true,
      formatter: (value) => {
        switch (value) {
          case 'sent':
            return <span className={styles.greenBadge}>{value}</span>;
          default:
            return <span className={styles.dangerBadge}>{value}</span>;
        }
      },
      isBadge: true
    },
    {
      title: 'Sent at',
      key: 'time_stamp',
      formatter: (value) => formatDateForDisplay(value)
    }
  ],
  is_search_mode,
  items: items.sort((a, b) => b.time_stamp - a.time_stamp),
  search_key: 'name',
  search_text: ''
});

export const TransactionalEmailLogs = () => {
  const { fetchMailLogs } = useMailLogService();
  const { is_mobile_view } = useSelector((state) => state.metadata);

  const [bounce_log, setBounceLog] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total_bounces, setTotalBounces] = useState(0);

  const handleDataRequest = async (page, population = 50) => {
    if (loading) return;
    setLoading(true);

    const { maillogs, size } = await fetchMailLogs({
      query_string: `class=transactional&page=${page}&population=${population}&sort_by=-time_stamp`
    });

    setBounceLog((curr_logs) => [...curr_logs, ...maillogs]);
    setTotalBounces(() => size);
    setLoading(false);
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {};

  return (
    <div>
      {is_mobile_view ? (
        <MobileDatatable
          config={{ ...config(bounce_log), is_search_mode }}
          action={(f) => f}
          onClick={(f) => f}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      ) : (
        <WebDatatable
          config={{
            ...config(bounce_log),
            is_search_mode,
            total_count: total_bounces
          }}
          action={() => {}}
          onClick={(f) => f}
          checkbox
          loading_data={loading}
          table_actions={[]}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      )}
    </div>
  );
};
