import { Icon } from 'assets/icons';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './item.module.css';

const SideNavItem = ({ title, name, path, onClick, onItemClick }) => {
  const handleItemClick = () => {
    if (onClick) {
      onClick();
    }

    onItemClick();
  };

  return (
    <>
      {!path ? (
        <div className={styles.item} onClick={handleItemClick}>
          <div>
            <span className={styles.icon}>
              <Icon name={name} />
            </span>
            <div className={styles.title}>{title}</div>
          </div>
        </div>
      ) : (
        <NavLink to={path} exact={true} className={styles.item} onClick={handleItemClick}>
          <div>
            <span className={styles.icon}>
              <Icon name={name} />
            </span>
            <div className={styles.title}>{title}</div>
          </div>
        </NavLink>
      )}
    </>
  );
};

export default SideNavItem;
