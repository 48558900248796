import { SlidingRow } from 'app/member/layouts/sliding';
import { PrimaryStatCard } from 'app/shared/cards';
import { convertToPercent } from 'app/shared/utils/general';
import { useMailActionService } from 'hooks/api/mailing/mailaction';
import { useMailLogService } from 'hooks/api/mailing/maillog';
import { useWalletLogService } from 'hooks/api/sales/walletlog';
import React, { useEffect, useState } from 'react';

export const CampaignPageStatBar = ({ id }) => {
  const { fetchMailActions } = useMailActionService();
  const { fetchMailLogs } = useMailLogService();
  const { fetchWalletLogs } = useWalletLogService();

  const [bounces, setBounces] = useState(0);
  const [bounce_rate, setBounceRate] = useState(0);
  const [clicks, setClicks] = useState(0);
  const [click_rate, setClickrate] = useState(0);
  const [cost, setCost] = useState(0);
  const [opens, setOpens] = useState(0);
  const [open_rate, setOpenRate] = useState(0);
  const [sends, setSends] = useState(0);

  useEffect(() => {
    fetchMailLogs({ query_string: `resource_id=${id}&class=campaign&count=1` }).then(
      ({ size: total_sends }) => {
        setSends(() => total_sends);

        fetchMailLogs({
          query_string: `resource_id=${id}&class=campaign&status=bounced&count=1`
        }).then(({ size }) => {
          setBounces(() => size);
          setBounceRate(() => convertToPercent(size, total_sends));
        });

        fetchMailActions({
          query_string: `resource_id=${id}&resource_type=campaign&action=opened&count=1`
        }).then(({ size }) => {
          setOpens(() => size);
          setOpenRate(() => convertToPercent(size, total_sends));
        });

        fetchMailActions({
          query_string: `resource_id=${id}&resource_type=campaign&action=clicked_link&count=1`
        }).then(({ size }) => {
          setClicks(() => size);
          setClickrate(() => convertToPercent(size, total_sends));
        });
      }
    );

    fetchWalletLogs({ query_string: `resource_type=campaign&resource_id=${id}` }).then(
      ({ walletlogs }) => {
        const cost = walletlogs.reduce((total, log) => {
          if (log.kind === 'charge') return (total += Number(log.amount));
          return (total -= Number(log.amount));
        }, 0);
        setCost(() => cost);
      }
    );
  }, [id]);

  return (
    <SlidingRow>
      <PrimaryStatCard
        main_stat={{ label: 'Successful sends', value: sends, color: 'var(--success)' }}
        bottom_stat={{ label: 'Cost', value: cost }}
      />
      <PrimaryStatCard
        main_stat={{ label: 'Bounces', value: bounces, color: 'var(--danger)' }}
        bottom_stat={{ label: 'Bounce rate (%)', value: bounce_rate }}
      />
      <PrimaryStatCard
        main_stat={{ label: 'Unique Opens', value: opens }}
        bottom_stat={{ label: 'Open rate (%)', value: open_rate }}
      />
      <PrimaryStatCard
        main_stat={{ label: 'Unique Clicks', value: clicks, color: 'var(--info)' }}
        bottom_stat={{ label: 'Click rate (%)', value: click_rate }}
      />
    </SlidingRow>
  );
};
