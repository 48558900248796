import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as RoleService from 'services/iam/role';

export const useRoleService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchRole = async (id, options = {}) => {
    const { error, payload } = await RoleService.readById(id, { ...options, token });
    if (error) {
      toast.error('Unable to fetch role at this time.');
      return { role: {} };
    }

    return { role: payload };
  };

  const fetchRoles = async (options = {}) => {
    const { error, payload } = await RoleService.read({ ...options, token });
    if (error) {
      toast.error('Unable to fetch roles at this time.');
      return { roles: [], size: 0 };
    }

    const {
      data: roles,
      meta: { size }
    } = payload;
    return { roles, size };
  };

  const searchroles = async (keys, keyword, options = {}) => {
    const { error, payload } = await RoleService.search(keys, keyword, { ...options, token });
    if (error) {
      toast.error('Unable to fetch roles at this time.');
      return { roles: [], size: 0 };
    }

    const {
      data: roles,
      meta: { size }
    } = payload;
    return { roles, size };
  };

  return { fetchRole, fetchRoles, searchroles };
};
