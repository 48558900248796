import { PageContentWrapper } from 'app/member/layouts/wrapper/page-content';
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ListCampaign from './list/list';
import { CampaignPage } from './views';

const CampaignModule = () => {
  return (
    <PageContentWrapper>
      <Switch>
        <Route path="/campaigns/:id" component={CampaignPage} />
        <Route path="/campaigns" component={ListCampaign} />
      </Switch>
    </PageContentWrapper>
  );
};

export default CampaignModule;
