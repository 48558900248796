import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from 'store/actions/header';
import { GridColumn, GridRow } from 'app/member/layouts/grid';
import { Spacer } from 'app/member/layouts/generic';
import { CreatePlanForm } from './details';
import { InputSection } from './components';
import { useCurrencyService } from 'hooks/api/sales/currency';
import { useParams } from 'react-router-dom';
import { usePlanService } from 'hooks/api/sales/plan';
import { useResourceService } from 'hooks/api/iam/resource';

export const PlanCreation = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { fetchCurrencies } = useCurrencyService();
  const { fetchResources } = useResourceService();

  const { fetchPlan } = usePlanService();
  const [resources, setResources] = useState([{ field: '', value: '' }]);
  const [prices, setPrices] = useState([{ field: '', value: '' }]);
  const [discounts, setDiscounts] = useState([{ field: '', value: '' }]);
  const [currencies, setCurrencies] = useState({});
  const [plan, setPlan] = useState({});
  const [resource_options, setResourceOptions] = useState([]);

  const DISCOUNT_PERIOD = ['Month', 'Annual'].map((field) => ({
    label: field,
    value: field.toLowerCase()
  }));

  useEffect(() => {
    if (isNaN(id)) return;
    fetchPlan(id).then(({ plan }) => {
      if (!plan) return;
      setPlan(plan);
      setResources(
        plan.resources.map((res) => ({
          field: { label: res.name, value: res.name },
          value: res.limit
        }))
      );

      setPrices(
        plan.prices.map((price) => ({
          field: { label: price.currency, value: price.currency },
          value: price.amount
        }))
      );
      setDiscounts(
        Object.entries(plan.discounts).map(([key, value]) => ({
          field: { label: key, value: key },
          value
        }))
      );
    });
  }, [id]);

  useEffect(() => {
    fetchResources().then(({ resources }) => {
      setResourceOptions(() =>
        resources.map((resource) => ({
          ...resource,
          label: resource.name,
          value: resource.code
        }))
      );
    });

    fetchCurrencies().then(({ currencies }) => {
      if (currencies.length) {
        const mapped_currency = currencies.map((curr) => {
          return {
            label: curr.code,
            value: curr.code
          };
        });
        setCurrencies(mapped_currency);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Billing', path: '.' },
        { title: 'Plan', path: '..' }
      ])
    );
  }, []);

  const addItem = (setItems) => () => {
    setItems((items) => [...items, { field: '', value: '' }]);
  };

  const removeItem = (setItems) => (index) => {
    setItems((items) => items.filter((_, i) => i !== index));
  };
  const handleItemChange = (setItems) => (index, key, value) => {
    setItems((items) => items.map((item, i) => (i === index ? { ...item, [key]: value } : item)));
  };

  return (
    <GridRow
      tabletStyles={{ gridTemplateColumns: 'repeat(1,1fr)', gap: 0 }}
      mobileStyles={{ gap: 0 }}
    >
      <GridColumn>
        {<CreatePlanForm plan={plan} data={{ resources, prices, discounts }} />}
      </GridColumn>
      <GridColumn span={2}>
        <Spacer multiple={1} />
        <InputSection
          title="Resources"
          items={resources}
          options={resource_options}
          addItem={addItem(setResources)}
          removeItem={removeItem(setResources)}
          handleItemChange={handleItemChange(setResources)}
        />
        <Spacer multiple={3} />

        <InputSection
          title="Prices"
          items={prices}
          options={currencies}
          addItem={addItem(setPrices)}
          removeItem={removeItem(setPrices)}
          handleItemChange={handleItemChange(setPrices)}
        />
        <Spacer multiple={3} />

        <InputSection
          title="Discounts"
          items={discounts}
          options={DISCOUNT_PERIOD}
          addItem={addItem(setDiscounts)}
          removeItem={removeItem(setDiscounts)}
          handleItemChange={handleItemChange(setDiscounts)}
        />
      </GridColumn>
    </GridRow>
  );
};
