import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as PlanService from 'services/sales/plan';

export const usePlanService = () => {
  const { token } = useSelector((store) => store.user_data);

  const createPlan = async (options = {}) => {
    const { error, payload: plan } = await PlanService.create({
      ...options,
      token
    });

    if (error) {
      toast.error('Cannot create plan at this time');
      return { plan: {} };
    }

    toast.success('Plan created successfully.');
    return { plan };
  };

  const deletePlan = async (id, options = {}) => {
    const { error } = await PlanService.deleteById(id, {
      ...options,
      token
    });

    if (error) {
      toast.error('Cannot delete plan at this time');
      return false;
    }

    toast.success('Plan deleted successfully.');
    return true;
  };

  const fetchPlan = async (id, options = {}) => {
    const { error, payload } = await PlanService.readById(id, { ...options, token });
    if (error) {
      toast.error('Unable to fetch plan at this time.');
      return { plan: {} };
    }

    return { plan: payload };
  };

  const fetchPlans = async (options = {}) => {
    const { error, payload } = await PlanService.read({ ...options, token });
    if (error) {
      toast.error('Unable to fetch plans at this time.');
      return { plans: [], size: 0 };
    }

    const {
      data: plans,
      meta: { size }
    } = payload;
    return { plans, size };
  };

  const searchPlans = async (keys, keyword, options = {}) => {
    const { error, payload } = await PlanService.search(keys, keyword, { ...options, token });
    if (error) {
      toast.error('Unable to fetch plans at this time.');
      return { plans: [], size: 0 };
    }

    const {
      data: plans,
      meta: { size }
    } = payload;
    return { plans, size };
  };

  const updatePlan = async (id, options = {}) => {
    const { error, payload: plan } = await PlanService.updateById(id, {
      ...options,
      token
    });

    if (error) {
      toast.error('Cannot update plan at this time');
      return { plan: {} };
    }

    toast.success('Plan updated successfully.');
    return { plan };
  };

  return {
    createPlan,
    deletePlan,
    fetchPlan,
    fetchPlans,
    searchPlans,
    updatePlan
  };
};
