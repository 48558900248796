import { Spacer } from 'app/member/layouts/generic';
import { GridRow } from 'app/member/layouts/grid';
import { Button } from 'app/shared/button';
import { DetailItem } from 'app/shared/info-section/item';
import { SimpleInput } from 'app/shared/input';
import { useResourceService } from 'hooks/api/iam/resource';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { addOneResourceToStore } from 'store/actions/resource';

export const CreateResource = ({ closeModal = () => {} }) => {
  const dispatch = useDispatch();
  const { createResource } = useResourceService();

  const [form_data, setFormData] = useState({});

  const handleInputChange = (key, value) => {
    setFormData((curr_value) => ({
      ...curr_value,
      [key]: value
    }));
  };

  const submit = async () => {
    if (!form_data.name || !form_data.code) {
      toast.error('Please fill all fields');
      return;
    }

    const { resource } = await createResource({
      data: {
        ...form_data
      }
    });

    if (resource) {
      dispatch(addOneResourceToStore(resource));
      closeModal();
    }
  };

  return (
    <>
      <GridRow num_of_columns={1}>
        <DetailItem title="Name">
          <SimpleInput onInput={(v) => handleInputChange('name', v)} value={form_data.name} />
        </DetailItem>
      </GridRow>
      <GridRow num_of_columns={1}>
        <DetailItem title="Code">
          <SimpleInput onInput={(v) => handleInputChange('code', v)} value={form_data.code} />
        </DetailItem>
      </GridRow>
      <Spacer multiple={4} />
      <GridRow num_of_columns={1}>
        <Button text="Save" onClick={submit} />
      </GridRow>
    </>
  );
};
