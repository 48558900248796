import { Spacer } from 'app/member/layouts/generic';
import { PageContentWrapper } from 'app/member/layouts/wrapper/page-content';
import SlidingNav from 'app/shared/nav/sliding/sliding';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { setPageTitle } from 'store/actions/header';
import { TransactionalListPage } from './sections/list';
import { TransactionalEmailLogs } from './sections/logs';

export const Transactionals = () => {
  const dispatch = useDispatch();
  const nav_items = [
    { path: '/transactionals', title: 'All transactionals' },
    { path: '/transactionals/logs', title: 'Email Logs' }
  ];

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Transactionals', path: '.' }]));
  }, []);

  return (
    <PageContentWrapper>
      <SlidingNav nav_items={nav_items} />
      <Spacer multiple={4} />
      <Switch>
        <Route path="/transactionals/logs" component={TransactionalEmailLogs} />
        <Route path="/transactionals" component={TransactionalListPage} />
      </Switch>
    </PageContentWrapper>
  );
};
