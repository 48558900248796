/** */
export const CREATE_TENANT = 'create tenant';
export const DELETE_TENANT = 'delete tenant';
export const READ_TENANTS = 'read tenants';
export const UPDATE_TENANT = 'update tenant';

/** */
export const ADD_ONE_TENANT_TO_STORE = 'add one tenant to store';
export const ADD_MANY_TENANTS_TO_STORE = 'add many tenants to store';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add tenant search results to store';
export const LOAD_TENANTS_TO_STORE = 'load tenants to store from API call';
export const REMOVE_MANY_TENANTS_FROM_STORE = 'remove many tenants from the store.';
export const REMOVE_ONE_TENANT_FROM_STORE = 'remove a tenant from the store.';

/** */
export const createTenant = (payload) => ({
  type: CREATE_TENANT,
  payload
});

export const deleteTenant = (payload) => ({
  type: DELETE_TENANT,
  payload
});

export const deleteManyTenants = (payload) => ({
  type: REMOVE_MANY_TENANTS_FROM_STORE,
  payload
});

export const readTenants = (payload) => ({
  type: READ_TENANTS,
  payload
});

export const updateTenant = (payload) => ({
  type: UPDATE_TENANT,
  payload
});

/** */

export const addManyTenantsToStore = (payload) => ({
  type: ADD_MANY_TENANTS_TO_STORE,
  payload
});

export const addOneTenantToStore = (payload) => ({
  type: ADD_ONE_TENANT_TO_STORE,
  payload
});

export const addTenantSearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const loadTenantsToStore = (payload) => ({
  type: LOAD_TENANTS_TO_STORE,
  payload
});

export const removeManyTenantsFromStore = (payload) => ({
  type: REMOVE_MANY_TENANTS_FROM_STORE,
  payload
});

export const removeOneTenantFromStore = (payload) => ({
  type: REMOVE_ONE_TENANT_FROM_STORE,
  payload
});
