/** */
export const READ_MANY_PERMISSIONS = 'read multiple permissions.';
export const READ_ONE_PERMISSION = 'read one permission.';

export const ADD_ONE_PERMISSION_TO_STORE = 'add one permission to store.';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add permission search results to store.';
export const ADD_MANY_PERMISSIONS_TO_STORE = 'add multiple permissions to store.';
export const LOAD_PERMISSIONS_TO_STORE = 'load up permissions to store.';
export const REM_ONE_PERMISSION_FROM_STORE = 'remove a permission from the store.';

/** */
export const readManyPermissions = (payload) => ({
  type: READ_MANY_PERMISSIONS,
  payload
});

export const readOnePermission = (payload) => ({
  type: READ_ONE_PERMISSION,
  payload
});

export const addManyPermissionsToStore = (payload) => ({
  type: ADD_MANY_PERMISSIONS_TO_STORE,
  payload
});

export const addOnePermissionToStore = (payload) => ({
  type: ADD_ONE_PERMISSION_TO_STORE,
  payload
});

export const addPermissionSearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const loadPermissionsToStore = (payload) => ({
  type: LOAD_PERMISSIONS_TO_STORE,
  payload
});

export const removeOnePermissionFromStore = (payload) => ({
  type: REM_ONE_PERMISSION_FROM_STORE,
  payload
});
