/** */
import cx from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addDataToStore } from 'store/actions/user-data';

import ContextMenu from 'app/shared/datatable/context-menu/context-menu';
import Breadcrumbs from 'app/shared/breadcrumbs/breadcrumbs';

import { Icon } from 'assets/icons';
import styles from './header.module.css';

const MemberAreaHeader = ({ onHamburgerClick }) => {
  const dispatch = useDispatch();

  const { page_title } = useSelector((state) => state.header);
  const { firstname, lastname } = useSelector((state) => state.user_data);

  const icon_user_actions = [
    { label: <span key={`${firstname} ${lastname}`}>{`${firstname} ${lastname}`} </span> },
    { label: 'Log Out', value: 'logout' }
  ];

  const handleLogout = () => {
    dispatch(addDataToStore({}));
  };

  const handleUserAction = (data) => {
    if (typeof data !== 'string') return;

    switch (data.toLowerCase()) {
      case 'logout':
        handleLogout();
        break;
      default:
    }
  };

  return (
    <header className={cx(styles.wrapper)}>
      <div>
        <div>
          <span className={styles.menuIcon} onClick={onHamburgerClick}>
            <Icon name="menu" />
          </span>

          {page_title?.length && (
            <span className={styles.title}>
              <Breadcrumbs crumbs={page_title} />
            </span>
          )}
        </div>

        <div className={styles.actions}>
          <div className={styles.profileIcon}>
            <ContextMenu
              actions={icon_user_actions}
              callback={handleUserAction}
              text={<Icon name="profile" />}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default MemberAreaHeader;
