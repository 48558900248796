import { TOGGLE_AUTOMATION_VISIBILITY } from 'store/actions/settings';

export default (state = false, action) => {
  const { type, payload } = action;
  switch (type) {
    case TOGGLE_AUTOMATION_VISIBILITY:
      return { ...state, ...payload };
    default:
      return state;
  }
};
