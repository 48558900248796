import { PageContentWrapper } from 'app/member/layouts/wrapper/page-content';
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ListPayment from './list/list';

const PaymentModule = () => {
  return (
    <PageContentWrapper>
      <Switch>
        <Route path="/payments" component={ListPayment} />
      </Switch>
    </PageContentWrapper>
  );
};

export default PaymentModule;
