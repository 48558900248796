import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { RolesListPage } from './list';

export const RolesModule = () => {
  return (
    <Switch>
      <Route path="/user-management/roles" component={RolesListPage} />
    </Switch>
  );
};
