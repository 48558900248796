import { processDateRange, TimeSeriesChart } from 'app/shared/charts/timeseries';
import { useContactService } from 'hooks/api/users/contact';
import React, { useEffect, useState } from 'react';
import { generateDefaultRange } from '../utils/date';

const chart_key_filters = [
  {
    label: 'Number of Contacts',
    value: 'size',
    default: true,
    color: 'var(--blue-primary)'
  }
];

export const ContactPerformance = ({ tenant_id }) => {
  const { fetchContacts } = useContactService();

  const [date_range, setDateRange] = useState([]);
  const [performance_data, setPerformanceData] = useState({});
  const [is_loading_performance_data, setIsLoadingPerformanceData] = useState(true);

  useEffect(() => {
    const [thirty_days_ago, today] = generateDefaultRange();
    const ranges = processDateRange(thirty_days_ago, today);
    setDateRange(() => ranges);
  }, []);

  useEffect(() => {
    handlePerformanceDateRangeChange(date_range);
  }, [date_range]);

  const handlePerformanceDateRangeChange = async (ranges = []) => {
    const day_map = ranges.reduce(
      (sac, { label }) => ({
        ...sac,
        [label]: { day: label }
      }),
      {}
    );

    setIsLoadingPerformanceData(true);

    const id_query = tenant_id ? `&tenant_id=${tenant_id}` : '';
    for (const range of ranges) {
      const { start, end, label } = range;
      const { size } = await fetchContacts({
        query_string: `time_stamp=${start}~${end}&count=1${id_query}`
      });

      day_map[label] = { ...day_map[label], size };
    }

    setPerformanceData(() => day_map);
    setIsLoadingPerformanceData(false);
  };

  return (
    <TimeSeriesChart
      graph_title="Contact Performance"
      data={Object.values(performance_data)}
      filter_data={[]}
      key_filters={chart_key_filters}
      is_loading_data={is_loading_performance_data}
      onDateRangeChange={setDateRange}
    />
  );
};
