/** */
export const READ_MANY_USERS = 'read multiple users.';
export const READ_ONE_USER = 'read one user.';

export const ADD_ONE_USER_TO_STORE = 'add one user to store.';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add user search results to store.';
export const ADD_MANY_USERS_TO_STORE = 'add multiple users to store.';
export const LOAD_USERS_TO_STORE = 'load up users to store.';
export const REM_ONE_USER_FROM_STORE = 'remove a user from the store.';

/** */
export const readManyUsers = (payload) => ({
  type: READ_MANY_USERS,
  payload
});

export const readOneUser = (payload) => ({
  type: READ_ONE_USER,
  payload
});

export const addManyUsersToStore = (payload) => ({
  type: ADD_MANY_USERS_TO_STORE,
  payload
});

export const addOneUserToStore = (payload) => ({
  type: ADD_ONE_USER_TO_STORE,
  payload
});

export const addUserSearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const loadUsersToStore = (payload) => ({
  type: LOAD_USERS_TO_STORE,
  payload
});

export const removeOneUserFromStore = (payload) => ({
  type: REM_ONE_USER_FROM_STORE,
  payload
});
