import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { addOneCurrencyToStore } from 'store/actions/currency';
import { Button } from 'app/shared/button';
import { MultiSelect } from 'app/shared/select';
import { useCurrencyService } from 'hooks/api/sales/currency';

const AddCurrency = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { createCurrency, fetchCurrencyCountries } = useCurrencyService();

  const [countries, setCountries] = useState([]);
  const [selected_country, setSelectedCountry] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCurrencyCountries().then(({ countries }) => {
      const checked_countries = {};
      const valid_countries = [];

      countries.forEach((country) => {
        const formatted_country = {
          label: `${country.currencies ? country.currencies[0].name : ''} (${
            country.currencies ? country.currencies[0].code : ''
          })`,
          value: `${country.currencies ? country.currencies[0].name : ''}`,
          img_url: country.flags?.png || country.flags?.svg,
          country_code: country.alpha2Code,
          currency_code: country.currencies ? country.currencies[0].code : ''
        };

        if (!formatted_country.country_code) return;
        if (checked_countries[formatted_country.currency_code]) return;
        checked_countries[formatted_country.country_code] = 1;

        valid_countries.push(formatted_country);
      });

      setCountries(() => valid_countries);
    });
  }, []);

  const clearForm = () => {
    setSelectedCountry('');
  };

  const submit = async () => {
    const data = {
      code: selected_country.currency_code,
      country_code: selected_country.country_code,
      name: selected_country.value
    };

    if (!selected_country) {
      toast.error('please select acountry');
      return;
    }

    setLoading(true);
    const payload = await createCurrency(data);

    setLoading(false);
    if (!payload) return;

    toast.success('Currency added.');
    dispatch(addOneCurrencyToStore(payload));
    clearForm();
    closeModal();
  };

  return (
    <div>
      <div>
        <label>Country:</label>
        <MultiSelect options={countries} onChange={setSelectedCountry} value={selected_country} />
      </div>
      <Button
        icon_name="add"
        text="Save"
        onClick={submit}
        className="float-right w-25 mt-4 mb-4"
        loading={loading}
      />
      <Button
        text="Cancel"
        type="secondary"
        onClick={() => {
          closeModal();
        }}
        className="float-right w-25 mt-4 mb-4 mr-4"
        loading={loading}
      />
    </div>
  );
};

export default AddCurrency;
