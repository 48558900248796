import { GridColumn, GridRow } from 'app/member/layouts/grid';
import { Button } from 'app/shared/button';
import { DetailItem } from 'app/shared/info-section/item';
import { MultiSelect } from 'app/shared/select';
import { useCurrencyService } from 'hooks/api/sales/currency';
import { usePlanService } from 'hooks/api/sales/plan';
import { useSubscriptionService } from 'hooks/api/sales/subscriptions';
import React, { useEffect, useState } from 'react';

const RENEWAL_PERIODS = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Annually', value: 'annual' }
];

export const SubscriptionAssignment = ({ tenant_id, closeModal }) => {
  const { fetchCurrencies } = useCurrencyService();
  const { fetchPlans } = usePlanService();
  const { createSubscription } = useSubscriptionService();

  const [currencies, setCurrencies] = useState([]);
  const [plans, setPlans] = useState([]);

  const [selected_currency, setSelectedCurrency] = useState({});
  const [selected_period, setSelectedPeriod] = useState({});
  const [selected_plan, setSelectedPlan] = useState({});

  const [is_saving, setIsSaving] = useState(false);

  useEffect(() => {
    Promise.all([fetchCurrencies(), fetchPlans()]).then(([{ currencies }, { plans }]) => {
      setCurrencies(currencies.map((c) => ({ ...c, label: c.name, value: c.code })));
      setPlans(plans.map((p) => ({ ...p, label: p.title, value: p.code })));
    });
  }, []);

  const submit = async () => {
    const data = {
      currency: selected_currency,
      plan: selected_plan,
      renewal_period: selected_period.value,
      tenant_id
    };

    setIsSaving(true);
    try {
      const { subscription } = await createSubscription({ data });
      if (subscription) closeModal();
    } finally {
      setIsSaving(false);
    }
  };

  const button_is_disabled =
    is_saving || !selected_currency.value || !selected_period.value || !selected_plan.value;

  return (
    <>
      <GridRow num_of_columns={3}>
        <GridColumn>
          <DetailItem title="Currency">
            <MultiSelect
              options={currencies}
              value={selected_currency}
              onChange={setSelectedCurrency}
            />
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="Plan">
            <MultiSelect options={plans} value={selected_plan} onChange={setSelectedPlan} />
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="Renewal period">
            <MultiSelect
              options={RENEWAL_PERIODS}
              value={selected_period}
              onChange={setSelectedPeriod}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={3}>
        <GridColumn span={2} />
        <GridColumn>
          <Button text="Assign Subscription" onClick={submit} disabled={button_is_disabled} />
        </GridColumn>
      </GridRow>
    </>
  );
};
