export const createValidationConfig = {
  name: {
    required: true,
    pattern: /^[a-zA-Z\s]+$/,
    customErrorMessage: {
      pattern: 'Invalid name format. Only letters and spaces are allowed.'
    }
  },
  code: {
    required: true,
    pattern: /^[a-zA-Z_]*$/,
    customValidation: (value) => {
      if (value.startsWith('_')) {
        return 'Code must not start with an underscore.';
      }
    },
    customErrorMessage: {
      pattern: 'Only combination of alphabet, and underscore is allowed'
    }
  },
  resource_class: {
    required: true
  }
};
