import { processDateRange, TimeSeriesChart } from 'app/shared/charts/timeseries';
import { useWalletLogService } from 'hooks/api/sales/walletlog';
import React, { useEffect, useState } from 'react';
import { generateDefaultRange } from '../utils/date';

const chart_key_filters = [
  {
    label: 'Campaign',
    value: 'campaign',
    default: true,
    color: 'var(--blue-primary)'
  },
  {
    label: 'Transactional',
    value: 'transactional',
    default: true,
    color: 'var(--orange-primary)'
  }
];

export const SpendingPerformance = ({ tenant_id }) => {
  const { fetchWalletLogs } = useWalletLogService();

  const [date_range, setDateRange] = useState([]);
  const [performance_data, setPerformanceData] = useState({});
  const [is_loading_performance_data, setIsLoadingPerformanceData] = useState(true);

  useEffect(() => {
    const [thirty_days_ago, today] = generateDefaultRange();
    const ranges = processDateRange(thirty_days_ago, today);
    setDateRange(() => ranges);
  }, []);

  useEffect(() => {
    handleDateRangeChange(date_range);
  }, [date_range]);

  const handleDateRangeChange = async (ranges = []) => {
    const day_map = ranges.reduce(
      (sac, { label }) => ({
        ...sac,
        [label]: { day: label }
      }),
      {}
    );

    setIsLoadingPerformanceData(true);

    const id_query = tenant_id ? `&tenant_id=${tenant_id}` : '';
    for (const range of ranges) {
      const { start, end, label } = range;
      const [{ walletlogs: campaign_logs }, { walletlogs: transactional_logs }] = await Promise.all(
        [
          fetchWalletLogs({
            query_string: `time_stamp=${start}~${end}&resource_type=campaign&sum=amount${id_query}`
          }),
          fetchWalletLogs({
            query_string: `time_stamp=${start}~${end}&resource_type=transactional&sum=amount${id_query}`
          })
        ]
      );

      const campaign = campaign_logs.reduce((sum, log) => sum + log.amount, 0);
      const transactional = transactional_logs.reduce((sum, log) => sum + log.amount, 0);
      day_map[label] = { ...day_map[label], campaign, transactional };
    }

    setPerformanceData(() => day_map);
    setIsLoadingPerformanceData(false);
  };

  return (
    <TimeSeriesChart
      graph_title="Spending Performance"
      data={Object.values(performance_data)}
      filter_data={[]}
      key_filters={chart_key_filters}
      is_loading_data={is_loading_performance_data}
      onDateRangeChange={setDateRange}
    />
  );
};
