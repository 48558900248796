import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as PaymentService from 'services/sales/payment';

export const usePaymentService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchPayments = async (options = {}) => {
    const { error, payload } = await PaymentService.read({ ...options, token });
    if (error) {
      toast.error('Unable to fetch payments at this time.');
      return { payments: [], size: 0 };
    }

    const {
      data: payments,
      meta: { size }
    } = payload;
    return { payments, size };
  };

  const searchPayments = async (keys, keyword, options = {}) => {
    const { error, payload } = await PaymentService.search(keys, keyword, { ...options, token });
    if (error) {
      toast.error('Unable to fetch payments at this time.');
      return { payments: [], size: 0 };
    }

    const {
      data: payments,
      meta: { size }
    } = payload;
    return { payments, size };
  };

  const verifyPayment = async (options = {}) => {
    const { error } = await PaymentService.verify({ ...options, token });
    if (error) {
      toast.error('Verification failed.');
      return false;
    }

    toast.success('Verification succesful.');
    return true;
  };

  return { fetchPayments, searchPayments, verifyPayment };
};
