import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';

import {
  addManyCurrencyToStore,
  removeOneCurrencyFromStore,
  addCurrencySearchResultsToStore
} from 'store/actions/currency';
import { setPageTitle } from 'store/actions/header';
import { Button } from 'app/shared/button';
import GmModal from 'app/shared/modal/modal';
import AddCurrency from '../create/create';
import UpdateCurrency from '../update/update';
import styles from '../currency.module.css';
import { useCurrencyService } from 'hooks/api/sales/currency';

const CurrencyList = () => {
  const dispatch = useDispatch();
  const { deleteCurrency, fetchCurrencies, searchCurrencies } = useCurrencyService();
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const currency_in_store = useSelector((state) => state.currency);

  const [currencies, setCurrencies] = useState([]);
  const [total_currencies, setTotalCurrencies] = useState(0);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show_currency_creation_modal, setShowCurrencyCreationModal] = useState(false);
  const [show_currency_update_modal, setShowCurrencyUpdateModal] = useState(false);
  const currency_data = useRef(null);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Currency', path: '.' }]));
    setLoading(true);
    handleDataRequest(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrencies(Object.values(currency_in_store));
  }, [currency_in_store]);

  const config = {
    actions: {
      single: () => [
        {
          label: 'Edit',
          value: 'edit'
        },
        {
          label: 'Delete',
          value: 'delete'
        }
      ],
      bulk: []
    },
    allow_bulk_action: true,
    css: {},
    fields: [
      {
        title: 'Code',
        key: 'code',
        searchable: true,
        isTitle: true
      },
      {
        title: 'Name',
        searchable: true,
        key: 'name'
      },
      {
        title: 'Country',
        key: 'country_code',
        isTagline: true
      },
      {
        title: 'Exchange rate',
        key: 'exchange_rate',
        isTagline: true
      }
    ],
    is_search_mode,
    items: currencies,
    search_key: 'code,name',
    search_text: ''
  };

  const handleDatatableAction = (payload) => {
    const { name, type, data } = payload;

    if (type === 'single') {
      if (name === 'edit') {
        currency_data.current = data;
        setShowCurrencyUpdateModal(true);
      }
      if (name === 'delete') {
        return handleSingleDeletion(data.id);
      }
    }
  };

  const handleSingleDeletion = async (currency_id) => {
    const result = await deleteCurrency(currency_id);
    if (result) dispatch(removeOneCurrencyFromStore(currency_id));
  };

  const handleDataRequest = async (page = 1, population = 50) => {
    try {
      setLoading(true);
      const { currencies, size } = await fetchCurrencies({
        query_string: `page=${page}&population=${population}&sort_by=-created_on`
      });

      dispatch(addManyCurrencyToStore(currencies));
      setTotalCurrencies(() => size);
    } catch (e) {
      dispatch(addManyCurrencyToStore([]));
    }
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys) return;
    try {
      setLoading(true);
      const { currencies, size } = await searchCurrencies(keys, keyword, {
        query_string: `sort_by=-created_on&page=${page}&population=${population}`
      });

      if (page === 0) return dispatch(addCurrencySearchResultsToStore(currencies));
      dispatch(addManyCurrencyToStore(currencies));
      setTotalCurrencies(() => size);
    } catch (e) {
      dispatch(addManyCurrencyToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const table_actions = (
    <>
      <Button icon_name="add" text="Currency" onClick={() => setShowCurrencyCreationModal(true)} />
    </>
  );

  return (
    <div>
      {is_mobile_view ? (
        <MobileDatatable
          config={config}
          action={handleDatatableAction}
          onClick={() => {}}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      ) : (
        <WebDatatable
          config={{
            ...config,
            total_count: total_currencies
          }}
          action={handleDatatableAction}
          onClick={() => {}}
          checkbox
          request_complete={!loading}
          onDataRequest={handleDataRequest}
          loadingSearch={() => {}}
          table_actions={table_actions}
          onSearchRequest={handleSearchRequest}
        />
      )}

      <GmModal
        bodyClassName={styles.currencyModal}
        title="Add Currency"
        show_title={true}
        show_modal={show_currency_creation_modal}
        onClose={() => setShowCurrencyCreationModal(false)}
      >
        <AddCurrency />
      </GmModal>

      <GmModal
        bodyClassName={styles.currencyModal}
        title="Update Currency"
        show_title={true}
        show_modal={show_currency_update_modal}
        onClose={() => setShowCurrencyUpdateModal(false)}
      >
        <UpdateCurrency currency={currency_data.current} />
      </GmModal>
    </div>
  );
};

export default CurrencyList;
