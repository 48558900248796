import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as SubscriptionService from 'services/sales/subscription';

export const useSubscriptionService = () => {
  const { token } = useSelector((store) => store.user_data);

  const createSubscription = async (options = {}) => {
    const { error, payload: subscription } = await SubscriptionService.create({
      ...options,
      token
    });
    if (error) {
      toast.error('Unable to create subscriptions at this time.');
    } else toast.success('Subscription created successfully');

    return { subscription };
  };

  const fetchSubscriptions = async (options = {}) => {
    const { error, payload } = await SubscriptionService.read({ ...options, token });
    if (error) {
      toast.error('Unable to fetch subscriptions at this time.');
      return { subscriptions: [], size: 0 };
    }

    const {
      data: subscriptions,
      meta: { size }
    } = payload;
    return { subscriptions, size };
  };

  const renewSubscription = async (id, options = {}) => {
    const { error, payload } = await SubscriptionService.renew(id, { ...options, token });
    if (error) {
      toast.error('Unable to renew subscription at this time.');
      return { invoice: {} };
    }

    toast.success('Subscription renewed successfully');
    const { data: invoice } = payload;
    return { invoice };
  };

  const searchSubscriptions = async (keys, keyword, options = {}) => {
    const { error, payload } = await SubscriptionService.search(keys, keyword, {
      ...options,
      token
    });
    if (error) {
      toast.error('Unable to fetch subscriptions at this time.');
      return { subscriptions: [], size: 0 };
    }

    const {
      data: subscriptions,
      meta: { size }
    } = payload;
    return { subscriptions, size };
  };

  const updateSubscription = async (id, options = {}) => {
    const { error, payload } = await SubscriptionService.updateById(id, { ...options, token });
    if (error) {
      toast.error('Unable to update subscription at this time.');
      return { subscription: null };
    }

    toast.success('Subscription updated successfully');
    return { subscription: payload };
  };

  return {
    createSubscription,
    fetchSubscriptions,
    renewSubscription,
    searchSubscriptions,
    updateSubscription
  };
};
