import React from 'react';
import { Link } from 'react-router-dom';

import './breadcrumbs.css';

const BreadcrumbSeparator = ({ children }) => (
  <span className="crumb-separator" style={{ userSelect: 'none' }}>
    {children}
  </span>
);

const Breadcrumbs = ({ crumbs }) => {
  const lastCrumbIndex = crumbs.length - 1;
  const breadcrumbs = Array.isArray(crumbs) ? crumbs : [];

  const crumbs_with_separators = breadcrumbs.reduce((acc, crumb, index) => {
    const notLast = index < lastCrumbIndex;
    if (notLast) {
      acc.push(crumb, {
        title: <BreadcrumbSeparator>/</BreadcrumbSeparator>,
        path: ''
      });
    } else {
      acc.push(crumb);
    }
    return acc;
  }, []);

  return (
    <>
      {crumbs_with_separators.map(({ title, path }, index) => {
        if (index + 1 === breadcrumbs.length) {
          return <span key={index}>{title}</span>;
        } else {
          return (
            <Link key={index} to={path} className="crumb-link">
              {title}
            </Link>
          );
        }
      })}
    </>
  );
};

export default Breadcrumbs;
