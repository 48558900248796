/** */
export const READ_MANY_WALLETLOGS = 'read multiple walletlogs.';
export const READ_ONE_WALLETLOG = 'read one walletlog.';

export const ADD_ONE_WALLETLOG_TO_STORE = 'add one walletlog to store.';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add walletlog search results to store.';
export const ADD_MANY_WALLETLOGS_TO_STORE = 'add multiple walletlogs to store.';
export const LOAD_WALLETLOGS_TO_STORE = 'load up walletlogs to store.';
export const REM_ONE_WALLETLOG_FROM_STORE = 'remove a walletlog from the store.';

/** */
export const readManyWalletLogs = (payload) => ({
  type: READ_MANY_WALLETLOGS,
  payload
});

export const readOneWalletLog = (payload) => ({
  type: READ_ONE_WALLETLOG,
  payload
});

export const addManyWalletLogsToStore = (payload) => ({
  type: ADD_MANY_WALLETLOGS_TO_STORE,
  payload
});

export const addOneWalletLogToStore = (payload) => ({
  type: ADD_ONE_WALLETLOG_TO_STORE,
  payload
});

export const addWalletLogSearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const loadWalletLogsToStore = (payload) => ({
  type: LOAD_WALLETLOGS_TO_STORE,
  payload
});

export const removeOneWalletLogFromStore = (payload) => ({
  type: REM_ONE_WALLETLOG_FROM_STORE,
  payload
});
