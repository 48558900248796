import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as AudienceService from 'services/users/audience';

export const useAudienceService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchAudiences = async (options = {}) => {
    const { error, payload } = await AudienceService.read({ ...options, token });
    if (error) {
      toast.error('Unable to fetch audiences at this time.');
      return { audiences: [], size: 0 };
    }

    const {
      data: audiences,
      meta: { size }
    } = payload;
    return { audiences, size };
  };

  const searchAudiences = async (keys, keyword, options = {}) => {
    const { error, payload } = await AudienceService.search(keys, keyword, { ...options, token });
    if (error) {
      toast.error('Unable to fetch audiences at this time.');
      return { audiences: [], size: 0 };
    }

    const {
      data: audiences,
      meta: { size }
    } = payload;
    return { audiences, size };
  };

  return { fetchAudiences, searchAudiences };
};
