/** */
import {
  ADD_MANY_PERMISSIONS_TO_STORE,
  ADD_ONE_PERMISSION_TO_STORE,
  ADD_SEARCH_RESULTS_TO_STORE,
  LOAD_PERMISSIONS_TO_STORE,
  REM_ONE_PERMISSION_FROM_STORE
} from '../actions/permission';

export default (state = {}, action) => {
  const { type, payload } = action;
  let previous_state;
  switch (type) {
    case ADD_MANY_PERMISSIONS_TO_STORE:
      return {
        ...state,
        ...payload.reduce(
          (permissions, permission) => ({
            ...permissions,
            [permission.id]: permission
          }),
          {}
        )
      };
    case ADD_ONE_PERMISSION_TO_STORE:
      return {
        ...state,
        [payload.id]: payload
      };
    case ADD_SEARCH_RESULTS_TO_STORE:
      return {
        ...payload.reduce(
          (permissions, permission) => ({
            ...permissions,
            [permission.id]: permission
          }),
          {}
        )
      };
    case LOAD_PERMISSIONS_TO_STORE:
      return {
        ...payload.reduce(
          (permissions, permission) => ({
            ...permissions,
            [permission.id]: permission
          }),
          {}
        )
      };
    case REM_ONE_PERMISSION_FROM_STORE:
      previous_state = { ...state };
      delete previous_state[payload];
      return {
        ...previous_state
      };
    default:
      return state;
  }
};
