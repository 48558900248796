/** */
import { urls } from 'services/_url';
import { apiGet, apiPut } from 'services/_http';

/** */
const base_url = `${urls.sales}/wallets`;

export const read = async (options = {}) => {
  return await apiGet(`${base_url}`, { ...options });
};

export const update = async (options = {}) => {
  return await apiPut(`${base_url}`, { ...options });
};
