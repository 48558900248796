import './event.css';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';

import { setPageTitle } from 'store/actions/header';
import { addManyEventToStore, removeOneEventFromStore } from 'store/actions/event';
import ConfirmationDialog from 'app/shared/dialogs/confirmation';
import CreateEventForm from './form';
import { useAutomationEventService } from 'hooks/api/automata/event';
import { GridColumn, GridRow } from 'app/member/layouts/grid';

const EventAutomation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { deleteEvent, fetchEvents } = useAutomationEventService();

  const { is_mobile_view } = useSelector((state) => state.metadata);
  const event_in_store = useSelector((state) => state.events);

  const [events, setEvents] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show_event_delete_dialog, setShowEventDeleteModal] = useState(false);
  const [event_to_delete, setEventToDelete] = useState({});

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Event', path: '.' }]));
    handleDataRequest(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setEvents(Object.values(event_in_store));
  }, [event_in_store]);

  const config = {
    actions: {
      single: () => [
        {
          label: 'Edit',
          value: 'edit'
        },
        {
          label: 'Delete',
          value: 'delete'
        }
      ]
    },
    allow_bulk_action: true,
    css: {},
    fields: [
      {
        title: 'Name',
        key: 'name',
        searchable: true,
        isTitle: true
      },
      {
        title: 'Code',
        searchable: true,
        key: 'code'
      },
      {
        title: 'Class',
        key: 'resource_class',
        isTagline: true
      }
    ],
    is_search_mode,
    items: events,
    search_key: 'code,name',
    search_text: ''
  };

  const handleDatatableAction = (payload) => {
    const { name, type, data } = payload;
    if (type === 'single') {
      if (name === 'edit') {
        history.push(`/settings/event/${data.id}`);
        return;
      }
      if (name === 'delete') {
        setEventToDelete(data);
        setShowEventDeleteModal(true);
      }
    }
  };
  const handleDataRequest = async (page = 1, population = 50) => {
    try {
      setLoading(true);
      const { events } = await fetchEvents({
        query_string: `type=system&page=${page}&population=${population}&sort_by=-created_on`
      });
      setLoading(false);
      dispatch(addManyEventToStore(events));
    } catch (e) {
      dispatch(addManyEventToStore([]));
    }
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {};

  const handleSingleDeletion = async (permitted) => {
    if (permitted) {
      if (event_to_delete.in_use) {
        return toast.error('You cannot delete an event in use');
      }

      const result = await deleteEvent(event_to_delete.id);
      if (result) dispatch(removeOneEventFromStore(event_to_delete.id));
    }
    setShowEventDeleteModal(false);
  };

  return (
    <>
      <GridRow>
        <GridColumn span={3}>
          {is_mobile_view ? (
            <MobileDatatable
              config={config}
              action={handleDatatableAction}
              onClick={() => {}}
              onListModeChange={setSearchMode}
              onDataRequest={handleDataRequest}
              onSearchRequest={handleSearchRequest}
              showHeader={false}
            />
          ) : (
            <WebDatatable
              config={{
                ...config,
                total_count: config.items?.length
              }}
              action={handleDatatableAction}
              onClick={() => {}}
              checkbox
              request_complete={!loading}
              onDataRequest={handleDataRequest}
              loadingSearch={() => {}}
              onSearchRequest={handleSearchRequest}
              showHeader={false}
            />
          )}

          <ConfirmationDialog
            title="Delete Event"
            message="Are you sure you want to delete this event?"
            callback={handleSingleDeletion}
            is_open={show_event_delete_dialog}
          />
        </GridColumn>
        <GridColumn>
          <CreateEventForm />
        </GridColumn>
      </GridRow>
    </>
  );
};

export default EventAutomation;
