/** */
import { urls } from 'services/_url';
import { apiGet } from 'services/_http';

/** */
const base_url = `${urls.sales}/walletlogs`;

export const read = async (options = {}) => {
  return await apiGet(`${base_url}`, { ...options });
};

export const search = async (keys, keyword, options = {}) => {
  const query_string = options.query_string
    ? `${options.query_string}&keyword=${keyword}`
    : `keyword=${keyword}`;
  return await apiGet(`${base_url}/search/${keys}`, {
    ...options,
    query_string
  });
};
