import { SlidingRow } from 'app/member/layouts/sliding';
import { PrimaryStatCard } from 'app/shared/cards';
import { useSMSLogService } from 'hooks/api/messaging/logs/sms';
import React, { useEffect, useState } from 'react';

export const SMSStatBar = ({ id }) => {
  const { fetchSMSLogs } = useSMSLogService();

  const [logs, setLogs] = useState({});

  useEffect(() => {
    Promise.all([
      fetchSMSLogs({ query_string: `status=sent&count=1` }),
      fetchSMSLogs({ query_string: `status=failed&count=1` })
    ]).then(([{ size: sent }, { size: undelivered }]) => {
      setLogs((curr_logs) => ({
        ...curr_logs,
        sent,
        undelivered
      }));
    });
  }, [id]);

  return (
    <SlidingRow>
      <PrimaryStatCard
        main_stat={{ label: 'Successful sends', value: logs.sent, color: 'var(--success)' }}
        bottom_stat={{ label: '-', value: '-' }}
      />
      <PrimaryStatCard
        main_stat={{ label: 'Undelivered', value: logs.undelivered, color: 'var(--danger)' }}
        bottom_stat={{ label: '-', value: '-' }}
      />
    </SlidingRow>
  );
};
