import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import { useHistory } from 'react-router-dom';

import { setPageTitle } from 'store/actions/header';
import { addManyEffectToStore, removeOneEffectFromStore } from 'store/actions/effect';

import './effect.css';
import ConfirmationDialog from 'app/shared/dialogs/confirmation';
import CreateEffectForm from './form';
import { useAutomationEffectService } from 'hooks/api/automata/effect';
import { GridColumn, GridRow } from 'app/member/layouts/grid';

const EffectAutomation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const effect_in_store = useSelector((state) => state.effects);
  const { fetchEffects, deleteEffect } = useAutomationEffectService();

  const [effects, setEffects] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show_effect_delete_modal, setShowEffectDeleteModal] = useState(false);
  const [effect_to_delete, setEffectToDelete] = useState({});

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Effect', path: '.' }]));
    handleDataRequest(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setEffects(Object.values(effect_in_store));
  }, [effect_in_store]);

  const config = {
    actions: {
      single: () => [
        {
          label: 'Edit',
          value: 'edit'
        },
        {
          label: 'Delete',
          value: 'delete'
        }
      ]
    },
    allow_bulk_action: true,
    css: {},
    fields: [
      {
        title: 'Name',
        key: 'name',
        searchable: true,
        isTitle: true
      },
      {
        title: 'Code',
        searchable: true,
        key: 'code'
      },
      {
        title: 'Class',
        key: 'resource_class',
        isTagline: true
      }
    ],
    is_search_mode,
    items: effects,
    search_key: 'code,name',
    search_text: ''
  };

  const handleDatatableAction = (payload) => {
    const { name, type, data } = payload;
    if (type === 'single') {
      if (name === 'edit') {
        history.push(`/settings/effect/${data.id}`);
        return;
      }
      if (name === 'delete') {
        setEffectToDelete(data);

        setShowEffectDeleteModal(true);
      }
    }
  };

  const handleDataRequest = async (page = 1, population = 50) => {
    try {
      setLoading(true);
      const { effects } = await fetchEffects({
        query_string: `page=${page}&population=${population}&sort_by=-created_on`
      });

      dispatch(addManyEffectToStore(effects));
    } catch (e) {
      dispatch(addManyEffectToStore([]));
    }
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {};

  const handleSingleDeletion = async (permitted) => {
    if (permitted) {
      if (effect_to_delete.in_use) {
        return toast.error('You cannot delete an effect in use');
      }

      const result = await deleteEffect(effect_to_delete.id);
      if (result) dispatch(removeOneEffectFromStore(effect_to_delete.id));
    }
    setShowEffectDeleteModal(false);
  };

  return (
    <>
      <GridRow>
        <GridColumn span={3}>
          {is_mobile_view ? (
            <MobileDatatable
              config={config}
              action={handleDatatableAction}
              onClick={() => {}}
              onListModeChange={setSearchMode}
              onDataRequest={handleDataRequest}
              onSearchRequest={handleSearchRequest}
              showHeader={false}
            />
          ) : (
            <WebDatatable
              config={{
                ...config,
                total_count: config.items?.length
              }}
              action={handleDatatableAction}
              onClick={() => {}}
              checkbox
              request_complete={!loading}
              onDataRequest={handleDataRequest}
              loadingSearch={() => {}}
              onSearchRequest={handleSearchRequest}
              showHeader={false}
            />
          )}

          <ConfirmationDialog
            title="Delete Effect"
            message="Are you sure you want to delete this effect?"
            callback={handleSingleDeletion}
            is_open={show_effect_delete_modal}
          />
        </GridColumn>
        <GridColumn>
          <CreateEffectForm />
        </GridColumn>
      </GridRow>
    </>
  );
};

export default EffectAutomation;
