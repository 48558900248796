import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as TemplateService from 'services/automata/template';

export const useAutomationTemplateService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchTemplates = async (options = {}) => {
    const { error, payload } = await TemplateService.read({ ...options, token });

    if (error) {
      toast.error(error);
      return { templates: [], size: 0 };
    }

    const {
      data: templates,
      meta: { size }
    } = payload;
    return { templates, size };
  };

  return { fetchTemplates };
};
