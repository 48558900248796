import { urls } from './_url';
import { apiDelete } from './_http';

export const destroyMailingData = async (id, options = {}) => {
  return await apiDelete(`${urls.mailing}/purge/${id}`, { ...options });
};

export const destroyAutomationData = async (id, options = {}) => {
  return await apiDelete(`${urls.automata}/purge/${id}`, { ...options });
};

export const destroyNotificationData = async (id, options = {}) => {
  return await apiDelete(`${urls.notification}/purge/${id}`, { ...options });
};
