import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as TransactionalService from 'services/mailing/transactional';

export const useTransactionalService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchTransactionalById = async (id, options = {}) => {
    const { error, payload } = await TransactionalService.readById(id, {
      ...options,
      token
    });
    if (error) {
      toast.error('Unable to fetch transactional at this time.');
      return { transactional: {} };
    }

    return { transactional: payload };
  };

  const fetchTransactionals = async (options = {}) => {
    const { error, payload } = await TransactionalService.read({ ...options, token });
    if (error) {
      toast.error('Unable to fetch transactionals at this time.');
      return { transactionals: [], size: 0 };
    }

    const {
      data: transactionals,
      meta: { size }
    } = payload;
    return { transactionals, size };
  };

  const searchTransactionals = async (keys, keyword, options = {}) => {
    const { error, payload } = await TransactionalService.search(keys, keyword, {
      ...options,
      token
    });
    if (error) {
      toast.error('Unable to fetch transactionals at this time.');
      return { transactionals: [], size: 0 };
    }

    const {
      data: transactionals,
      meta: { size }
    } = payload;
    return { transactionals, size };
  };

  return { fetchTransactionalById, fetchTransactionals, searchTransactionals };
};
