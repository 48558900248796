import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { UserListPage } from './list';

export const UsersModule = () => {
  return (
    <Switch>
      <Route path="/user-management/users" component={UserListPage} />
    </Switch>
  );
};
