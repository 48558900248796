import { PageContentWrapper } from 'app/member/layouts/wrapper/page-content';
import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import SlidingNav from 'app/shared/nav/sliding/sliding';
import { Spacer } from 'app/member/layouts/generic';
import { useDispatch } from 'react-redux';
import { setPageTitle } from 'store/actions/header';
import { PlansModule } from './plans';
import { ReferralsModule } from './referrals';

const NAVITEMS = [
  {
    title: 'Plans',
    path: '/billings/plans'
  },
  {
    title: 'Referrals',
    path: '/billings/referrals'
  }
];

export const BillingModule = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Billing', path: '.' }]));
  }, []);

  return (
    <PageContentWrapper>
      <SlidingNav nav_items={NAVITEMS} />
      <Spacer multiple={4} />
      <Switch>
        <Route path="/billings/referrals" component={ReferralsModule} />
        <Route path="/*" component={PlansModule} />
      </Switch>
    </PageContentWrapper>
  );
};
