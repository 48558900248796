/** */
export const READ_MANY_PAYMENTS = 'read multiple payments.';
export const READ_ONE_PAYMENT = 'read one payment.';

export const ADD_ONE_PAYMENT_TO_STORE = 'add one payment to store.';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add payment search results to store.';
export const ADD_MANY_PAYMENTS_TO_STORE = 'add multiple payments to store.';
export const LOAD_PAYMENTS_TO_STORE = 'load up payments to store.';
export const REM_ONE_PAYMENT_FROM_STORE = 'remove a payment from the store.';

/** */
export const readManyPayments = (payload) => ({
  type: READ_MANY_PAYMENTS,
  payload
});

export const readOnePayment = (payload) => ({
  type: READ_ONE_PAYMENT,
  payload
});

export const addManyPaymentsToStore = (payload) => ({
  type: ADD_MANY_PAYMENTS_TO_STORE,
  payload
});

export const addOnePaymentToStore = (payload) => ({
  type: ADD_ONE_PAYMENT_TO_STORE,
  payload
});

export const addPaymentSearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const loadPaymentsToStore = (payload) => ({
  type: LOAD_PAYMENTS_TO_STORE,
  payload
});

export const removeOnePaymentFromStore = (payload) => ({
  type: REM_ONE_PAYMENT_FROM_STORE,
  payload
});
