export const determineFormAlertClass = (code) => {
  switch (code) {
    case 0:
      return 'd-block alert-danger';
    case 1:
      return 'd-block alert-success';
    default:
      return '';
  }
};
export const formIsEmpty = (form) => {
  if (!Object.keys(form).length) return true;

  for (const key in form) {
    if (!key) return true;
  }

  return false;
};

export const calcHourDifferenceInTime = (timestamp) => {
  const now = Date.now();

  const difference = now - timestamp;
  const hourDiff = Math.abs(Math.floor(difference / 1000 / 60 / 60));

  return hourDiff;
};

export const campaignCanBeEdited = (campaign) => {
  if (
    campaign.status === 'draft' ||
    campaign.status === 'cancelled' ||
    (campaign.status === 'queued' && campaignCanBeCancelled(campaign.schedule?.date))
  )
    return true;
  return false;
};

export const campaignCanBeCancelled = (timestamp) => {
  const today = new Date();
  const campaign_date = new Date(timestamp);

  return campaign_date > today;
};
