import { PageContentWrapper } from 'app/member/layouts/wrapper/page-content';
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import CurrencyList from './list/list';

const CurrencyListModule = () => {
  return (
    <PageContentWrapper>
      <Switch>
        <Route path="/currencies" component={CurrencyList} />
      </Switch>
    </PageContentWrapper>
  );
};

export default CurrencyListModule;
