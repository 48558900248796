/** */
export const ADD_ONE_PLAN_TO_STORE = 'add one plan to store';
export const ADD_MANY_PLANS_TO_STORE = 'add many plans to store';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add plans search results to store';
export const LOAD_PLANS_TO_STORE = 'load plans to store from API call';
export const REMOVE_MANY_PLANS_FROM_STORE = 'remove many plans from the store.';
export const REMOVE_ONE_PLAN_FROM_STORE = 'remove a plan from the store.';

/** */

export const addManyPlansToStore = (payload) => ({
  type: ADD_MANY_PLANS_TO_STORE,
  payload
});

export const addOnePlanToStore = (payload) => ({
  type: ADD_ONE_PLAN_TO_STORE,
  payload
});

export const addPlanSearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const loadPlansToStore = (payload) => ({
  type: LOAD_PLANS_TO_STORE,
  payload
});

export const removeManyPlansFromStore = (payload) => ({
  type: REMOVE_MANY_PLANS_FROM_STORE,
  payload
});

export const removeOnePlanFromStore = (payload) => ({
  type: REMOVE_ONE_PLAN_FROM_STORE,
  payload
});
