import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';

import { addManySMSToStore, addSMSearchResultsToStore } from 'store/actions/sms';
import { setPageTitle } from 'store/actions/header';
import styles from '../sms.module.css';
import { useSMSService } from 'hooks/api/messaging/sms';

export const SMSListPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { fetchSMSs, searchSMS } = useSMSService();

  const { is_mobile_view } = useSelector((state) => state.metadata);
  const sms_in_store = useSelector((state) => state.sms);

  const [sms, setSMS] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total_sms, setTotalSMS] = useState(0);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'SMS', path: '.' }]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSMS(Object.values(sms_in_store));
  }, [sms_in_store]);

  const config = {
    actions: {
      single: () => [
        {
          value: 'view',
          label: 'View'
        }
      ]
    },
    allow_bulk_action: true,
    css: {},
    fields: [
      {
        title: 'Title',
        key: 'title',
        isTitle: true,
        searchable: true,
        formatter: (value) => (value?.length > 25 ? `${value.substr(0, 25)}...` : value || '')
      },
      {
        title: 'Sender ID',
        key: 'sender_id',
        isTagline: true,
        searchable: true,
        formatter: (value) => value || 'N/A'
      },
      {
        title: 'Status',
        key: 'status',
        searchable: true,
        formatter: (value) => {
          switch (value) {
            case 'processed':
              return <span className={styles.greenBadge}>{value}</span>;
            case 'queued':
              return <span className={styles.infoBadge}>{value}</span>;
            case 'cancelled':
              return <span className={styles.dangerBadge}>{value}</span>;
            default:
              return <span className={styles.dangerBadge}>{value}</span>;
          }
        },
        isBadge: true
      },
      {
        title: 'Send date',
        key: 'schedule',
        formatter: (value) => {
          if (!value?.date) return '...';
          return new Date(value.date).toDateString();
        },
        isMetadata: true
      }
    ],
    is_search_mode,
    items: sms.sort((a, b) => {
      const a_date = a.status === 'draft' ? a.created_on : a.schedule?.date;
      const b_date = b.status === 'draft' ? b.created_on : b.schedule?.date;

      const parsed_a = new Date(a_date).toISOString().replace(/\.\d{3}Z/gi, '.000Z');
      const parsed_b = new Date(b_date).toISOString().replace(/\.\d{3}Z/gi, '.000Z');

      let difference = Date.parse(parsed_b) - Date.parse(parsed_a);
      if (difference === 0) {
        difference = b.id - a.id;
      }

      return difference;
    }),
    search_key: 'name',
    search_text: ''
  };

  const handleDatatableAction = (payload) => {
    const { name, type, data } = payload;

    if (type === 'single') {
      switch (name) {
        default:
          history.push(`/sms/${data.id}`);
      }
    }
  };

  const handleItemClick = (payload) => {
    const { id } = payload;
    history.push(`/sms/${id}`);
  };

  const handleDataRequest = async (page, population = 50) => {
    try {
      setLoading(true);
      const { messages, size } = await fetchSMSs({
        query_string: `page=${page}&population=${population}&sort_by=-created_on`
      });

      dispatch(addManySMSToStore(messages));
      setTotalSMS(size);
    } catch (e) {
      dispatch(addManySMSToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys) return;
    try {
      setLoading(true);
      const { messages, size } = await searchSMS(keys, keyword, {
        query_string: `page=${page}&population=${population}&sort_by=-created_on`
      });

      setTotalSMS(size);
      if (page === 0) return dispatch(addSMSearchResultsToStore(messages));
      dispatch(addManySMSToStore(messages));
    } catch (e) {
      dispatch(addManySMSToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const table_actions = <></>;

  return (
    <div>
      {is_mobile_view ? (
        <MobileDatatable
          config={config}
          action={handleDatatableAction}
          onClick={handleItemClick}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      ) : (
        <WebDatatable
          config={{
            ...config,
            total_count: total_sms
          }}
          action={handleDatatableAction}
          onClick={handleItemClick}
          checkbox
          loading_data={loading}
          table_actions={table_actions}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      )}
    </div>
  );
};
