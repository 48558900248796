/** */
import React from 'react';
import './spinner.css';

const Spinner = (props) => {
  return (
    <div className="gm-spinner-50">
      <div className="loadingio-spinner-dual-ring-mnt0rrug7d">
        <div className="ldio-413e85d3ydp">
          <div></div>
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
