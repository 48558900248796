import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as JourneyService from 'services/automata/journey';

export const useAutomationJourneyService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchJourneys = async (options = {}) => {
    const { error, payload } = await JourneyService.read({ ...options, token });

    if (error) {
      toast.error(error);
      return { journeys: [], size: 0 };
    }

    const {
      data: journeys,
      meta: { size }
    } = payload;
    return { journeys, size };
  };

  return { fetchJourneys };
};
