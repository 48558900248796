import '../dashboard/dashboard.css';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import { addManyWalletLogsToStore, loadWalletLogsToStore } from 'store/actions/wallet-log';
import { useWalletLogService } from 'hooks/api/sales/walletlog';
import { useCampaignService } from 'hooks/api/mailing/campaign';
import { useTransactionalService } from 'hooks/api/mailing/transactional';
import { formatDateForDisplay } from 'app/shared/utils/date';

export const TenantWalletLogs = () => {
  const dispatch = useDispatch();
  const { id: tenant_id } = useParams();
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const walletlogs_in_store = useSelector((state) => state.walletlogs);

  const { fetchCampaigns } = useCampaignService();
  const { fetchTransactionals } = useTransactionalService();
  const { fetchWalletLogs } = useWalletLogService();

  const [wallet_log, setWalletLog] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total_walletlogs, setTotalWalletLogs] = useState(0);

  useEffect(() => {
    setWalletLog(Object.values(walletlogs_in_store));
  }, [walletlogs_in_store]);

  useEffect(() => {
    dispatch(loadWalletLogsToStore([]));
  }, [tenant_id]);

  const config = {
    actions: {
      single: () => [
        {
          label: 'View'
        }
      ]
    },
    allow_bulk_action: false,
    css: {},
    fields: [
      {
        title: 'Amount',
        key: 'amount',
        isTitle: true,
        formatter: (value) => value || 0
      },
      {
        title: 'Currency',
        key: 'currency',
        isTagline: true,
        searchable: true,

        formatter: (value) => value || 'N/A'
      },
      {
        title: 'Kind',
        key: 'kind',
        searchable: true,
        formatter: (value) => {
          switch (value) {
            case 'refund':
              return <span className="greenBadge">{value} </span>;
            case 'charge':
              return <span className="dangerBadge">{value}</span>;
            default:
              return <span className="dangerBadge">{value}</span>;
          }
        },
        isBadge: true
      },
      {
        title: 'Resource',
        key: 'name',
        isTitle: true,
        searchable: true,
        formatter: (value) => (value?.length > 25 ? `${value.substr(0, 25)}...` : value || 'N/A')
      },
      {
        title: 'Date',
        key: 'created_on',
        formatter: (value) => formatDateForDisplay(value),
        isMetadata: true
      }
    ],
    is_search_mode,
    items: wallet_log,
    search_key: 'kind',
    search_text: ''
  };

  const handleDataRequest = async (page, population = 50) => {
    try {
      setLoading(true);
      const { walletlogs, size } = await fetchWalletLogs({
        query_string: `tenant_id=${tenant_id}&page=${page}&population=${population}&sort_by=-created_on`
      });

      const resource_ids = walletlogs.map((log) => log.resource_id);
      const [{ campaigns }, { transactionals }] = await Promise.all([
        fetchCampaigns({
          query_string: `id=${resource_ids.join(',')}&return_only=name,id&tenant_id=${tenant_id}`
        }),
        fetchTransactionals({
          query_string: `id=${resource_ids.join(',')}&return_only=name,id&tenant_id=${tenant_id}`
        })
      ]);

      const resource_map = {
        ...campaigns.reduce((s, c) => ({ ...s, [`campaign:${c.id}`]: c.name }), {}),
        ...transactionals.reduce((s, t) => ({ ...s, [`transactional:${t.id}`]: t.name }), {})
      };

      const logs = walletlogs.map((log) => ({
        ...log,
        name: resource_map[`${log.resource_type}:${log.resource_id}`]
      }));

      dispatch(addManyWalletLogsToStore(logs));
      setTotalWalletLogs(size);
    } catch (e) {
      dispatch(addManyWalletLogsToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {};

  return (
    <div>
      {is_mobile_view ? (
        <MobileDatatable
          config={config}
          action={false}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      ) : (
        <WebDatatable
          config={{
            ...config,
            total_count: total_walletlogs
          }}
          action={false}
          checkbox
          loading_data={loading}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      )}
    </div>
  );
};
