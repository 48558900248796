import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as CampaignService from 'services/mailing/campaign';

export const useCampaignService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchCampaignById = async (id, options = {}) => {
    const { error, payload } = await CampaignService.readById(id, { ...options, token });
    if (error) {
      toast.error('Unable to fetch campaign at this time.');
      return { campaign: {} };
    }

    return { campaign: payload };
  };

  const fetchCampaigns = async (options = {}) => {
    const { error, payload } = await CampaignService.read({ ...options, token });
    if (error) {
      toast.error('Unable to fetch campaigns at this time.');
      return { campaigns: [], size: 0 };
    }

    const {
      data: campaigns,
      meta: { size }
    } = payload;
    return { campaigns, size };
  };

  const searchCampaigns = async (keys, keyword, options = {}) => {
    const { error, payload } = await CampaignService.search(keys, keyword, { ...options, token });
    if (error) {
      toast.error('Unable to fetch campaigns at this time.');
      return { campaigns: [], size: 0 };
    }

    const {
      data: campaigns,
      meta: { size }
    } = payload;
    return { campaigns, size };
  };

  return { fetchCampaignById, fetchCampaigns, searchCampaigns };
};
