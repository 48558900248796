import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/** */
import { setPageTitle } from 'store/actions/header';
import {
  addManyAudiencesToStore,
  addAudienceSearchResultsToStore,
  loadAudienceToStore
} from 'store/actions/audience';

/** */
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import WebDatatable from 'app/shared/datatable/web/datatable';
import { useAudienceService } from 'hooks/api/users/audience';
import { useContactService } from 'hooks/api/users/contact';
import { useNumbers } from 'hooks/ui/general/numbers';

const ListMailingLists = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { toReadableNumber } = useNumbers();
  const { fetchAudiences, searchAudiences } = useAudienceService();
  const { fetchContacts } = useContactService();

  const { is_mobile_view } = useSelector((state) => state.metadata);
  const audiences_in_store = useSelector((state) => state.audiences);

  const [loading, setLoading] = useState(false);
  const [total_audiences, setTotalAudiences] = useState(0);
  const [mailing_lists, setMailingLists] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Audiences', path: '.' }]));
  }, []);

  useEffect(() => {
    setMailingLists(Object.values(audiences_in_store));
  }, [audiences_in_store]);

  const build_query_string = (page = 0, population = 50) => {
    return `return_only=name,id,description,created_on,tenant_id,is_archived&page=${page}&population=${population}&sort_by=-created_on`;
  };

  const config = {
    actions: {
      single: () => [{ label: 'View', value: 'view' }]
    },
    allow_bulk_action: true,
    css: {},
    fields: [
      {
        title: 'Name',
        key: 'name',
        isTitle: true,
        searchable: true
      },
      {
        title: 'Description',
        key: 'description',
        formatter: (value) => (is_mobile_view ? `${value && value.substr(0, 35)}...` : value),
        isTagline: true
      },
      {
        title: 'No. of Contacts',
        key: 'contacts',
        formatter: (value) => {
          return toReadableNumber(value);
        }
      },
      {
        title: 'Date created',
        key: 'created_on',
        formatter: (value) => (value ? new Date(value).toDateString() : ''),
        isMetadata: true
      },
      {
        title: 'Tenant ID',
        key: 'tenant_id',
        formatter: (value) => {
          return value;
        }
      }
    ],
    is_search_mode,
    items: mailing_lists,
    search_key: 'name',
    search_text: ''
  };

  const handleItemClick = (payload) => {
    const { id } = payload;
    history.push(`/audiences/${id}?name=${payload.name}`);
  };

  const handleDataRequest = async (page, population = 50) => {
    try {
      setLoading(true);
      const query_string = build_query_string(page, population);
      const { audiences, size: audience_count } = await fetchAudiences({ query_string });
      const initial_audiences = audiences.map((audience) => ({ ...audience, contacts: 0 }));

      if (!page || page === 0) {
        dispatch(loadAudienceToStore(initial_audiences));
      } else {
        dispatch(addManyAudiencesToStore(initial_audiences));
      }

      const aud_ids = [];
      const responses = await Promise.all(
        audiences.map((audience) => {
          aud_ids.push(audience.id);
          return fetchContacts({
            query_string: `subscriptions=${audience.id}&count=1`
          });
        })
      );
      const count_by_id = responses.reduce(
        (s, { size }, index) => ({
          ...s,
          [aud_ids[index]]: size
        }),
        {}
      );

      const updated_audiences = audiences.map((a) => ({
        ...a,
        contacts: count_by_id[a.id]
      }));

      if (!page || page === 0) {
        dispatch(loadAudienceToStore(updated_audiences));
      } else {
        dispatch(addManyAudiencesToStore(updated_audiences));
      }
      setTotalAudiences(() => audience_count);
    } catch (e) {
      dispatch(addManyAudiencesToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const handleDatatableAction = (payload) => {
    const { name, type, data } = payload;
    if (type === 'single') {
      if (name === 'view') {
        history.push(`/audiences/${data.id}`);
      }
    }
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys) return;
    try {
      setLoading(true);
      const { audiences, size: audience_count } = await searchAudiences(keys, keyword, {
        query_string: `page=${page}&population=${population}`
      });

      const aud_ids = [];
      const responses = await Promise.all(
        audiences.map((audience) => {
          aud_ids.push(audience.id);
          return fetchContacts({
            query_string: `subscriptions=${audience.id}&count=1`
          });
        })
      );

      const count_by_id = responses.reduce(
        (s, { size }, index) => ({
          ...s,
          [aud_ids[index]]: size
        }),
        {}
      );

      const parsed_audiences = audiences.map((a) => ({ ...a, contacts: count_by_id[a.id] }));
      if (page === 0) return dispatch(addAudienceSearchResultsToStore(parsed_audiences));
      dispatch(addManyAudiencesToStore(parsed_audiences));
      setTotalAudiences(audience_count);
    } catch (e) {
      dispatch(addManyAudiencesToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const table_actions = <></>;

  return (
    <div>
      {is_mobile_view ? (
        <MobileDatatable
          config={config}
          action={handleDatatableAction}
          onClick={handleItemClick}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      ) : (
        <div>
          <WebDatatable
            action={handleDatatableAction}
            checkbox
            config={{
              ...config,
              total_count: total_audiences
            }}
            onClick={handleItemClick}
            loading_data={loading}
            table_actions={table_actions}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
          />
        </div>
      )}
    </div>
  );
};

export default ListMailingLists;
