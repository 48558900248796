import { DatePicker } from '@arco-design/web-react';
import { GridColumn, GridRow } from 'app/member/layouts/grid';
import { Button } from 'app/shared/button';
import { DetailItem } from 'app/shared/info-section/item';
import { SimpleInput } from 'app/shared/input';
import { useSubscriptionService } from 'hooks/api/sales/subscriptions';
import React, { useEffect, useState } from 'react';

export const SubscriptionUpdation = ({ data = {}, closeModal, onSave = () => {} }) => {
  const { updateSubscription } = useSubscriptionService();

  const [amount, setAmount] = useState(0);
  const [expires_at, setExpiresAt] = useState(null);
  const [resources, setResources] = useState({});

  useEffect(() => {
    const { price, expires_at, resources } = data;
    if (!price || !price.amount || !expires_at || !resources) return;
    const { amount } = price;
    const [date_value] = expires_at.split('T');
    setAmount(amount || 0);
    setExpiresAt(date_value);
    setResources(
      (resources || []).reduce((sac, resource) => ({ ...sac, [resource.name]: resource.limit }), {})
    );

    return () => {
      setAmount(0);
      setExpiresAt(null);
      setResources([]);
    };
  }, [data]);

  const handleResourceChange = (key, value) => {
    setResources((curr_resources) => ({
      ...curr_resources,
      [key]: value
    }));
  };

  const submit = async () => {
    const default_resources = (data.resources || []).reduce(
      (sac, res) => ({ ...sac, [res.name]: res }),
      {}
    );
    const data_to_save = {
      ...data,
      expires_at: new Date(expires_at),
      price: { ...data.price, amount },
      resources: Object.keys(resources).map((resource) => ({
        ...default_resources[resource],
        name: resource,
        limit: resources[resource]
      }))
    };

    const { subscription } = await updateSubscription(data.id, { data: data_to_save });
    if (!subscription) return;

    onSave(subscription);
    closeModal();
  };

  return (
    <>
      <GridRow num_of_columns={2}>
        <GridColumn>
          <DetailItem title="Amount">
            <SimpleInput type="number" value={amount} onInput={setAmount} />
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="Expiration Date">
            <DatePicker
              value={expires_at}
              onChange={setExpiresAt}
              style={{ zIndex: 999, border: '1px solid var(--neutral-dark-4)', height: '40px' }}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={3}>
        {Object.keys(resources).map((resource) => (
          <GridColumn key={resource}>
            <DetailItem title={resource}>
              <SimpleInput
                type="number"
                value={resources[resource]}
                onInput={(value) => handleResourceChange(resource, value)}
              />
            </DetailItem>
          </GridColumn>
        ))}
      </GridRow>
      <GridRow num_of_columns={4}>
        <GridColumn span={3} />
        <GridColumn>
          <Button text="Save changes" onClick={submit} />
        </GridColumn>
      </GridRow>
    </>
  );
};
