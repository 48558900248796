import { Spacer } from 'app/member/layouts/generic';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CampaignInformation } from './info';
import { SMSStatBar } from './statbar';

export const SMSViewPage = () => {
  const { id } = useParams();

  return (
    <>
      <SMSStatBar id={id} />
      <Spacer multiple={4} />
      <CampaignInformation id={id} />
    </>
  );
};
