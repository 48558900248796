import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../button';
import { SimpleInput } from '../input';
import { toast } from 'react-toastify';
import { addOneTenantToStore } from 'store/actions/tenant';
import { MultiSelect } from '../select';
import { useWalletService } from 'hooks/api/sales/wallet';

export const WalletTopUp = ({ tenant_id }) => {
  const dispatch = useDispatch();
  const { fetchWallets, updateWallet } = useWalletService();

  const tenants = useSelector((state) => state.tenants);
  const tenant = tenants[tenant_id];

  const [rate, setRate] = useState(tenant?.rate);
  const [balance, setBalance] = useState(0);
  const [curr_balance, setCurrBalance] = useState(tenant?.balance);
  const [saving, setSaving] = useState(false);
  const [top_up_reason, setTopUpReason] = useState('');

  const REASON_OPTIONS = [
    {
      value: 'free-credit',
      label: 'Free Credit'
    },
    {
      value: 'bank-credit',
      label: 'Bank Credit'
    },
    {
      value: 'refund',
      label: 'Refund'
    }
  ];
  useEffect(() => {
    setRate(tenant?.rate);
    setCurrBalance(tenant?.balance);
  }, [tenant]);

  const submit = async () => {
    const {
      wallets: [wallet]
    } = await fetchWallets({ query_string: `tenant_id=${tenant_id}` });

    const new_rate = Number(rate);
    const new_balance = Number(balance) + Number(wallet?.balance);

    if (isNaN(new_rate) || new_rate < 0) return toast.error('Please set a valid rate.');
    if (isNaN(new_balance) || new_balance < 0) return toast.error('Please set balance.');

    setSaving(true);
    const result = await updateWallet({
      data: {
        tenant_id,
        rate,
        balance: new_balance,
        reason: top_up_reason.value
      }
    });

    if (result) {
      setBalance(() => 0);
      setCurrBalance(new_balance);
      dispatch(
        addOneTenantToStore({
          ...tenant,
          rate: new_rate,
          balance: new_balance
        })
      );
    }
    setSaving(false);
  };
  return (
    <>
      <SimpleInput
        type="number"
        label="Rate"
        value={rate}
        onInput={setRate}
        placeholder="Rate must be greater than on equal to 250"
      />
      <SimpleInput disabled type="number" label="Current balance" value={curr_balance} />
      <SimpleInput type="number" label="Amount" value={balance} onInput={setBalance} />
      <div>
        <label>Reason:</label>
        <MultiSelect options={REASON_OPTIONS} onChange={setTopUpReason} value={top_up_reason} />
      </div>
      <Button onClick={submit} loading={saving} text="Save" icon_name="check_circle" />
    </>
  );
};
