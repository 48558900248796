import '../dashboard/dashboard.css';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import {
  addManyPaymentsToStore,
  loadPaymentsToStore,
  addPaymentSearchResultsToStore,
  addOnePaymentToStore
} from 'store/actions/payment';
import { formatDateForDisplay } from 'app/shared/utils/date';
import { usePaymentService } from 'hooks/api/sales/payment';

export const TenantPaymentLogs = () => {
  const dispatch = useDispatch();
  const { id: tenant_id } = useParams();
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const payments_in_store = useSelector((state) => state.payments);

  const { fetchPayments, searchPayments, verifyPayment } = usePaymentService();

  const [payment_log, setPaymentLog] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total_paymentlogs, setTotalPaymentLogs] = useState(0);

  useEffect(() => {
    setPaymentLog(Object.values(payments_in_store));
  }, [payments_in_store]);

  useEffect(() => {
    dispatch(loadPaymentsToStore([]));
  }, [tenant_id]);

  const config = {
    actions: {
      single: (payment) => [
        {
          label: 'Verify payment',
          value: 'verify',
          hidden: payment.status !== 'pending'
        }
      ]
    },
    allow_bulk_action: false,
    css: {},
    fields: [
      {
        title: 'Amount',
        key: 'amount',
        isTitle: true,
        formatter: (value) => value || 0
      },
      {
        title: 'Currency',
        key: 'currency',
        isTagline: true,
        searchable: true,
        formatter: (value) => value || 'N/A'
      },
      {
        title: 'Status',
        key: 'status',
        searchable: true,
        formatter: (value) => {
          switch (value) {
            case 'successful':
              return <span className="greenBadge">{value}</span>;
            case 'pending':
              return <span className="infoBadge">{value}</span>;
            default:
              return <span className="infoBadge">{value}</span>;
          }
        },
        isBadge: true
      },
      {
        title: 'Reference',
        key: 'tx_ref',
        isTitle: true,
        searchable: true,
        formatter: (value) => (value?.length > 35 ? `${value.substr(0, 35)}...` : value || 'N/A')
      },
      {
        title: 'Date',
        key: 'created_on',
        formatter: (value) => formatDateForDisplay(value),
        isMetadata: true
      }
    ],
    is_search_mode,
    items: payment_log,
    search_key: 'tx_ref',
    search_text: ''
  };

  const handleDatatableAction = (payload) => {
    const { name, type, data } = payload;

    if (type === 'single') {
      switch (name) {
        case 'verify':
          handleVerifyPayment(data);
          break;
        default:
          break;
      }
    }
  };

  const handleDataRequest = async (page, population = 50) => {
    try {
      setLoading(true);
      const { payments, size } = await fetchPayments({
        query_string: `tenant_id=${tenant_id}&page=${page}&population=${population}&sort_by=-created_on`
      });

      dispatch(addManyPaymentsToStore(payments));
      setTotalPaymentLogs(size);
    } catch (e) {
      dispatch(addManyPaymentsToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys) return;
    try {
      setLoading(true);
      const { payments, size } = await searchPayments(keys, keyword, {
        query_string: `tenant_id=${tenant_id}&page=${page}&population=${population}&sort_by=-created_on`
      });

      setTotalPaymentLogs(size);
      if (page === 0) return dispatch(addPaymentSearchResultsToStore(payments));
      dispatch(addManyPaymentsToStore(payments));
    } catch (e) {
      dispatch(addManyPaymentsToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyPayment = async (data) => {
    const result = await verifyPayment({
      data: {
        transaction_id: data.tx_ref,
        tx_ref: data.tx_ref,
        status: data.status
      }
    });

    if (result) dispatch(addOnePaymentToStore({ ...data, status: 'successful' }));
  };

  return (
    <div>
      {is_mobile_view ? (
        <MobileDatatable
          config={config}
          action={handleDatatableAction}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      ) : (
        <WebDatatable
          config={{
            ...config,
            total_count: total_paymentlogs
          }}
          action={handleDatatableAction}
          checkbox
          loading_data={loading}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      )}
    </div>
  );
};
