import React from 'react';
import Select, { components } from 'react-select';
import styles from './select.module.css';
import cx from 'classnames';

const custom_theme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    neutral10: 'var(--blue-primary)',
    neutral80: '#FFFFFF',
    neutral90: '#FFFFFF'
  }
});

const custom_styles = {
  menuList: (provided) => ({
    ...provided,
    minWidth: 300
  }),
  multiValue: (provided) => ({
    ...provided,
    color: '#ffffff'
  }),
  singleValue: (provided) => ({
    ...provided,
    borderRadius: '5px',
    backgroundColor: 'var(--blue-primary)',
    padding: '0 15px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 8px'
  }),
  input: (provided) => ({
    ...provided,
    paddingTop: '0',
    paddingBottom: '0',
    margin: '0',
    color: 'var(--neutral-dark-2)'
  }),
  option: (provided) => ({
    ...provided,
    width: '100% !important',
    color: 'var(--neutral-dark-2)'
  })
};

const Control = ({ selectProps, ...props }) => {
  const error_class = selectProps.error ? styles.errorBorder : '';
  return <components.Control {...props} className={cx(styles.control, error_class)} />;
};

const Option = (props) => {
  const { isMulti, isSelected } = props;
  if (isMulti)
    return (
      <components.Option {...props}>
        <div className={styles.menulist}>
          <div className={`${styles.gmCheckbox} ${isSelected ? styles.checkboxOn : ''} `}></div>
          {props.children}
        </div>
      </components.Option>
    );
  return <components.Option {...props} className={styles.option} />;
};

export const MultiSelect = ({
  defaultValue = [],
  options = [],
  placeholder,
  onChange,
  value,
  isMulti,
  error
}) => (
  <>
    <Select
      isMulti={isMulti}
      className={styles.rselect}
      options={options}
      onChange={onChange}
      defaultValue={defaultValue}
      value={value}
      components={{
        Control,
        Option
      }}
      closeMenuOnSelect={!isMulti}
      menuPlacement="auto"
      placeholder={placeholder}
      styles={custom_styles}
      theme={custom_theme}
    />
    {error && <span className={styles.error}>{error}</span>}
  </>
);
