/** */
export const READ_MANY_CURRENCY = 'read multiple currency.';
export const ADD_MANY_CURRENCY_TO_STORE = 'add multiple currency to store.';
export const REMOVE_ONE_CURRENCY_FROM_STORE = 'remove one currency from store';
export const REMOVE_MANY_CURRENCY_FROM_STORE = 'remove many currency from store';
export const ADD_ONE_CURRENCY_TO_STORE = 'add one currency to store';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add currency search results to store.';

/** */
export const readManyCurrency = (payload) => ({
  type: READ_MANY_CURRENCY,
  payload
});

export const addManyCurrencyToStore = (payload) => ({
  type: ADD_MANY_CURRENCY_TO_STORE,
  payload
});

export const removeOneCurrencyFromStore = (payload) => ({
  type: REMOVE_ONE_CURRENCY_FROM_STORE,
  payload
});

export const removeManyCurrencyFromStore = (payload) => ({
  type: REMOVE_MANY_CURRENCY_FROM_STORE,
  payload
});

export const addOneCurrencyToStore = (payload) => ({
  type: ADD_ONE_CURRENCY_TO_STORE,
  payload
});

export const addCurrencySearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});
