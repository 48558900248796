import React from 'react';
import { icons } from './definitions';

export const SiteIcon = ({ negative, height = 24, width = 24 }) => {
  const stroke = negative ? 'white' : '#2B99FF';
  const path_fill = negative ? 'white' : '#FFAA2B';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 1.60538C6.92852 1.06826 7.93569 0.651986 9 0.378052V23.6219C7.93569 23.348 6.92852 22.9317 6 22.3946V1.60538ZM3 4.06252C1.13291 6.17792 0 8.95666 0 12C0 15.0433 1.13291 17.8221 3 19.9375V4.06252Z"
        fill={stroke}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0V24Z"
        fill={path_fill}
      />
    </svg>
  );
};

export const Icon = ({ name = '', width = 24, height = 24, onClick = () => {} }) => {
  const icon = icons[name] || icons.add;
  return <span onClick={onClick}>{icon(width, height)}</span>;
};
