import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';

import { addManyRolesToStore, addRoleSearchResultsToStore } from 'store/actions/role';
import { setPageTitle } from 'store/actions/header';
import styles from './list.module.css';
import { useRoleService } from 'hooks/api/iam/role';
import { truncateText } from 'app/shared/utils/general';

export const RolesListPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { fetchRoles, searchroles } = useRoleService();

  const { is_mobile_view } = useSelector((state) => state.metadata);
  const roles_in_store = useSelector((state) => state.roles);

  const [roles, setRoles] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total_roles, setTotalRoles] = useState(0);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'My Roles', path: '.' }]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRoles(Object.values(roles_in_store));
  }, [roles_in_store]);

  const config = {
    actions: {
      single: () => []
    },
    allow_bulk_action: true,
    css: {},
    fields: [
      {
        title: 'Name',
        key: 'name',
        isTitle: true,
        searchable: true,
        formatter: (value) => truncateText(value, 40)
      },
      {
        title: 'Description',
        key: 'description',
        isTagline: true,
        searchable: true,
        formatter: (value) => value || 'N/A'
      },
      {
        title: 'Type',
        key: 'type',
        searchable: true,
        formatter: (value) => {
          switch (value) {
            case 'system':
              return <span className={styles.greenBadge}>{value}</span>;
            default:
              return <span className={styles.infoBadge}>{value}</span>;
          }
        },
        isBadge: true
      },
      {
        title: 'Modified On',
        key: 'updated_on',
        formatter: (value) => new Date(value).toDateString(),
        isMetadata: true
      }
    ],
    is_search_mode,
    items: roles.sort((a, b) => b.id - a.id),
    search_key: 'name',
    search_text: ''
  };

  const handleDatatableAction = (payload) => {
    const { name, type, data } = payload;

    if (type === 'single') {
      switch (name) {
        default:
          history.push(`/roles/${data.id}`);
      }
    }
  };

  const handleItemClick = (payload) => {
    const { id } = payload;
    history.push(`/roles/${id}`);
  };

  const handleDataRequest = async (page, population = 50) => {
    try {
      setLoading(true);
      const { roles, size } = await fetchRoles({
        query_string: `page=${page}&population=${population}&sort_by=-created_on`
      });

      dispatch(addManyRolesToStore(roles));
      setTotalRoles(size);
    } catch (e) {
      dispatch(addManyRolesToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys) return;
    try {
      setLoading(true);
      const { roles, size } = await searchroles(keys, keyword, {
        query_string: `page=${page}&population=${population}&sort_by=-created_on`
      });

      setTotalRoles(size);
      if (page === 0) return dispatch(addRoleSearchResultsToStore(roles));
      dispatch(addManyRolesToStore(roles));
    } catch (e) {
      dispatch(addManyRolesToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const table_actions = <></>;

  return (
    <div>
      {is_mobile_view ? (
        <MobileDatatable
          config={config}
          action={handleDatatableAction}
          onClick={handleItemClick}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      ) : (
        <WebDatatable
          config={{
            ...config,
            total_count: total_roles
          }}
          action={handleDatatableAction}
          onClick={handleItemClick}
          checkbox
          loading_data={loading}
          table_actions={table_actions}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      )}
    </div>
  );
};
