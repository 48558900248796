import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';

import {
  addManyPaymentsToStore,
  addOnePaymentToStore,
  addPaymentSearchResultsToStore
} from 'store/actions/payment';
import { setPageTitle } from 'store/actions/header';
import styles from '../payment.module.css';
import { usePaymentService } from 'hooks/api/sales/payment';
import { useTenantService } from 'hooks/api/iam/tenant';

const ListPayment = () => {
  const dispatch = useDispatch();
  const { fetchTenants } = useTenantService();
  const { fetchPayments, searchPayments, verifyPayment } = usePaymentService();

  const { is_mobile_view } = useSelector((state) => state.metadata);
  const payments_in_store = useSelector((state) => state.payments);

  const [payments, setPayments] = useState([]);
  const [tenants, setTenants] = useState({});
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading_search, setLoadingSearch] = useState(false);

  const [total_payments, setTotalPayments] = useState(0);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'My Payments', path: '.' }]));
    setLoading(true);
    fetchTenants()
      .then(async ({ tenants }) => {
        const tenant_map = tenants.reduce((sac, tenant) => {
          return {
            ...sac,
            [tenant.id]: tenant
          };
        }, {});

        setTenants(() => tenant_map);
        const { payments, size } = await fetchPayments({
          query_string: 'page=0&population=50&sort_by=-created_on'
        });
        dispatch(
          addManyPaymentsToStore(
            payments.map((payment) => {
              return {
                ...payment,
                tenant_name: tenant_map[payment.tenant_id]?.name
              };
            })
          )
        );
        setTotalPayments(size);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPayments(Object.values(payments_in_store));
  }, [payments_in_store]);

  const config = {
    actions: {
      single: (payment) => [
        {
          label: 'Verify payment',
          value: 'verify'
          // hidden: payment.status !== 'pending'
        }
      ]
    },
    allow_bulk_action: false,
    css: {},
    fields: [
      {
        title: 'Reference',
        key: 'tx_ref',
        searchable: true,
        isTitle: true
      },
      {
        title: 'Amount',
        key: 'amount',
        isTagline: true
      },
      {
        title: 'Currency',
        key: 'currency',
        searchable: true,
        isTagline: true
      },
      {
        title: 'Tenant',
        searchable: true,
        key: 'tenant_name'
      },
      {
        title: 'Status',
        key: 'status',
        searchable: true,
        formatter: (value) => {
          switch (value) {
            case 'successful':
              return <span className={styles.greenBadge}>{value}</span>;
            case 'pending':
              return <span className={styles.infoBadge}>{value}</span>;
            default:
              return <span className={styles.dangerBadge}>{value}</span>;
          }
        },
        isBadge: true
      },
      {
        title: 'date',
        key: 'created_on',
        formatter: (value) => new Date(value).toDateString(),
        isMetadata: true
      }
    ],
    is_search_mode,
    items: payments,
    search_key: 'reference,amount',
    search_text: ''
  };

  const handleVerifyPayment = async (data) => {
    const result = await verifyPayment({
      data: {
        transaction_id: data.tx_ref,
        tx_ref: data.tx_ref,
        status: data.status
      }
    });

    if (result) dispatch(addOnePaymentToStore({ ...data, status: 'successful' }));
  };

  const handleDatatableAction = (payload) => {
    const { name, type, data } = payload;

    if (type === 'single') {
      switch (name) {
        case 'verify':
          handleVerifyPayment(data);
          break;
        default:
          break;
      }
    }
  };

  const handleDataRequest = async (page, population = 50) => {
    try {
      setLoading(true);
      const { payments, size } = await fetchPayments({
        query_string: `page=${page}&population=${population}&sort_by=-created_on`
      });

      dispatch(
        addManyPaymentsToStore(
          payments.map((payment) => ({
            ...payment,
            tenant_name: tenants[payment.tenant_id]?.firstname
          }))
        )
      );
      setTotalPayments(size);
    } catch (e) {
      dispatch(addManyPaymentsToStore([]));
    }
  };

  const handleSearchRequest = async (keys, keyword, page) => {
    if (!keys) return;
    setLoadingSearch(true);
    try {
      const { payments, size } = await searchPayments(keys, keyword, {
        query_string: `page=${page}&population=50&sort_by=-created_on`
      });

      dispatch(
        addPaymentSearchResultsToStore(
          payments.map((payment) => ({
            ...payment,
            tenant_name: tenants[payment.tenant_id]?.firstname
          }))
        )
      );
      setTotalPayments(size);
    } catch (e) {
      dispatch(addManyPaymentsToStore([]));
    } finally {
      setLoadingSearch(false);
    }
  };

  const table_actions = <></>;

  return (
    <div>
      {is_mobile_view ? (
        <MobileDatatable
          config={config}
          action={handleDatatableAction}
          onClick={() => {}}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      ) : (
        <WebDatatable
          config={{
            ...config,
            total_count: total_payments
          }}
          action={handleDatatableAction}
          onClick={() => {}}
          checkbox
          request_complete={!loading}
          onDataRequest={handleDataRequest}
          loadingSearch={loading_search}
          table_actions={table_actions}
          onSearchRequest={handleSearchRequest}
        />
      )}
    </div>
  );
};

export default ListPayment;
