import { PageContentWrapper } from 'app/member/layouts/wrapper/page-content';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { TutotialListPage } from './list';

export const TutorialModule = () => {
  return (
    <PageContentWrapper>
      <Switch>
        <Route path="/tutorials" component={TutotialListPage} />
      </Switch>
    </PageContentWrapper>
  );
};
