import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from 'store/actions/header';
import { Switch, Route, Redirect } from 'react-router-dom';
import SlidingNav from 'app/shared/nav/sliding/sliding';
import styles from './settings.module.css';
import EventAutomation from './event';
import EffectAutomation from './effect';
import { PageContentWrapper } from 'app/member/layouts/wrapper/page-content';
import { PasswordUpdateForm } from './password';
import { Spacer } from 'app/member/layouts/generic';

const NAVITEMS = [
  {
    title: 'Password',
    path: '/settings/password'
  },
  {
    title: 'Automation Events',
    path: '/settings/event'
  },
  {
    title: 'Automation Effects',
    path: '/settings/effect'
  }
];

const SettingsModule = () => {
  const dispatch = useDispatch();
  const [nav_items, setNavItems] = useState(NAVITEMS);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Settings', path: '.' }]));
    setNavItems(NAVITEMS);
  }, [dispatch]);

  return (
    <PageContentWrapper>
      <header>
        <SlidingNav nav_items={nav_items} />
      </header>
      <Spacer multiple={4} />
      <section className={styles.content}>
        <Switch>
          <Route path="/settings/password" component={PasswordUpdateForm} />
          <Route exact path="/settings/effect" component={EffectAutomation} />
          <Route exact path="/settings/effect/:id" component={EffectAutomation} />
          <Route exact path="/settings/event" component={EventAutomation} />
          <Route exact path="/settings/event/:id" component={EventAutomation} />

          <Redirect from="/**" to="/settings/password" />
        </Switch>
      </section>
    </PageContentWrapper>
  );
};

export default SettingsModule;
