/** */
import React from 'react';
import cx from 'classnames';
import { Route, Switch, useHistory } from 'react-router-dom';

/** */
import LoginForm from './login/login';

/** */
import styles from './guest.module.css';
import { SiteIcon } from 'assets/icons';

const GuestArea = () => {
  const history = useHistory();
  return (
    <div className={styles.wrapper}>
      <div className={cx(styles.header, 'flex-row-center')}>
        <SiteIcon width={48} height={24} onClick={() => history.push('/')} />
        <span className={styles.siteName} onClick={() => history.push('/')}>
          {' '}
          Go-Mailer{' '}
        </span>
      </div>
      <div className={styles.formWrapper}>
        <Switch>
          <Route path="/" component={LoginForm} />
        </Switch>
      </div>
    </div>
  );
};

export default GuestArea;
