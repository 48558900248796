import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as AdminService from 'services/users/guest';

export const useAdminService = () => {
  const { token } = useSelector((store) => store.user_data);

  const login = async (options = {}) => {
    const { error, payload: tenant } = await AdminService.login({ ...options, token });
    if (error) {
      toast.error('Unable to fetch tenants at this time.');
      return { tenant: null, error };
    }

    return { tenant, error: null };
  };

  const resetPassword = async (options = {}) => {
    const { error } = await AdminService.resetPassword({ ...options, token });
    if (error) {
      toast.error('Unable to update tenant at this time.');
      return false;
    }

    toast.error('Password updated successfully.');
    return true;
  };

  return { login, resetPassword };
};
