/** */
import {
  ADD_MANY_RESOURCES_TO_STORE,
  ADD_ONE_RESOURCE_TO_STORE,
  ADD_SEARCH_RESULTS_TO_STORE,
  LOAD_RESOURCES_TO_STORE,
  REM_ONE_RESOURCE_FROM_STORE
} from '../actions/resource';

export default (state = {}, action) => {
  const { type, payload } = action;
  let previous_state;
  switch (type) {
    case ADD_MANY_RESOURCES_TO_STORE:
      return {
        ...state,
        ...payload.reduce(
          (resources, resource) => ({
            ...resources,
            [resource.id]: resource
          }),
          {}
        )
      };
    case ADD_ONE_RESOURCE_TO_STORE:
      return {
        ...state,
        [payload.id]: payload
      };
    case ADD_SEARCH_RESULTS_TO_STORE:
      return {
        ...payload.reduce(
          (resources, resource) => ({
            ...resources,
            [resource.id]: resource
          }),
          {}
        )
      };
    case LOAD_RESOURCES_TO_STORE:
      return {
        ...payload.reduce(
          (resources, resource) => ({
            ...resources,
            [resource.id]: resource
          }),
          {}
        )
      };
    case REM_ONE_RESOURCE_FROM_STORE:
      previous_state = { ...state };
      delete previous_state[payload];
      return {
        ...previous_state
      };
    default:
      return state;
  }
};
