/** */
import { useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

import { useTenantWalletData, WalletContext } from 'app/hooks/wallet-context';

import SideNav from './side-nav/side-nav';
import { ModuleLayout } from './modules';
import { addMetadata } from 'store/actions/metadata';
import './member.css';

const MemberArea = () => {
  const dispatch = useDispatch();
  const { tenant_wallet_list } = useTenantWalletData();
  const [open_menu_tray, setOpenMenuTray] = useState(false);

  const setMobileFlag = () => {
    if (window.screen.width < 768) {
      dispatch(addMetadata({ is_mobile_view: true }));
    } else dispatch(addMetadata({ is_mobile_view: false }));
  };

  useEffect(() => {
    setMobileFlag();
    window.addEventListener('resize', () => {
      setMobileFlag();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WalletContext.Provider value={{ tenant_wallet_list }}>
      <div className="member-area-wrapper">
        <section className="app-body">
          <SideNav open_tray={open_menu_tray} onTrayClose={() => setOpenMenuTray(false)} />
          <Switch>
            <Route path="/" component={() => <ModuleLayout setOpenMenuTray={setOpenMenuTray} />} />
          </Switch>
        </section>
      </div>
    </WalletContext.Provider>
  );
};

export default MemberArea;
