import '../dashboard/dashboard.css';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';

import { setPageTitle } from 'store/actions/header';
import { useCampaignService } from 'hooks/api/mailing/campaign';

export const TenantCampaigns = () => {
  const { id: tenant_id } = useParams();
  const dispatch = useDispatch();
  const { fetchCampaigns } = useCampaignService();

  const { is_mobile_view } = useSelector((state) => state.metadata);

  const [campaigns, setCampaigns] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total_campaigns, setTotalCampaigns] = useState(0);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'My Campaigns', path: '.' }]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const config = {
    actions: {
      single: () => []
    },
    allow_bulk_action: true,
    css: {},
    fields: [
      {
        title: 'Campaign Name',
        key: 'name',
        isTitle: true,
        searchable: true,
        formatter: (value) => (value?.length > 35 ? `${value.substr(0, 35)}...` : value || '')
      },
      {
        title: 'Sender Email',
        key: 'sender_email',
        isTagline: true,
        searchable: true,
        formatter: (value) => value || 'N/A'
      },
      {
        title: 'Status',
        key: 'status',
        searchable: true,
        formatter: (value) => {
          switch (value) {
            case 'processed':
              return <span className="greenBadge">{value}</span>;
            case 'queued':
              return <span className="infoBadge">{value}</span>;
            case 'cancelled':
              return <span className="dangerBadge">{value}</span>;
            default:
              return <span className="dangerBadge">{value}</span>;
          }
        },
        isBadge: true
      },
      {
        title: 'Send date',
        key: 'schedule',
        formatter: (value) => {
          if (!value?.date) return '...';
          return new Date(value.date).toDateString();
        },
        isMetadata: true
      }
    ],
    is_search_mode,
    items: campaigns.sort((a, b) => {
      const a_date = a.status === 'draft' ? a.created_on : a.schedule?.date;
      const b_date = b.status === 'draft' ? b.created_on : b.schedule?.date;

      const parsed_a = new Date(a_date).toISOString().replace(/\.\d{3}Z/gi, '.000Z');
      const parsed_b = new Date(b_date).toISOString().replace(/\.\d{3}Z/gi, '.000Z');

      let difference = Date.parse(parsed_b) - Date.parse(parsed_a);
      if (difference === 0) {
        difference = b.id - a.id;
      }

      return difference;
    }),
    search_key: 'name',
    search_text: ''
  };
  const handleDatatableAction = (payload) => {};

  const handleItemClick = (payload) => {};

  const handleDataRequest = async (page, population = 50) => {
    try {
      setLoading(true);
      const { campaigns, size } = await fetchCampaigns({
        query_string: `page=${page}&population=${population}&bool=-is_test_campaign&tenant_id=${tenant_id}
        &sort_by=-created_on&return_only=is_advanced,id,name,sender_name,sender_email,status,schedule,failed_sends_list,created_on`
      });

      setCampaigns((current) => [...current, ...campaigns]);
      setTotalCampaigns(size);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {};

  const table_actions = <></>;

  return (
    <div>
      {is_mobile_view ? (
        <MobileDatatable
          config={config}
          action={handleDatatableAction}
          onClick={handleItemClick}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      ) : (
        <WebDatatable
          config={{
            ...config,
            total_count: total_campaigns
          }}
          action={handleDatatableAction}
          onClick={handleItemClick}
          checkbox
          loading_data={loading}
          table_actions={table_actions}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      )}
    </div>
  );
};
