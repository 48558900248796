import dateFormat from 'dateformat';
/** */
const MONTH_MAP = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];
const NUM_OF_MILLISECONDS_IN_ONE_DAY = 86400000;

/** */
const convertDateFromIsoToHTMLFormat = (iso_date) => {
  const date = new Date(iso_date);
  const day = padDateValue(date.getDate());
  const month = padDateValue(date.getMonth() + 1);
  const year = date.getFullYear();

  const converted_date = `${year}-${month}-${day}`;
  return iso_date ? converted_date : '';
};

/**
 * Extracts day string of the given timestamp for graphs. Return ex. 'Jan 10'
 * @param {Number} timestamp
 */
const extractDayStringForGraph = (timestamp) => {
  const date = new Date(timestamp);
  const month = MONTH_MAP[date.getMonth()];
  const day = padDateValue(date.getDate());

  return `${month} ${day}`;
};

const generateHTMLFormDateTimeDefaults = () => {
  const date = new Date().toISOString();
  const generated_date = `${convertDateFromIsoToHTMLFormat(date)}`;
  return generated_date;
};

/**
 * Generates a configuration of back-counted days starting from a base time.
 *
 * @param {*} base_timestamp The day to start back count
 * @param {*} spread The numbers of days to count back.
 */
const generateDaysConfigurationForGraph = (base_timestamp, spread = 7, key_values) => {
  let curr_timestamp = getDayTimestampForRawTimestamp(base_timestamp);
  const config = {};
  for (let i = 0; i < spread; i++) {
    const date_string = extractDayStringForGraph(curr_timestamp);
    // key_values = [subscribers , unsubscribers]; [opens, bounces]
    config[date_string] = {
      date: date_string,
      [key_values[0]]: 0,
      [key_values[1]]: 0
    };
    curr_timestamp -= NUM_OF_MILLISECONDS_IN_ONE_DAY;
  }
  return config;
};

/**
 * Generates the number of milliseconds at (12:00AM) for the date specified in the raw_timestamp.
 *
 * @param {Number} raw_timestamp number of milliseconds
 */
const getDayTimestampForRawTimestamp = (raw_timestamp) => {
  const date = new Date(raw_timestamp);
  const day = `${padDateValue(date.getMonth() + 1)}/${padDateValue(
    date.getDate()
  )}/${date.getFullYear()}`;
  return Date.parse(day);
};

/**
 * Get the timestamp of the first and last days of the month
 */

const getMonthTimeRange = () => {
  const year = new Date().getFullYear();
  const current_month = new Date().getMonth();
  const start = Date.parse(new Date(year, current_month, 1));
  const end = Date.parse(new Date(year, current_month + 1, 1)) - 1000;

  return { start, end };
};

/**
 * prefixes 0 on numbers with single digits.
 * @param {Number} value
 */
const padDateValue = (value) => {
  return value < 10 ? `0${value}` : value;
};

const isCampaignSendDateValid = (chosen_date) => {
  const today = new Date().toDateString();
  return new Date(`${chosen_date}`) >= new Date(today);
};

const toDateString = (date = '') => {
  if (!date) return '';
  return new Date(date).toDateString();
};

const formatDateWithoutDayOfWeek = (date = '') => {
  if (!date) return '';
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return new Date(date).toLocaleDateString(undefined, options);
};

const formatDateForDisplay = (raw_value) => {
  if (!raw_value) return '';
  return dateFormat(new Date(raw_value), 'dd mmm, yyyy h:MM TT');
};

const toTimeString = (date = '') => {
  if (!date) return '';
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };
  return new Date(date).toLocaleTimeString('en-US', options);
};

const generateDefaultRange = () => {
  const [today] = new Date().toISOString().split('T');
  const [thirty_days_ago] = new Date(Date.parse(today) - 30 * 86400000).toISOString().split('T');
  return [thirty_days_ago, today];
};

/**
 *
 * @param {Number} year
 * @returns {startDate, endDate}
 */
const getYearRange = (year) => {
  const startDate = new Date(year, 0, 1).getTime(); // January 1st
  const endDate = new Date(year, 11, 31).getTime() + 86400000 - 1; // December 31st (23:59:59)
  return { startDate, endDate };
};

const getMonthTimestamps = (year) => {
  const timestamps = [];

  for (let month = 0; month < 12; month++) {
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0, 23, 59, 59, 999); // Last day of the month

    timestamps.push({
      start: startDate.getTime(),
      end: endDate.getTime()
    });
  }

  return timestamps;
};

const getMonthNameFromTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  return months[date.getMonth()];
};

export {
  convertDateFromIsoToHTMLFormat,
  extractDayStringForGraph,
  formatDateForDisplay,
  formatDateWithoutDayOfWeek,
  generateDaysConfigurationForGraph,
  generateHTMLFormDateTimeDefaults,
  getDayTimestampForRawTimestamp,
  generateDefaultRange,
  getMonthTimeRange,
  isCampaignSendDateValid,
  padDateValue,
  toDateString,
  toTimeString,
  getYearRange,
  getMonthTimestamps,
  getMonthNameFromTimestamp
};
