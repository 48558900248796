/** */
import './dashboard.css';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from 'store/actions/header';
import { PageContentWrapper } from 'app/member/layouts/wrapper/page-content';
import { DashboardStatBar } from './components/statbar';
import { Spacer } from 'app/member/layouts/generic';
import { GridColumn, GridRow } from 'app/member/layouts/grid';
import { CampaignPerformance } from '../../../shared/performance/campaign';
import { TransactionalPerformance } from 'app/shared/performance/transactional';
import { ContactPerformance } from 'app/shared/performance/contact';
import { SpendingPerformance } from 'app/shared/performance/spends';

const GMDashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Dashboard', path: '.' }]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContentWrapper>
      <DashboardStatBar />
      <Spacer multiple={4} />
      <GridRow num_of_columns={1}>
        <GridColumn>
          <CampaignPerformance />
        </GridColumn>
      </GridRow>
      <Spacer multiple={4} />
      <GridRow num_of_columns={1}>
        <GridColumn>
          <TransactionalPerformance />
        </GridColumn>
      </GridRow>
      <Spacer multiple={4} />
      <GridRow num_of_columns={1}>
        <GridColumn>
          <ContactPerformance />
        </GridColumn>
      </GridRow>
      <Spacer multiple={4} />
      <GridRow num_of_columns={1}>
        <GridColumn>
          <SpendingPerformance />
        </GridColumn>
      </GridRow>
    </PageContentWrapper>
  );
};

export default GMDashboard;
