import { Icon } from 'assets/icons';
import React from 'react';
import cx from 'classnames';
import styles from './input.module.css';

export const SimpleInput = ({
  type = 'text',
  label = '',
  name = '',
  error = '',
  input_id = '',
  className = '',
  wrapperClassName = '',
  disabled,
  placeholder = '',
  value = '',
  onBlur = () => {},
  onInput = () => {},
  style = {}
}) => {
  return (
    <div className={cx(styles.formGroup, wrapperClassName)}>
      {label && <label htmlFor={input_id}>{label}</label>}
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={() => {}}
        onInput={(e) => (name ? onInput(e.target) : onInput(e.target.value))}
        onBlur={onBlur}
        className={cx(className, { [styles.errorBorder]: error })}
        style={style}
        id={input_id}
        disabled={disabled}
      />
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

export const IconInput = ({
  type = 'text',
  icon_name = 'add',
  label = '',
  input_id = '',
  className = '',
  iconClassName = '',
  wrapperClassName = '',
  disabled,
  placeholder = '',
  value = '',
  onBlur = () => {},
  onInput = () => {},
  onIconClick = () => {},
  style = {},
  icon_position = 'left',
  autoFocus
}) => {
  const noPadding = icon_position === 'left';
  return (
    <div className={cx(styles.iconFormGroup, wrapperClassName)}>
      {label && <label htmlFor={input_id}>{label}</label>}

      {icon_position === 'left' && (
        <span onClick={onIconClick} className={cx(styles.inputIcon, iconClassName)}>
          <Icon name={icon_name} />
        </span>
      )}
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onBlur={onBlur}
        onInput={(e) => onInput(e.target.value)}
        onChange={() => {}}
        className={cx({ [styles.inputIconLeft]: noPadding }, className)}
        style={style}
        id={input_id}
        disabled={disabled}
        autoFocus={autoFocus}
      />
      {icon_position === 'right' && (
        <span onClick={onIconClick} className={styles.inputIcon}>
          <Icon name={icon_name} />
        </span>
      )}
    </div>
  );
};
