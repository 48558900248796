import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PlanListPage } from './list';
import { PlanCreation } from './create';

export const PlansModule = () => {
  return (
    <Switch>
      <Route path="/billings/plans/:id" component={PlanCreation} />
      <Route path="/*" component={PlanListPage} />
    </Switch>
  );
};
