import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { Icon } from 'assets/icons';
import styles from './form-alert.module.css';

export const Alert = ({
  message = '',
  type = 'error' // 'error' | 'info' | 'success'
}) => {
  console.log({ message, type });
  const [show_alert, setShowAlert] = useState(false);
  const [alert_message, setAlertMessage] = useState('');

  useEffect(() => {
    setAlertMessage(message);
    setShowAlert(!!message);
  }, [message]);

  const handleClose = () => {
    setShowAlert(() => false);
    setAlertMessage(() => '');
  };

  return (
    <>
      {show_alert && (
        <div className={cx(styles.alert, styles[type])}>
          <span>{alert_message}</span>
          <span onClick={handleClose} className={styles.closeBtn}>
            <Icon name="close" />
          </span>
        </div>
      )}
    </>
  );
};
