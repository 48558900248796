import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as WalletLogService from 'services/sales/walletlog';

export const useWalletLogService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchWalletLogs = async (options = {}) => {
    const { error, payload } = await WalletLogService.read({ ...options, token });
    if (error) {
      toast.error('Unable to fetch wallet logs at this time.');
      return { walletlogs: [], size: 0 };
    }

    const { data: walletlogs, meta } = payload;
    return { walletlogs, size: meta?.size || 0 };
  };

  return { fetchWalletLogs };
};
