import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as EffectService from 'services/automata/effect';

export const useAutomationEffectService = () => {
  const { token } = useSelector((store) => store.user_data);

  const createEffect = async (data, options = {}) => {
    const { error, payload: effect } = await EffectService.create({
      ...options,
      data,
      token
    });

    if (error) {
      toast.error('Cannot create effect at this time');
      return { evnt: {} };
    }

    toast.error('Effect created successfully.');
    return { effect };
  };

  const deleteEffect = async (id, options = {}) => {
    const { error } = await EffectService.deleteById(id, {
      ...options,
      token
    });

    if (error) {
      toast.error('Unable to delete effect at this time.');
      return false;
    }

    toast.success('Effect deleted successfully');
    return true;
  };

  const fetchEffect = async (id, options = {}) => {
    const { error, payload: effect } = await EffectService.readById({ ...options, token });

    if (error) {
      toast.error(error);
      return { effect: null };
    }

    return { effect };
  };

  const fetchEffects = async (options = {}) => {
    const { error, payload } = await EffectService.read({ ...options, token });

    if (error) {
      toast.error(error);
      return { effects: [], size: 0 };
    }

    const {
      data: effects,
      meta: { size }
    } = payload;
    return { effects, size };
  };

  const seedEffects = async () => {
    const { error } = await EffectService.seed({ token });
    if (error) {
      toast.error('Unable to seed effects at this time.');
      return false;
    }

    toast.success('Effects seeded successfully');
    return true;
  };

  const updateEffect = async (id, options = {}) => {
    const { error } = await EffectService.updateById(id, {
      ...options,
      token
    });

    if (error) {
      toast.error('Unable to update effect at this time.');
      return false;
    }

    toast.success('Effect updated successfully');
    return true;
  };

  return { createEffect, deleteEffect, fetchEffect, fetchEffects, seedEffects, updateEffect };
};
