import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { setPageTitle } from 'store/actions/header';
import SlidingNav from 'app/shared/nav/sliding/sliding';

import { TenantWalletLogs } from './components/walletlogs';
import { TenantPaymentLogs } from './components/payments/payment-logs';
import TenantDashboard from './components/dashboard/dashboard';
import { TenantUpdatePage } from './components/edit';
import { Spacer } from 'app/member/layouts/generic';
import { TenantCampaigns } from './components/campaigns';
import { TenantMailLogs } from './components/maillog';
import { SubscriptionsListPage } from './components/subscription/list';

export const TenantPage = () => {
  const { path, url } = useRouteMatch();
  const dispatch = useDispatch();

  const NAVITEMS = [
    {
      title: 'Overview',
      path: `${url}/dashboard`
    },
    {
      title: 'Campaigns',
      path: `${url}/campaigns`
    },
    {
      title: 'Subscriptions',
      path: `${url}/subscriptions`
    },
    {
      title: 'Email logs',
      path: `${url}/logs/email`
    },
    {
      title: 'Wallet logs',
      path: `${url}/logs/wallet`
    },
    {
      title: 'Payments',
      path: `${url}/logs/payment`
    }
  ];
  const [nav_items, setNavItems] = useState(NAVITEMS);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Tenants', path: '.' }]));

    setNavItems(NAVITEMS);
  }, []);

  return (
    <>
      <header>
        <SlidingNav nav_items={nav_items} />
      </header>
      <Spacer multiple={4} />
      <Switch>
        <Route path={`${path}/edit`} component={TenantUpdatePage} />
        <Route exact path={`${path}/dashboard`} component={TenantDashboard} />
        <Route exact path={`${path}/campaigns`} component={TenantCampaigns} />
        <Route exact path={`${path}/subscriptions`} component={SubscriptionsListPage} />
        <Route exact path={`${path}/logs/email`} component={TenantMailLogs} />
        <Route path={`${path}/logs/wallet`} component={TenantWalletLogs} />
        <Route path={`${path}/logs/payment`} component={TenantPaymentLogs} />
      </Switch>
    </>
  );
};
