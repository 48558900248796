import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';

import { addManyResourcesToStore, addResourceSearchResultsToStore } from 'store/actions/resource';
import { setPageTitle } from 'store/actions/header';
import { useResourceService } from 'hooks/api/iam/resource';
import { truncateText } from 'app/shared/utils/general';
import { Button } from 'app/shared/button';
import GmModal from 'app/shared/modal/modal';
import { CreateResource } from '../create';

export const ResourceListPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { fetchResources, searchResources } = useResourceService();

  const { is_mobile_view } = useSelector((state) => state.metadata);
  const resources_in_store = useSelector((state) => state.resources);

  const [resources, setResources] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show_creation_modal, setShowCreationModal] = useState(false);
  const [total_resources, setTotalResources] = useState(0);

  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'User Management', path: '..' },
        { title: 'Resources', path: '.' }
      ])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setResources(Object.values(resources_in_store));
  }, [resources_in_store]);

  const config = {
    actions: {
      single: () => []
    },
    allow_bulk_action: true,
    css: {},
    fields: [
      {
        title: 'Name',
        key: 'name',
        isTitle: true,
        searchable: true,
        formatter: (value) => truncateText(value, 40)
      },
      {
        title: 'Code',
        key: 'code',
        isTagline: true,
        searchable: true,
        formatter: (value) => value || 'N/A'
      },
      {
        title: 'Modified On',
        key: 'updated_on',
        formatter: (value) => new Date(value).toDateString(),
        isMetadata: true
      }
    ],
    is_search_mode,
    items: resources.sort((a, b) => b.id - a.id),
    search_key: 'name',
    search_text: ''
  };

  const handleDatatableAction = (payload) => {
    const { name, type, data } = payload;

    if (type === 'single') {
      switch (name) {
        default:
          history.push(`/user-management/resources/${data.id}`);
      }
    }
  };

  const handleItemClick = (payload) => {
    const { id } = payload;
    history.push(`/user-management/resources/${id}`);
  };

  const handleDataRequest = async (page, population = 50) => {
    try {
      setLoading(true);
      const { resources, size } = await fetchResources({
        query_string: `page=${page}&population=${population}&sort_by=-created_on`
      });

      dispatch(addManyResourcesToStore(resources));
      setTotalResources(size);
    } catch (e) {
      dispatch(addManyResourcesToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys) return;
    try {
      setLoading(true);
      const { resources, size } = await searchResources(keys, keyword, {
        query_string: `page=${page}&population=${population}&sort_by=-created_on`
      });

      setTotalResources(size);
      if (page === 0) return dispatch(addResourceSearchResultsToStore(resources));
      dispatch(addManyResourcesToStore(resources));
    } catch (e) {
      dispatch(addManyResourcesToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const table_actions = (
    <>
      <Button text="Resource" icon_name="add" onClick={() => setShowCreationModal(true)} />
    </>
  );

  return (
    <div>
      {is_mobile_view ? (
        <MobileDatatable
          config={config}
          action={handleDatatableAction}
          onClick={handleItemClick}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      ) : (
        <WebDatatable
          config={{
            ...config,
            total_count: total_resources
          }}
          action={handleDatatableAction}
          onClick={handleItemClick}
          checkbox
          loading_data={loading}
          table_actions={table_actions}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      )}
      <GmModal
        onClose={() => setShowCreationModal(false)}
        title="Create Resource"
        show_title
        show_modal={show_creation_modal}
      >
        <CreateResource />
      </GmModal>
    </div>
  );
};
