import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';

import { addManyCampaignsToStore, addCampaignSearchResultsToStore } from 'store/actions/campaign';
import { setPageTitle } from 'store/actions/header';
import { Button } from 'app/shared/button';
import styles from '../campaign.module.css';
import { useCampaignService } from 'hooks/api/mailing/campaign';

const ListCampaigns = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { fetchCampaigns, searchCampaigns } = useCampaignService();

  const { is_mobile_view } = useSelector((state) => state.metadata);
  const campaigns_in_store = useSelector((state) => state.campaigns);

  const [campaigns, setCampaigns] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show_test, setShowTest] = useState(false);
  const [total_campaigns, setTotalCampaigns] = useState(0);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'My Campaigns', path: '.' }]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCampaigns(
      Object.values(campaigns_in_store).filter(
        (campaign) => campaign.is_test_campaign === show_test
      )
    );
  }, [campaigns_in_store]);

  useEffect(() => {
    handleDataRequest(0);
  }, [show_test]);

  const config = {
    actions: {
      single: () => [
        {
          value: 'view',
          label: 'View'
        }
      ]
    },
    allow_bulk_action: true,
    css: {},
    fields: [
      {
        title: 'Campaign Name',
        key: 'name',
        isTitle: true,
        searchable: true,
        formatter: (value) => (value?.length > 25 ? `${value.substr(0, 25)}...` : value || '')
      },
      {
        title: 'Sender Email',
        key: 'sender_email',
        isTagline: true,
        searchable: true,
        formatter: (value) => value || 'N/A'
      },
      {
        title: 'Status',
        key: 'status',
        searchable: true,
        formatter: (value) => {
          switch (value) {
            case 'processed':
              return <span className={styles.greenBadge}>{value}</span>;
            case 'queued':
              return <span className={styles.infoBadge}>{value}</span>;
            case 'cancelled':
              return <span className={styles.dangerBadge}>{value}</span>;
            default:
              return <span className={styles.dangerBadge}>{value}</span>;
          }
        },
        isBadge: true
      },
      {
        title: 'Send date',
        key: 'schedule',
        formatter: (value) => {
          if (!value?.date) return '...';
          return new Date(value.date).toDateString();
        },
        isMetadata: true
      }
    ],
    is_search_mode,
    items: campaigns.sort((a, b) => {
      const a_date = a.status === 'draft' ? a.created_on : a.schedule?.date;
      const b_date = b.status === 'draft' ? b.created_on : b.schedule?.date;

      const parsed_a = new Date(a_date).toISOString().replace(/\.\d{3}Z/gi, '.000Z');
      const parsed_b = new Date(b_date).toISOString().replace(/\.\d{3}Z/gi, '.000Z');

      let difference = Date.parse(parsed_b) - Date.parse(parsed_a);
      if (difference === 0) {
        difference = b.id - a.id;
      }

      return difference;
    }),
    search_key: 'name',
    search_text: ''
  };
  const handleDatatableAction = (payload) => {
    const { name, type, data } = payload;

    if (type === 'single') {
      switch (name) {
        default:
          history.push(`/campaigns/${data.id}`);
      }
    }
  };

  const handleItemClick = (payload) => {
    const { id } = payload;
    history.push(`/campaigns/${id}`);
  };

  const handleDataRequest = async (page = 0, population = 50) => {
    try {
      setLoading(true);
      const query = show_test ? 'bool=is_test_campaign' : 'bool=-is_test_campaign';
      const { campaigns, size } = await fetchCampaigns({
        query_string: `page=${page}&population=${population}&sort_by=-created_on&return_only=-template&${query}`
      });

      dispatch(addManyCampaignsToStore(campaigns));
      setTotalCampaigns(size);
    } catch (e) {
      dispatch(addManyCampaignsToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const handleSearchRequest = async (keys, keyword, page = 0, population = 50) => {
    if (!keys) return;
    try {
      setLoading(true);
      const query = show_test ? 'bool=is_test_campaign' : 'bool=-is_test_campaign';
      const { campaigns, size } = await searchCampaigns(keys, keyword, {
        query_string: `page=${page}&population=${population}&sort_by=-created_on&return_only=-template&${query}`
      });

      setTotalCampaigns(size);
      if (page === 0) return dispatch(addCampaignSearchResultsToStore(campaigns));
      dispatch(addManyCampaignsToStore(campaigns));
    } catch (e) {
      dispatch(addManyCampaignsToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const table_actions = (
    <>
      <Button
        icon_name={show_test ? 'hide' : 'show'}
        text={`${show_test ? 'Hide' : 'Show'} test campaigns`}
        onClick={() => setShowTest((show) => !show)}
      />
      <Button icon_name="add" text="Campaign" onClick={() => history.push('/editor/campaign/')} />
    </>
  );

  return (
    <div>
      {is_mobile_view ? (
        <MobileDatatable
          config={config}
          action={handleDatatableAction}
          onClick={handleItemClick}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      ) : (
        <WebDatatable
          config={{
            ...config,
            total_count: total_campaigns
          }}
          action={handleDatatableAction}
          onClick={handleItemClick}
          checkbox
          loading_data={loading}
          table_actions={table_actions}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      )}
    </div>
  );
};

export default ListCampaigns;
