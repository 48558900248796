/** */
export const READ_MANY_RESOURCES = 'read multiple resources.';
export const READ_ONE_RESOURCE = 'read one resource.';

export const ADD_ONE_RESOURCE_TO_STORE = 'add one resource to store.';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add resource search results to store.';
export const ADD_MANY_RESOURCES_TO_STORE = 'add multiple resources to store.';
export const LOAD_RESOURCES_TO_STORE = 'load up resources to store.';
export const REM_ONE_RESOURCE_FROM_STORE = 'remove a resource from the store.';

/** */
export const readManyResources = (payload) => ({
  type: READ_MANY_RESOURCES,
  payload
});

export const readOneResource = (payload) => ({
  type: READ_ONE_RESOURCE,
  payload
});

export const addManyResourcesToStore = (payload) => ({
  type: ADD_MANY_RESOURCES_TO_STORE,
  payload
});

export const addOneResourceToStore = (payload) => ({
  type: ADD_ONE_RESOURCE_TO_STORE,
  payload
});

export const addResourceSearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const loadResourcesToStore = (payload) => ({
  type: LOAD_RESOURCES_TO_STORE,
  payload
});

export const removeOneResourceFromStore = (payload) => ({
  type: REM_ONE_RESOURCE_FROM_STORE,
  payload
});
