import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addManyTransactionalsToStore,
  addTransactionalSearchResults
} from 'store/actions/transactional';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import { useTransactionalService } from 'hooks/api/mailing/transactional';

const config = (items = []) => ({
  actions: {
    single: () => [
      {
        value: 'view',
        label: 'View'
      }
    ]
  },
  allow_bulk_action: true,
  css: {},
  fields: [
    {
      title: 'Title',
      key: 'name',
      isTitle: true,
      searchable: true,
      formatter: (value) => (value?.length > 25 ? `${value.substr(0, 25)}...` : value || '')
    },
    {
      title: 'Sender Email',
      key: 'sender_email',
      isTagline: true,
      searchable: true,
      formatter: (value) => value || 'N/A'
    },
    {
      title: 'Sender Name',
      key: 'sender_name',
      isTagline: true,
      searchable: true,
      formatter: (value) => value || 'N/A'
    },
    {
      title: 'Template Code',
      key: 'code',
      isTagline: true,
      searchable: true,
      formatter: (value) => value || 'N/A'
    }
  ],
  items: items.sort((a, b) => b.id - a.id),
  search_key: 'title',
  search_text: ''
});

export const TransactionalListPage = () => {
  const dispatch = useDispatch();
  const { fetchTransactionals, searchTransactionals } = useTransactionalService();

  const { is_mobile_view } = useSelector((state) => state.metadata);
  const transactionals_in_store = useSelector((state) => state.transactionals);

  const [transactionals, setTransactionals] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transactional_count, setTransactionalCount] = useState(0);

  useEffect(() => {
    setTransactionals(() => Object.values(transactionals_in_store));
  }, [transactionals_in_store]);

  const handleDatatableAction = () => {};

  const handleItemClick = () => {};

  const handleDataRequest = async (page, population = 50) => {
    try {
      setLoading(true);
      const { transactionals, size } = await fetchTransactionals({
        query_string: `page=${page}&population=${population}&sort_by=-created_on&return_only=id,name,sender_name,sender_email,code,created_on`
      });

      dispatch(addManyTransactionalsToStore(transactionals));
      setTransactionalCount(size);
    } catch (e) {
      dispatch(addManyTransactionalsToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys) return;
    try {
      setLoading(true);
      const { transactionals, size } = await searchTransactionals(keys, keyword, {
        query_string: `page=${page}&population=${population}&sort_by=-created_on&return_only=id,name,sender_name,sender_email,code,created_on`
      });

      setTransactionalCount(size);
      if (page === 0) return dispatch(addTransactionalSearchResults(transactionals));
      dispatch(addManyTransactionalsToStore(transactionals));
    } catch (e) {
      dispatch(addManyTransactionalsToStore([]));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {is_mobile_view ? (
        <MobileDatatable
          config={{ ...config(transactionals), is_search_mode }}
          action={handleDatatableAction}
          onClick={handleItemClick}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      ) : (
        <WebDatatable
          config={{
            ...config(transactionals),
            is_search_mode,
            total_count: transactional_count
          }}
          action={handleDatatableAction}
          onClick={handleItemClick}
          checkbox
          loading_data={loading}
          table_actions={[]}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      )}
    </div>
  );
};
