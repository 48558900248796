import { processDateRange, TimeSeriesChart } from 'app/shared/charts/timeseries';
import { useMailActionService } from 'hooks/api/mailing/mailaction';
import { useMailLogService } from 'hooks/api/mailing/maillog';
import { useNumbers } from 'hooks/ui/general/numbers';
import React, { useEffect, useState } from 'react';
import { generateDefaultRange } from '../utils/date';

const chart_key_filters = [
  {
    label: 'Sends',
    value: 'sends',
    default: true,
    color: 'var(--blue-primary)'
  },
  {
    label: 'Bounces',
    value: 'bounces',
    color: 'var(--orange-primary)'
  },
  {
    label: 'Opens',
    value: 'opens',
    default: true,
    color: 'var(--neutral-dark-5)'
  }
];

export const CampaignPerformance = ({ tenant_id }) => {
  const { fetchMailActions } = useMailActionService();
  const { fetchMailLogs } = useMailLogService();
  const { toReadableNumber } = useNumbers();

  const [date_range, setDateRange] = useState([]);
  const [email_performance_data, setEmailPerformanceData] = useState({});
  const [is_loading_email_performance_data, setIsLoadingEmailPerformanceData] = useState(true);

  useEffect(() => {
    const [thirty_days_ago, today] = generateDefaultRange();
    const ranges = processDateRange(thirty_days_ago, today);
    setDateRange(() => ranges);
  }, []);

  useEffect(() => {
    handleEmailPerformanceDateRangeChange(date_range);
  }, [date_range]);

  const handleEmailPerformanceDateRangeChange = async (ranges = []) => {
    const day_map = ranges.reduce(
      (sac, { label }) => ({
        ...sac,
        [label]: { day: label }
      }),
      {}
    );

    setEmailPerformanceData(() => day_map);
    setIsLoadingEmailPerformanceData(true);

    const id_query = tenant_id ? `&tenant_id=${tenant_id}` : '';
    for (const range of ranges) {
      const { start, end, label } = range;
      const [{ size: sends }, { size: bounces }, { size: opens }] = await Promise.all([
        fetchMailLogs({
          query_string: `time_stamp=${start}~${end}&count=1&class=campaign${id_query}`
        }),
        fetchMailLogs({
          query_string: `time_stamp=${start}~${end}&count=1&class=campaign&status=bounced${id_query}`
        }),
        fetchMailActions({
          query_string: `time_stamp=${start}~${end}&resource_type=campaign&action=opened&count=1${id_query}`
        })
      ]);

      setEmailPerformanceData((curr_data) => ({
        ...curr_data,
        [label]: {
          ...curr_data[label],
          sends: toReadableNumber(sends),
          bounces: toReadableNumber(bounces),
          opens: toReadableNumber(opens)
        }
      }));
    }
    setIsLoadingEmailPerformanceData(false);
  };

  return (
    <TimeSeriesChart
      graph_title="Campaign Performance"
      data={Object.values(email_performance_data)}
      filter_data={[]}
      key_filters={chart_key_filters}
      is_loading_data={is_loading_email_performance_data}
      onDateRangeChange={setDateRange}
    />
  );
};
