import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';

import {
  addManyServersToStore,
  addOneServerToStore,
  addServerSearchResultsToStore,
  removeOneServerFromStore
} from 'store/actions/server';
import { setPageTitle } from 'store/actions/header';
import { Button } from 'app/shared/button';
import styles from '../payment.module.css';
import GmModal from 'app/shared/modal/modal';
import CreateServer from '../create/create';
import { useServerService } from 'hooks/api/postal/server';
import { ImportServer } from '../import';

const ListPayment = () => {
  const dispatch = useDispatch();
  const { deleteServer, fetchServers, searchServers, updateServer } = useServerService();

  const { is_mobile_view } = useSelector((state) => state.metadata);
  const servers_in_store = useSelector((state) => state.servers);

  const [servers, setServers] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading_search, setLoadingSearch] = useState(false);
  const [show_server_creation_modal, setShowServerCreationModal] = useState(false);
  const [show_import_modal, setShowImportModal] = useState(false);

  const [total_servers, setTotalServers] = useState(0);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Email Servers', path: '.' }]));
    handleDataRequest(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setServers(Object.values(servers_in_store));
  }, [servers_in_store]);

  const config = {
    actions: {
      single: (server) => [
        {
          label: 'Activate',
          value: 'activate',
          hidden: server.status === 'up'
        },
        {
          label: 'Deactivate',
          value: 'deactivate',
          hidden: server.status === 'down'
        },
        {
          label: 'Delete',
          value: 'delete'
        }
      ]
    },
    allow_bulk_action: false,
    css: {},
    fields: [
      {
        title: 'Domain',
        key: 'ip_address',
        searchable: true,
        isTitle: true
      },
      {
        title: 'Class',
        key: 'class',
        isTagline: true
      },
      {
        title: 'Queue Class',
        key: 'queue_class',
        isTagline: true
      },
      {
        title: 'ESP',
        key: 'provider',
        searchable: true,
        isTagline: true
      },
      {
        title: 'Status',
        key: 'status',
        searchable: true,
        formatter: (value) => {
          switch (value) {
            case 'up':
              return <span className={styles.greenBadge}>{value}</span>;
            default:
              return <span className={styles.dangerBadge}>{value}</span>;
          }
        },
        isBadge: true
      },
      {
        title: 'date',
        key: 'created_on',
        formatter: (value) => new Date(value).toDateString(),
        isMetadata: true
      }
    ],
    is_search_mode,
    items: servers,
    search_key: 'ip_address,class,provider,status',
    search_text: ''
  };

  const toggleServerStatus = async (id, server_data) => {
    const data = {
      status: server_data.status === 'up' ? 'down' : 'up'
    };

    const result = await updateServer(id, { data });
    if (result) dispatch(addOneServerToStore({ ...server_data, ...data }));
  };

  const handleDatatableAction = (payload) => {
    const { name, type, data } = payload;

    if (type === 'single') {
      switch (name) {
        case 'activate':
        case 'deactivate':
          toggleServerStatus(data.id, data);
          break;
        case 'delete':
          return handleDeletion(data.id);
        default:
          break;
      }
    }
  };

  const handleDeletion = async (server_id) => {
    const result = await deleteServer(server_id);
    if (result) return dispatch(removeOneServerFromStore(server_id));
  };

  const handleDataRequest = async (page) => {
    try {
      setLoading(true);
      const { servers, size } = await fetchServers({
        query_string: `page=${page}&population=50&sort_by=-created_on`
      });
      dispatch(addManyServersToStore(servers));
      setTotalServers(size);
    } catch (e) {
      dispatch(addManyServersToStore([]));
    }
  };

  const handleSearchRequest = async (keys, keyword, page) => {
    if (!keys) return;
    setLoadingSearch(true);
    try {
      const { servers, size } = await searchServers(keys, keyword, {
        query_string: `page=${page}&population=50&sort_by=-created_on`
      });

      dispatch(addServerSearchResultsToStore(servers));
      setTotalServers(size);
    } catch (e) {
      dispatch(addManyServersToStore([]));
    } finally {
      setLoadingSearch(false);
    }
  };

  const table_actions = (
    <>
      <Button icon_name="add" text="Server" onClick={() => setShowServerCreationModal(true)} />
      <Button icon_name="upload" text="Upload Servers" onClick={() => setShowImportModal(true)} />
    </>
  );

  return (
    <div>
      {is_mobile_view ? (
        <MobileDatatable
          config={config}
          action={handleDatatableAction}
          onClick={() => {}}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      ) : (
        <WebDatatable
          config={{
            ...config,
            total_count: total_servers
          }}
          action={handleDatatableAction}
          onClick={() => {}}
          checkbox
          request_complete={!loading}
          loadingSearch={loading_search}
          table_actions={table_actions}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      )}

      <GmModal
        title="Create Server"
        show_title={true}
        show_modal={show_server_creation_modal}
        onClose={() => setShowServerCreationModal(false)}
      >
        <CreateServer />
      </GmModal>
      <GmModal
        title="Import Server"
        show_title={true}
        show_modal={show_import_modal}
        onClose={() => setShowImportModal(false)}
      >
        <ImportServer />
      </GmModal>
    </div>
  );
};

export default ListPayment;
