import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';

import {
  addManyPermissionsToStore,
  addPermissionSearchResultsToStore
} from 'store/actions/permission';
import { setPageTitle } from 'store/actions/header';
import { usePermissionService } from 'hooks/api/iam/permission';
import { truncateText } from 'app/shared/utils/general';
import GmModal from 'app/shared/modal/modal';
import { Button } from 'app/shared/button';
import { CreatePermission } from '../create';

export const PermissionListPage = () => {
  const dispatch = useDispatch();
  const { fetchPermissions, searchPermissions } = usePermissionService();

  const { is_mobile_view } = useSelector((state) => state.metadata);
  const permissions_in_store = useSelector((state) => state.permissions);

  const [permissions, setPermissions] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data_to_edit, setDataToEdit] = useState({});
  const [show_creation_modal, setShowCreationModal] = useState(false);
  const [total_permissions, setTotalPermissions] = useState(0);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Permissions', path: '.' }]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPermissions(Object.values(permissions_in_store));
  }, [permissions_in_store]);

  const config = {
    actions: {
      single: () => [
        {
          value: 'edit',
          label: 'Edit'
        }
      ]
    },
    allow_bulk_action: true,
    css: {},
    fields: [
      {
        title: 'Name',
        key: 'name',
        isTitle: true,
        searchable: true,
        formatter: (value) => truncateText(value, 40)
      },
      {
        title: 'Code',
        key: 'code',
        isTagline: true,
        searchable: true,
        formatter: (value) => value || 'N/A'
      },
      {
        title: 'Resource',
        key: 'resource',
        searchable: true,
        formatter: (value) => value || 'N/A',
        isBadge: true
      },
      {
        title: 'Modified On',
        key: 'updated_on',
        formatter: (value) => new Date(value).toDateString(),
        isMetadata: true
      }
    ],
    is_search_mode,
    items: permissions.sort((a, b) => b.id - a.id),
    search_key: 'name',
    search_text: ''
  };

  const handleDatatableAction = (payload) => {
    const { name, type, data } = payload;

    if (type === 'single') {
      switch (name) {
        case 'edit':
          setDataToEdit(() => data);
          setShowCreationModal(() => true);
          break;
        default:
      }
    }
  };

  const handleItemClick = (payload) => {};

  const handleDataRequest = async (page, population = 50) => {
    try {
      setLoading(true);
      const { permissions, size } = await fetchPermissions({
        query_string: `page=${page}&population=${population}&sort_by=-created_on`
      });

      dispatch(addManyPermissionsToStore(permissions));
      setTotalPermissions(size);
    } catch (e) {
      dispatch(addManyPermissionsToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys) return;
    try {
      setLoading(true);
      const { permissions, size } = await searchPermissions(keys, keyword, {
        query_string: `page=${page}&population=${population}&sort_by=-created_on`
      });

      setTotalPermissions(size);
      if (page === 0) return dispatch(addPermissionSearchResultsToStore(permissions));
      dispatch(addManyPermissionsToStore(permissions));
    } catch (e) {
      dispatch(addManyPermissionsToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const table_actions = (
    <>
      <Button icon_name="add" text="Permission" onClick={() => setShowCreationModal(true)} />
    </>
  );

  return (
    <div>
      {is_mobile_view ? (
        <MobileDatatable
          config={config}
          action={handleDatatableAction}
          onClick={handleItemClick}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      ) : (
        <WebDatatable
          config={{
            ...config,
            total_count: total_permissions
          }}
          action={handleDatatableAction}
          onClick={handleItemClick}
          checkbox
          loading_data={loading}
          table_actions={table_actions}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      )}
      <GmModal
        onClose={() => setShowCreationModal(false)}
        show_modal={show_creation_modal}
        title="Create Permission"
        show_title
      >
        <CreatePermission data={data_to_edit} />
      </GmModal>
    </div>
  );
};
