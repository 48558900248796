import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'app/shared/button';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { DetailItem } from 'app/shared/info-section/item';
import { SimpleInput } from 'app/shared/input';
import { TextArea } from 'app/shared/input/textarea';
import { createPlanValidationConfig } from 'app/shared/utils/form/validation/plan';
import { usePlanService } from 'hooks/api/sales/plan';
import useValidator from 'hooks/validation';
import styles from './create.module.css';

export const CreatePlanForm = ({ plan = {}, data }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { createPlan, updatePlan } = usePlanService();
  const { errors, validateField, form_is_valid } = useValidator(createPlanValidationConfig);
  const [basic_info, setBasicInfo] = useState({
    code: '',
    contact_bracket: '',
    features: '',
    title: '',
    tenant_ids: ''
  });

  useEffect(() => {
    if (Object.keys(plan).length) {
      const info = {
        code: plan.code || '',
        contact_bracket: plan.contact_bracket || '',
        features: plan.features ? plan.features.join(', ') : '',
        title: plan.title || '',
        tenant_ids: (plan.tenant_ids || []).join(', ')
      };

      setBasicInfo(info);
      Object.keys(info).forEach((key) => handleBasicInfoChange(key, info[key]));
    }
  }, [plan]);

  const submitForm = async () => {
    const { resources, prices, discounts } = data;
    const resources_items = resources.map((res) => ({ name: res.field.value, limit: res.value }));
    const discount_items = discounts.reduce(
      (acc, res) => ({ ...acc, [res.field.value]: res.value }),
      {}
    );
    const prices_items = prices.map((res) => ({ currency: res.field.value, amount: res.value }));
    const payload = {
      ...basic_info,
      tenant_ids: basic_info.tenant_ids ? basic_info.tenant_ids.split(',').map(Number) : [],
      features: [basic_info.features],
      resources: resources_items,
      prices: prices_items,
      discounts: discount_items
    };

    setLoading(() => true);
    let result = {};
    if (plan.id) {
      result = await updatePlan(plan.id, { data: payload });
    } else {
      result = await createPlan({ data: payload });
    }

    if (result.plan) {
      history.push(`/billings/plans`);
    }
    setLoading(() => false);
  };

  const handleBasicInfoChange = (key, value) => {
    validateField(key, value);
    setBasicInfo((curr_info) => ({
      ...curr_info,
      [key]: value
    }));
  };

  return (
    <BasicInfoSection title="Basic Details">
      <DetailItem title="Title">
        <SimpleInput
          input_id="title"
          value={basic_info.title}
          onInput={(value) => handleBasicInfoChange('title', value)}
          error={errors.title}
          required
        />
      </DetailItem>
      <DetailItem title="Code">
        <SimpleInput
          input_id="code"
          value={basic_info.code}
          onInput={(value) => handleBasicInfoChange('code', value)}
          error={errors.code}
          required
        />
      </DetailItem>
      <DetailItem title="Tenants">
        <SimpleInput
          input_id="tenants"
          value={basic_info.tenant_ids}
          onInput={(value) => handleBasicInfoChange('tenant_ids', value)}
        />
      </DetailItem>
      <DetailItem title="Features">
        <TextArea
          className={styles.textarea}
          input_id="features"
          name="features"
          value={basic_info.features}
          onInput={(value) => handleBasicInfoChange('features', value)}
          rows={4}
          error={errors.features}
          required={true}
        />
      </DetailItem>
      <DetailItem title="CONTACT Bracket">
        <SimpleInput
          input_id="contact_bracket"
          value={basic_info.contact_bracket}
          onInput={(value) => handleBasicInfoChange('contact_bracket', value)}
          error={errors.contact_bracket}
          required
        />
      </DetailItem>
      <Button onClick={submitForm} text="Save" loading={loading} disabled={!form_is_valid} />
    </BasicInfoSection>
  );
};
