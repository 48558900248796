/** */
export const ADD_ONE_REFERRAL_TO_STORE = 'add one referral to store';
export const ADD_MANY_REFERRALS_TO_STORE = 'add many referrals to store';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add referrals search results to store';
export const LOAD_REFERRALS_TO_STORE = 'load referrals to store from API call';
export const REMOVE_MANY_REFERRALS_FROM_STORE = 'remove many referrals from the store.';
export const REMOVE_ONE_REFERRAL_FROM_STORE = 'remove a referral from the store.';

/** */

export const addManyReferralsToStore = (payload) => ({
  type: ADD_MANY_REFERRALS_TO_STORE,
  payload
});

export const addOneReferralToStore = (payload) => ({
  type: ADD_ONE_REFERRAL_TO_STORE,
  payload
});

export const addReferralSearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const loadReferralsToStore = (payload) => ({
  type: LOAD_REFERRALS_TO_STORE,
  payload
});

export const removeManyReferralsFromStore = (payload) => ({
  type: REMOVE_MANY_REFERRALS_FROM_STORE,
  payload
});

export const removeOneReferralFromStore = (payload) => ({
  type: REMOVE_ONE_REFERRAL_FROM_STORE,
  payload
});
