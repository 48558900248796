import React from 'react';
import styles from './info-section.module.css';

export const DetailItem = ({ title = '', required = false, children }) => {
  return (
    <div className={styles.item}>
      <p>
        {title} {required && <span className={styles.required}>*</span>}
      </p>
      {children}
    </div>
  );
};
