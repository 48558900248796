import '../dashboard/dashboard.css';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import { useContactService } from 'hooks/api/users/contact';
import { useMailLogService } from 'hooks/api/mailing/maillog';
import { truncateText } from 'app/shared/utils/general';
import { formatDateForDisplay } from 'app/shared/utils/date';

export const TenantMailLogs = () => {
  const { id: tenant_id } = useParams();
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const { fetchContacts, searchContact } = useContactService();
  const { fetchMailLogs } = useMailLogService();

  const [logs, setLogs] = useState({});
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total_logs, setTotalLogs] = useState(0);

  const config = {
    actions: { single: () => [] },
    css: {},
    fields: [
      {
        title: 'First name',
        key: 'recipient',
        formatter: (value) => (value ? value.firstname : 'N/A'),
        isTitle: true
      },
      {
        title: 'Last name',
        key: 'recipient',
        formatter: (value) => (value ? value.lastname : 'N/A'),
        isTitle: true
      },
      {
        title: 'Email address',
        key: 'recipient',
        isTagline: true,
        formatter: (value) => truncateText(value?.email, 25) || 'N/A'
      },
      {
        title: 'Gender',
        key: 'recipient',
        formatter: (value) => (value ? value.gender : 'N/A')
      },
      {
        title: 'Sent at',
        key: 'time_stamp',
        isMetadata: true,
        formatter: (value) => formatDateForDisplay(value)
      },
      {
        title: 'Type',
        key: 'class',
        formatter: (value) => {
          switch (value) {
            case 'campaign':
              return (
                <span className="infoBadge" style={{ backgroundColor: 'var(--neutral-dark-3)' }}>
                  {value}
                </span>
              );
            default:
              return (
                <span className="greenBadge" style={{ backgroundColor: 'var(--blue-primary)' }}>
                  {value}
                </span>
              );
          }
        }
      }
    ],
    is_search_mode,
    items: Object.values(logs).sort((a, b) => b.time_stamp - a.time_stamp),
    search_key: 'name',
    search_text: ''
  };

  const handleDataRequest = async (page, population = 50) => {
    if (loading) return;
    setLoading(true);

    const { maillogs, size } = await fetchMailLogs({
      query_string: `tenant_id=${tenant_id}&page=${page}&population=${population}&sort_by=-created_on`
    });

    const contact_ids = maillogs.map((action) => action.recipient_id);
    const { contacts } = await fetchContacts({ query_string: `id=${contact_ids.join()}` });

    const contacts_by_id = contacts.reduce((s, c) => ({ ...s, [c.id]: c }), {});

    const logs = maillogs.map((log) => ({
      ...log,
      recipient: contacts_by_id[log.recipient_id]
    }));

    setLogs((curr_log) => ({
      ...curr_log,
      ...logs.reduce((s, a) => ({ ...s, [a.time_stamp]: a }), {})
    }));
    setTotalLogs(() => size);

    setLoading(false);
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys || !keyword) {
      setSearchMode(false);
      await handleDataRequest(page, population);
      return;
    }
    setLoading(true);
    try {
      const { contacts } = await searchContact(keys, keyword, {
        query_string: `page=0&population=${500}&return_only=id,firstname, lastname, email,gender`
      });

      if (contacts.length) {
        const contacts_by_id = contacts.reduce((s, c) => ({ ...s, [c.id]: c }), {});
        const contact_ids = contacts.map((c) => c.id);
        const { maillogs, size } = await fetchMailLogs({
          query_string: `tenant_id=${tenant_id}&recipient_id=${contact_ids.join()}&page=${page}&population=${population}&sort_by=-created_on`
        });

        const logs = maillogs.map((log) => ({
          ...log,
          recipient: contacts_by_id[log.recipient_id]
        }));

        if (is_search_mode) {
          setLogs((curr_log) => ({
            ...curr_log,
            ...logs.reduce((s, a) => ({ ...s, [a.time_stamp]: a }), {})
          }));
        } else {
          setLogs(() => ({
            ...logs.reduce((s, a) => ({ ...s, [a.time_stamp]: a }), {})
          }));
          setSearchMode(true);
        }
        setTotalLogs(() => size);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {is_mobile_view ? (
        <MobileDatatable
          config={config}
          action={() => {}}
          onClick={() => {}}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
          showHeader
        />
      ) : (
        <WebDatatable
          config={{
            ...config,
            total_count: total_logs
          }}
          action={() => {}}
          onClick={() => {}}
          checkbox
          loading_data={loading}
          table_actions={[]}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      )}
    </>
  );
};
