/** */
export const READ_MANY_SERVERS = 'read multiple servers.';
export const READ_ONE_SERVER = 'read one server.';

export const ADD_ONE_SERVER_TO_STORE = 'add one server to store.';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add server search results to store.';
export const ADD_MANY_SERVERS_TO_STORE = 'add multiple servers to store.';
export const LOAD_SERVERS_TO_STORE = 'load up servers to store.';
export const REM_ONE_SERVER_FROM_STORE = 'remove a server from the store.';

/** */
export const readManyServers = (payload) => ({
  type: READ_MANY_SERVERS,
  payload
});

export const readOneServer = (payload) => ({
  type: READ_ONE_SERVER,
  payload
});

export const addManyServersToStore = (payload) => ({
  type: ADD_MANY_SERVERS_TO_STORE,
  payload
});

export const addOneServerToStore = (payload) => ({
  type: ADD_ONE_SERVER_TO_STORE,
  payload
});

export const addServerSearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const loadServersToStore = (payload) => ({
  type: LOAD_SERVERS_TO_STORE,
  payload
});

export const removeOneServerFromStore = (payload) => ({
  type: REM_ONE_SERVER_FROM_STORE,
  payload
});
