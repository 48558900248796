/** */
import { urls } from 'services/_url';
import { apiPost } from 'services/_http';

/** */
const base_url = `${urls.users}/admins`;

export const login = async (options = {}) => {
  return apiPost(`${base_url}/login`, { ...options });
};

export const resetPassword = async (options = {}) => {
  return apiPost(`${base_url}/password/reset`, { ...options });
};

export const signup = async (options = {}) => {
  return apiPost(`${base_url}/register`, { ...options });
};
