import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as ContactService from 'services/users/contact';

export const useContactService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchContacts = async (options = {}) => {
    const { error, payload } = await ContactService.read({ ...options, token });
    if (error) {
      toast.error('Unable to fetch contacts at this time.');
      return { contacts: [], size: 0 };
    }

    const {
      data: contacts,
      meta: { size }
    } = payload;
    return { contacts, size };
  };

  const searchContact = async (keys, keyword, options = {}) => {
    const { error, payload } = await ContactService.search(keys, keyword, { ...options, token });
    if (error) {
      toast.error('Unable to fetch contacts at this time.');
      return { contacts: [], size: 0 };
    }

    const {
      data: contacts,
      meta: { size }
    } = payload;
    return { contacts, size };
  };

  return {
    fetchContacts,
    searchContact
  };
};
