/** */
import { urls } from 'services/_url';
import { apiGet } from 'services/_http';

/** */
const base_url = `${urls.mailing}/maillogs`;

export const read = async (options = {}) => {
  return await apiGet(`${base_url}`, { ...options });
};

export const readById = async (id, options = {}) => {
  return await apiGet(`${base_url}/${id}`, { ...options });
};
