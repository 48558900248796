import { table_config } from './helper';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import WebDataTable from 'app/shared/datatable/web/datatable';

import {
  addManyTutorialsToStore,
  addTutorialSearchResultsToStore,
  removeOneTutorialFromStore
} from 'store/actions/tutorial';
import { setPageTitle } from 'store/actions/header';
import GmModal from 'app/shared/modal/modal';
import styles from './list.module.css';
import ConfirmationDialog from 'app/shared/dialogs/confirmation';
import { useTutorialService } from 'hooks/api/files/tutorial';
import { TutorialCreation } from '../create';
import { Button } from 'app/shared/button';

export const TutotialListPage = () => {
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const tutorials_in_store = useSelector((state) => state.tutorials);

  const dispatch = useDispatch();
  const { deleteTutorial, fetchTutorials, searchTutorials } = useTutorialService();

  const [items, setItems] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [tutorial_data, setTutorialData] = useState({});
  const [loading, setLoading] = useState(false);
  const [tutorial_count, setTutorialCount] = useState(0);

  const [selected_tutorial_id, setSelectedTutorialId] = useState(0);
  const [show_creation_modal, setShowCreationModal] = useState(false);
  const [show_deletion_dialog, setShowDeletionDialog] = useState(false);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Tutorials', path: '.' }]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setItems(Object.values(tutorials_in_store));
  }, [tutorials_in_store]);

  const handleDatatableAction = (action) => {
    const { name, type, data } = action;

    if (type.toLowerCase() === 'single') {
      switch (name.toLowerCase()) {
        case 'edit':
          setTutorialData(() => data);
          setShowCreationModal(() => true);
          break;
        case 'delete':
          setSelectedTutorialId(() => data.id);
          setShowDeletionDialog(true);
          break;
        default:
      }
    }
  };

  const handleItemClick = (data) => {};

  const handleDataRequest = async (page) => {
    try {
      const { tutorials, size } = await fetchTutorials({
        query_string: `sort_by=-created_on&page=${page}&population=50`
      });

      setTutorialCount(size);
      dispatch(addManyTutorialsToStore(tutorials));
    } catch (e) {
      dispatch(addManyTutorialsToStore([]));
    }
  };

  const handleSearchRequest = async (keys, keyword, page) => {
    if (!keys) return;
    try {
      setLoading(true);
      const { tutorials, size } = await searchTutorials(keys, keyword, {
        query_string: `sort_by=-created_on&page=${page}&population=50`
      });

      if (page === 0) {
        return dispatch(addTutorialSearchResultsToStore(tutorials));
      }

      dispatch(addManyTutorialsToStore(tutorials));
      setTutorialCount(size);
    } catch (e) {
      dispatch(addManyTutorialsToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const handleSingleDeletion = async (permitted) => {
    if (permitted) {
      const result = await deleteTutorial(selected_tutorial_id);

      if (result) dispatch(removeOneTutorialFromStore(selected_tutorial_id));
    }

    setShowDeletionDialog(false);
  };

  const table_actions = (
    <>
      <Button icon_name="add" text="Tutorial" onClick={() => setShowCreationModal(true)} />
    </>
  );

  return (
    <div>
      {is_mobile_view ? (
        <MobileDatatable
          config={{
            ...table_config,
            is_search_mode,
            items,
            total_count: tutorial_count
          }}
          action={handleDatatableAction}
          onClick={handleItemClick}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      ) : (
        <div>
          <WebDataTable
            config={{
              ...table_config,
              is_search_mode,
              items,
              total_count: tutorial_count
            }}
            table_actions={table_actions}
            action={handleDatatableAction}
            onClick={handleItemClick}
            checkbox
            loading_data={loading}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
          />
        </div>
      )}

      <ConfirmationDialog
        title="Delete Tutorial"
        message="Are you sure you want to delete this tutorial?"
        callback={handleSingleDeletion}
        is_open={show_deletion_dialog}
      />
      <GmModal
        title="Create Tutorial"
        show_title
        show_modal={show_creation_modal}
        onClose={() => setShowCreationModal(false)}
        bodyClassName={styles.topupModal}
      >
        <TutorialCreation tutorial_data={tutorial_data} />
      </GmModal>
    </div>
  );
};
