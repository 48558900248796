/** */
import {
  ADD_MANY_CURRENCY_TO_STORE,
  REMOVE_ONE_CURRENCY_FROM_STORE,
  REMOVE_MANY_CURRENCY_FROM_STORE,
  ADD_ONE_CURRENCY_TO_STORE,
  ADD_SEARCH_RESULTS_TO_STORE
} from '../actions/currency';

export default (state = {}, action) => {
  const { type, payload } = action;
  let previous_state;
  switch (type) {
    case ADD_MANY_CURRENCY_TO_STORE:
      return {
        ...state,
        ...payload.reduce(
          (currencies, currency) => ({
            ...currencies,
            [currency.id]: currency
          }),
          {}
        )
      };
    case REMOVE_MANY_CURRENCY_FROM_STORE:
      previous_state = { ...state };
      payload.forEach((id) => delete previous_state[id]);
      return {
        ...previous_state
      };
    case REMOVE_ONE_CURRENCY_FROM_STORE:
      previous_state = { ...state };
      delete previous_state[payload];
      return {
        ...previous_state
      };
    case ADD_ONE_CURRENCY_TO_STORE:
      return {
        ...state,
        [payload.id]: payload
      };
    case ADD_SEARCH_RESULTS_TO_STORE:
      return {
        ...payload.reduce(
          (currencies, currency) => ({
            ...currencies,
            [currency.id]: currency
          }),
          {}
        )
      };
    default:
      return state;
  }
};
