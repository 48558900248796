export const createPlanValidationConfig = {
  title: {
    required: true,
    pattern: /^[a-zA-Z\s\d-_]+$/,
    customErrorMessage: {
      pattern: 'Invalid title format.'
    }
  },
  code: {
    required: true,
    pattern: /^[a-zA-Z_\d]*$/,
    customValidator: (value) => {
      const result = { is_valid: false, message: '' };
      if (value.startsWith('_')) {
        result.message = 'Code must not start with an underscore.';
      } else result.is_valid = true;

      return result;
    },
    customErrorMessage: {
      pattern: 'Only combination of alphabet, and underscore is allowed'
    }
  },
  features: {
    required: true,
    pattern: /^[\w\W]+$/
  },
  contact_bracket: {
    required: true,
    pattern: /^\d+:\d+$/,
    customValidator: (value) => {
      const result = { is_valid: false, message: '' };
      const [min, max] = value.split(':').map(Number);

      if (isNaN(min) || isNaN(max)) {
        result.message = 'Both values in contact bracket must be numbers.';
      } else if (min > max) {
        result.message = 'The first number should be less than or equal to the second number.';
      } else {
        result.is_valid = true;
      }
      return result;
    },
    customErrorMessage: {
      pattern: 'Contact bracket must be in the format number:number (e.g., 200:500).'
    }
  }
};
