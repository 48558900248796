import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ResourceListPage } from './list';

export const ResourcesModule = () => {
  return (
    <Switch>
      <Route path="/user-management/resources" component={ResourceListPage} />
    </Switch>
  );
};
