import './dashboard.css';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from 'store/actions/header';
import { useCampaignService } from 'hooks/api/mailing/campaign';
import { GridColumn, GridRow } from 'app/member/layouts/grid';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { DetailItem } from 'app/shared/info-section/item';
import { useAudienceService } from 'hooks/api/users/audience';
import { formatDateForDisplay } from 'app/shared/utils/date';

export const CampaignInformation = ({ id }) => {
  const dispatch = useDispatch();
  const { fetchAudiences } = useAudienceService();
  const { fetchCampaignById } = useCampaignService();

  const [audiences, setAudiences] = useState([]);
  const [campaign, setCampaign] = useState({});

  useEffect(() => {
    fetchCampaignById(id).then(async ({ campaign }) => {
      const { mailing_lists } = campaign;
      setCampaign(campaign);
      dispatch(
        setPageTitle([
          { title: 'My Campaigns', path: '.' },
          { title: campaign.name, path: `/campaigns/${id}` }
        ])
      );

      const { audiences: audience_from_db } = await fetchAudiences({
        query_string: `id=${mailing_lists.join(',')}`
      });
      setAudiences(audience_from_db);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GridRow>
      <GridColumn span={3}>
        <iframe
          title="Campaign body"
          srcDoc={campaign.body || ''}
          style={{
            width: '100%',
            minHeight: '75vh',
            padding: '15px',
            background: 'var(--neutral-light)',
            border: '1px solid var(--neutral-dark-5)',
            borderRadius: '8px'
          }}
        ></iframe>
      </GridColumn>
      <GridColumn span={1}>
        <BasicInfoSection title="Basic Details">
          <DetailItem title="Name:">{campaign.name || 'N/A'}</DetailItem>
          <DetailItem title="Subject:">{campaign.subject || 'N/A'}</DetailItem>
          <DetailItem title="Sender Name:">{campaign.sender_name || 'N/A'}</DetailItem>
          <DetailItem title="Sender Email:">{campaign.sender_email || 'N/A'}</DetailItem>
          <DetailItem title="Scheduled date:">
            {formatDateForDisplay(campaign.schedule?.date) || 'N/A'}
          </DetailItem>
          <DetailItem title="Status:">{campaign.status || 'N/A'}</DetailItem>
          <DetailItem title="Audiences">
            {audiences.map((audience) => (
              <p key={audience.id}>{audience.name}</p>
            ))}
          </DetailItem>
          <DetailItem title="Number of Retries">
            {campaign.metadata?.retry_count + 1 || 0}
          </DetailItem>
        </BasicInfoSection>
      </GridColumn>
    </GridRow>
  );
};
