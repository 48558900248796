import { Spacer } from 'app/member/layouts/generic';
import { GridColumn, GridRow } from 'app/member/layouts/grid';
import { Button } from 'app/shared/button';
import { useServerService } from 'hooks/api/postal/server';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

export const ImportServer = ({ closeModal = () => {} }) => {
  const { uploadServers } = useServerService();

  const [file, setFile] = useState(null);

  const submit = async () => {
    if (!file) {
      toast.error('no file selected');
      return;
    }

    if (!['json'].some((ext) => file.name.endsWith(ext))) {
      toast.error('Only json format is supported');
      return;
    }

    const data = new FormData();
    data.append('file', file);

    const result = await uploadServers({ data });
    if (result) {
      toast.success('Servers uploaded successfully');
      setFile(null);
      closeModal();
    }
  };

  return (
    <>
      <div className="custom-file">
        <input
          type="file"
          accept=".json"
          className="custom-file-input"
          id="file"
          onChange={(e) => setFile(e.target.files[0])}
          onClick={(e) => (e.target.value = null)}
        />
        <label className="custom-file-label" htmlFor="file">
          {file ? file.name : 'Select file'}
        </label>
      </div>
      <Spacer multiple={4} />
      <GridRow num_of_columns={3}>
        <GridColumn span={2} />
        <GridColumn>
          <Button text="Import" icon_name="check_circle" onClick={submit} />
        </GridColumn>
      </GridRow>
    </>
  );
};
