import { PageContentWrapper } from 'app/member/layouts/wrapper/page-content';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ListTenant from './list/list';
import { TenantPage } from './view';

const TenantModule = () => {
  return (
    <PageContentWrapper>
      <Switch>
        <Route path="/tenants/:id" component={TenantPage} />
        <Route path="/tenants" component={ListTenant} />
      </Switch>
    </PageContentWrapper>
  );
};

export default TenantModule;
