import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as walletService from 'services/sales/wallet';

export const useWalletService = () => {
  const { token } = useSelector((state) => state.user_data);

  const fetchWallets = async (options = {}) => {
    const { error, payload } = await walletService.read({ ...options, token });
    if (error) {
      toast.error(error);
      return { wallets: {}, size: 0 };
    }

    const {
      data: wallets,
      meta: { size }
    } = payload;

    return { wallets, size };
  };

  const updateWallet = async (options = {}) => {
    const { error } = await walletService.update({ ...options, token });
    if (error) {
      toast.error(error);
      return false;
    }

    toast.success('Top up successful');
    return true;
  };

  return {
    fetchWallets,
    updateWallet
  };
};
